import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import authService from "../services/authservices";
import { ToastContainer, toast } from "react-toastify";
function Strategies() {
  useEffect(() => {
    getStratagy();
    getFollowedStratagy();
  }, []);

  const [StratagyLoader, setStratagyLoader] = useState(false);
  const [StratagyList, setStratagyList, StratagyListref] = useState([]);

  const getStratagy = async (data) => {
    try {
      setStratagyLoader(true);
      const token = localStorage.getItem("token");
      const response = await authService.topStratagies(token);
      setStratagyLoader(false);
      console.log(response, "response");
      if (response.status == true) {
        setStratagyList(response.data);
      } else {
        setStratagyList(response.data);
      }
      console.log(StratagyListref.current.length, "--==");
    } catch (error) {
      console.log(error, "-----------------");
    }
  };

  const getFollowedStratagy = async (data) => {
    try {
      setStratagyLoader(true);
      const token = localStorage.getItem("token");
      const response = await authService.topStratagies(token);
      setStratagyLoader(false);
      console.log(response, "response");
      if (response.status == true) {
        setStratagyList(response.data);
      } else {
        setStratagyList(response.data);
      }
      console.log(StratagyListref.current.length, "--==");
    } catch (error) {
      console.log(error, "-----------------");
    }
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(
      <div className="toast-ml">
        <h4 className="toast-title">Success!</h4>
        <p className="toast-message">{message}</p>
      </div>
    );
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(
      <div className="toast-ml">
        <h4 className="toast-title">Error!</h4>
        <p className="toast-message">{message}</p>
      </div>
    );
  };

  const handleFollowStrategy = async (data) => {
    try {
      var obj = {
        action: data.buttonLabel,
        strategyId: data._id,
      };
      const response = await authService.handleFollowStrategy(obj);
      setStratagyLoader(false);
      console.log(response, "response");
      if (response.status == true) {
        showsuccessToast(response.Message);
        getStratagy();
      } else {
        showerrorToast(response.Message);
        getStratagy();
      }
    } catch (err) {}
  };

  return (
    <div>
      <section>
        <Header />
      </section>
      <div className="asset_row_resp">
        <div className="container">
          <div className="home_main">
            <div className="row starteg_main">
              <div className="col-xl-6 col-lg-8 col-md-10 col-sm-12">
                <span className="startegy_top_center">
                  Master the Market with Proven Strategies for Success
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 p-0 pad-0-560">
                <div className="startegy_first_main">
                  <span className="home_botstatus_span">Top Strategies</span>
                  {StratagyLoader == true ? (
                    <span className="home_loading">Loading....</span>
                  ) : (
                    <div className="row">
                      {StratagyListref.current.length > 0
                        ? StratagyListref.current.map((options) => {
                            return (
                              <div className="col-lg-3 col-md-6 pad-0-560">
                                <div className="strategy_topstarty_inmain">
                                  <div className="startegy_topstary_inntop">
                                    <div className="top_startgy_intopintop">
                                      <span className="dca_main">
                                        {" "}
                                        {options.stratagyName}
                                      </span>
                                      <div className="reg_check_main">
                                        <div className="">
                                          <img
                                            src={require("../assests/icons/topstartegy-users.png")}
                                            alt="Users"
                                            className="topstartegy-users"
                                          />
                                          <span className="top-99">
                                            {" "}
                                            {options.followers}
                                          </span>
                                        </div>
                                        <div className="topstartegy_color_div">
                                          <span>
                                            Leverage: {options.leverage}X
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="startgy_box_intopinbottom">
                                      <div className="top_startgy_inbottomleft">
                                        <span className="top_startgy_inbottomadvance">
                                          Annualized ROI
                                        </span>
                                        <span className="top_startgy_inbottompercen">
                                          {options.annualROI}%
                                        </span>
                                      </div>
                                      <img
                                        src={require("../assests/topstrategy-chart.png")}
                                        alt="Startegy chart"
                                        className="startegy_chart_strategybox"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="home_topstary_follow"
                                    onClick={() =>
                                      handleFollowStrategy(options)
                                    }
                                  >
                                    <span>{options.buttonLabel}</span>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 p-0">
                <div className="startegy_first_main">
                  <span className="home_botstatus_span">
                    Followed strategies
                  </span>
                  <div className="row">
                    {StratagyListref.current.length > 0
                      ? StratagyListref.current
                          .filter((option) => option.buttonLabel === "Unfollow") // Filter items where button label is "Follow"
                          .map((options) => {
                            return (
                              <div className="col-lg-3 col-md-6 pad-0-560">
                                <div className="strategy_topstarty_inmain">
                                  <div className="startegy_topstary_inntop">
                                    <div className="top_startgy_intopintop">
                                      <span className="dca_main">
                                        {" "}
                                        {options.stratagyName}
                                      </span>
                                      <div className="reg_check_main">
                                        <div className="">
                                          <img
                                            src={require("../assests/icons/topstartegy-users.png")}
                                            alt="Users"
                                            className="topstartegy-users"
                                          />
                                          <span className="top-99">
                                            {" "}
                                            {options.followers}
                                          </span>
                                        </div>
                                        <div className="topstartegy_color_div">
                                          <span>
                                            Leverage: {options.leverage}X
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="startgy_box_intopinbottom">
                                      <div className="top_startgy_inbottomleft">
                                        <span className="top_startgy_inbottomadvance">
                                          Annualized ROI
                                        </span>
                                        <span className="top_startgy_inbottompercen">
                                          {options.annualROI}%
                                        </span>
                                      </div>
                                      <img
                                        src={require("../assests/topstrategy-chart.png")}
                                        alt="Startegy chart"
                                        className="startegy_chart_strategybox"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="home_topstary_follow"
                                    onClick={() =>
                                      handleFollowStrategy(options)
                                    }
                                  >
                                    <span>{options.buttonLabel}</span>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                      : ""}
                    {/* <div className="col-lg-3 col-md-6 m-top-20-770 pad-0-560">
                    <div className="strategy_topstarty_inmain">
                        <div className="startegy_topstary_inntop">
                            <div className="top_startgy_intopintop">
                                <span className="dca_main"> Intra Strategy</span>
                                <div className="reg_check_main">
                                    <div className="">
                                    <img
                                    src={require("../assests/icons/topstartegy-users.png")}
                                    alt="Users"
                                    className="topstartegy-users"
                                  />
                                  <span className="top-99"> 99</span>
                                    </div>
                                    <div className="topstartegy_color_div">
                                  <span>Leverage: 3X</span>
                                </div>
                                </div>
                                </div>
                                <div className="startgy_box_intopinbottom">
                                    <div className="top_startgy_inbottomleft">
                                    <span className="top_startgy_inbottomadvance">
                                  Annualized ROI
                                </span>
                                <span className="top_startgy_inbottompercen">
                                  536.49%
                                </span>
                                    </div>
                                    <img
                                src={require("../assests/topstrategy-chart.png")}
                                alt="Startegy chart"
                                className="startegy_chart_strategybox"
                              />
                                
                            </div>
                        </div>
                        <div className="home_topstary_follow">
                          <span>Un Follow</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 m-top-20 pad-0-560">
                    <div className="strategy_topstarty_inmain">
                        <div className="startegy_topstary_inntop">
                            <div className="top_startgy_intopintop">
                                <span className="dca_main"> Triangle Strategy</span>
                                <div className="reg_check_main">
                                    <div className="">
                                    <img
                                    src={require("../assests/icons/topstartegy-users.png")}
                                    alt="Users"
                                    className="topstartegy-users"
                                  />
                                  <span className="top-99"> 99</span>
                                    </div>
                                    <div className="topstartegy_color_div">
                                  <span>Leverage: 3X</span>
                                </div>
                                </div>
                                </div>
                                <div className="startgy_box_intopinbottom">
                                    <div className="top_startgy_inbottomleft">
                                    <span className="top_startgy_inbottomadvance">
                                  Annualized ROI
                                </span>
                                <span className="top_startgy_inbottompercen">
                                  536.49%
                                </span>
                                    </div>
                                    <img
                                src={require("../assests/topstrategy-chart.png")}
                                alt="Startegy chart"
                                className="startegy_chart_strategybox"
                              />
                                
                            </div>
                        </div>
                        <div className="home_topstary_follow">
                          <span>Un Follow</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 m-top-20 pad-0-560">
                    <div className="strategy_topstarty_inmain">
                        <div className="startegy_topstary_inntop">
                            <div className="top_startgy_intopintop">
                                <span className="dca_main"> Arbitrage Strategy</span>
                                <div className="reg_check_main">
                                    <div className="">
                                    <img
                                    src={require("../assests/icons/topstartegy-users.png")}
                                    alt="Users"
                                    className="topstartegy-users"
                                  />
                                  <span className="top-99"> 99</span>
                                    </div>
                                    <div className="topstartegy_color_div">
                                  <span>Leverage: 3X</span>
                                </div>
                                </div>
                                </div>
                                <div className="startgy_box_intopinbottom">
                                    <div className="top_startgy_inbottomleft">
                                    <span className="top_startgy_inbottomadvance">
                                  Annualized ROI
                                </span>
                                <span className="top_startgy_inbottompercen">
                                  536.49%
                                </span>
                                    </div>
                                    <img
                                src={require("../assests/topstrategy-chart.png")}
                                alt="Startegy chart"
                                className="startegy_chart_strategybox"
                              />
                                
                            </div>
                        </div>
                        <div className="home_topstary_follow">
                          <span>Un Follow</span>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Strategies;
