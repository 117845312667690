import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useState from "react-usestateref";
import Header from "./Header";
import authService from "../services/authservices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Assets() {
  const [totalBalance, setTotalBalance] = useState(0);
  const [withdrawalHistory, setWithdrawalHistory] = useState([]);
  const [depositHistory, setDepositHistory] = useState([]);

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const response = await authService.getTransactions();
        if (response.status === true && response.totalBalanceUSDT !== undefined) {
          setTotalBalance(parseFloat(response.totalBalanceUSDT));
          setDepositHistory(response.deposits);
        } else {
          console.error("Unexpected response format:", response);
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    const fetchWithdrawalHistory = async () => {
      try {
        const response = await authService.getWithdrawalHistory();
        if (response.status === true) {
          setWithdrawalHistory(response.data);
        } else {
          console.error("Unexpected response format:", response);
        }
      } catch (error) {
        console.error("Error fetching withdrawal history:", error);
      }
    };

    // Call the APIs once when the component is mounted
    fetchBalance();
    fetchWithdrawalHistory();
  }, []);
  const handleCopy = (txID) => {
    // Prevent triggering multiple times rapidly
    if (handleCopy.timeout) clearTimeout(handleCopy.timeout);

    handleCopy.timeout = setTimeout(() => {
      navigator.clipboard
        .writeText(txID)
        .then(() => {
          toast.success(
            <div className="toast-ml">
              <h4 className="toast-title">Success!</h4>
              <p className="toast-message">
                Transaction ID copied to clipboard!
              </p>
            </div>
          );
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
          toast.error(
            <div className="toast-ml">
              <h4 className="toast-title">Error!</h4>
              <p className="toast-message">Failed to copy Transaction ID</p>
            </div>
          );
        });
    }, 300); // 300ms debounce time
  };
  return (
    <div>
      <section>
        <Header />
      </section>
      <div className="asset_row_resp">
        <div className="container">
          <div className="home_main">
            <div className="row asset_main">
              <div className="col-xl-4 col-lg-6 col-md-8 col-sm-9">
                <div className="asset_top_center">
                  <span className="asset_top_head">
                    Take Control of Your Future with Assets Today
                  </span>
                  <div className="asset_top_btns">
                    <Link to="/deposit">
                      <button className="asset_top_deposit">Deposit</button>
                    </Link>
                    <Link to="/withdraw">
                      <button className="asset_top_Withdraw">Withdraw</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 p-0">
                <div className="asset_second_main">
                  <div className="asset_second_left">
                    <span className="footer_head">Total Value</span>
                    <div className="home_user_name">
                      <span className="name_16_31bd65">{totalBalance.toFixed(4)} USDT</span>
                      <span className="home_userbln_head">~{totalBalance.toFixed(2)} USD</span>
                    </div>
                  </div>
                  <span className="asset_slash">|</span>
                  <div className="asset_second_right">
                    <div className="asset_second_insmall">
                      <span className="home_userbln_head">Binance Balance</span>
                      <span className="footer_head">0.00 USDT</span>
                    </div>
                    <div className="asset_second_insmall">
                      <span className="home_userbln_head">Today’s Pnl</span>
                      <span className="footer_head">0.00%</span>
                    </div>
                    <div className="asset_second_insmall">
                      <span className="home_userbln_head">7 Days Pnl</span>
                      <span className="footer_head">0.00%</span>
                    </div>
                    <div className="asset_second_insmall">
                      <span className="home_userbln_head">30 Days Pnl</span>
                      <span className="footer_head">0.00%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 p-0">
                <div className="asset_third_main pb-0">
                  <span className="home_botstatus_span">Histories</span>
                  <div className="table-responsive table-borderless home_recent_table">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="text_left">Currency</th>
                          <th>Amount</th>
                          <th>Type</th>
                          <th>Transaction ID</th>
                          <th>Date And Time</th>
                        </tr>
                      </thead>

                      {depositHistory.length == 0 && withdrawalHistory.length == 0 ? (
                          <tbody>
                            <tr>
                              <td colSpan={5}><span>No Records Found</span></td>
                            </tr>
                          </tbody>
                        ) : (

                          <tbody>
                        
                        {depositHistory.map((deposit, index) => (
                          <tr key={index}>
                            <td>
                              <div className="home_recent_firstdata">
                                <img
                                  src={require("../assests/usdt-logo.png")}
                                  alt="USDT"
                                  className="usdt_home_recent"
                                />
                                <span>USDT</span>
                              </div>
                            </td>
                            <td>
                              <span>{deposit.amount}</span>
                            </td>
                            <td>
                              <span>Deposit</span>
                            </td>
                            <td>
                              <div className="home_uid_main">
                                <span>{deposit.txID}</span>
                                <i
                                  className="ri-file-copy-line cursor-pointer uid_copy_recent"
                                  onClick={() =>
                                    handleCopy(deposit.txID)
                                  }
                                ></i>
                              </div>
                            </td>
                            <td>
                              <span>{new Date(deposit.timestamp).toLocaleString()}</span>
                            </td>
                          </tr>
                        ))}
                        {withdrawalHistory.map((withdrawal, index) => (
                          <tr key={index}>
                            <td>
                              <div className="home_recent_firstdata">
                                <img
                                  src={require("../assests/usdt-logo.png")}
                                  alt="USDT"
                                  className="usdt_home_recent"
                                />
                                <span>{withdrawal.currency}</span>
                              </div>
                            </td>
                            <td>
                              <span>{withdrawal.amount}</span>
                            </td>
                            <td>
                              <span>Withdraw</span>
                            </td>
                            <td>
                              <div className="home_uid_main">
                                <span>{withdrawal.transactionId}</span>
                                <i
                                  className="ri-file-copy-line cursor-pointer uid_copy_recent"
                                  onClick={() =>
                                    handleCopy(withdrawal.transactionId)
                                  }
                                ></i>
                              </div>
                            </td>
                            <td>
                              <span>{new Date(withdrawal.date).toLocaleString()}</span>
                            </td>
                          </tr>
                        ))}
                       </tbody>
                        )}
                      
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Assets;
