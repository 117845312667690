import React from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";

function FAQ() {
  const [faqType, setFaqType, faqTyperef] = useState("Trade");

  return (
    <div>
      <section>
        <Header />
      </section>
      <div className="">
        <div className="container">
          <div className="row prof_row_sm">
            <div className="col-lg-2 dispaly_none_1200">
              <Sidebar />
            </div>
            <div className="col-xl-10 col-lg-12 deposit_pad_top">
              <span className="profile_span">FAQs</span>
              {/* <div className="row mt-4 h-100 faq_row_pad"> */}
              <div className="faq_resp_changed_main">
                {/* <div className="col-2 col-lg-2 col-md-3 col-sm-4 p-0"> */}
                <div className="faq_resp_changed_mainLeft">
                  <div className="faq_col2_main h-100">
                    <span className="faq_cate_span">Categories</span>
                    <div className="mt-2">
                      <div
                        className={
                          faqTyperef.current == "Trade"
                            ? "faq_cate_main_active"
                            : "faq_cate_main"
                        }
                        onClick={() => setFaqType("Trade")}
                      >
                        <i class="fa-solid fa-chevron-right faq_cate_arrow"></i>
                        <span>Trade</span>
                      </div>
                      <div
                        className={
                          faqTyperef.current == "Deposit"
                            ? "faq_cate_main_active"
                            : "faq_cate_main"
                        }
                        onClick={() => setFaqType("Deposit")}
                      >
                        <i class="fa-solid fa-chevron-right faq_cate_arrow"></i>
                        <span>Deposit</span>
                      </div>
                      <div
                        className={
                          faqTyperef.current == "Withdraw"
                            ? "faq_cate_main_active"
                            : "faq_cate_main"
                        }
                        onClick={() => setFaqType("Withdraw")}
                      >
                        <i class="fa-solid fa-chevron-right faq_cate_arrow"></i>
                        <span>Withdraw</span>
                      </div>
                      <div
                        className={
                          faqTyperef.current == "Apibind"
                            ? "faq_cate_main_active"
                            : "faq_cate_main"
                        }
                        onClick={() => setFaqType("Apibind")}
                      >
                        <i class="fa-solid fa-chevron-right faq_cate_arrow"></i>
                        <span>API bind</span>
                      </div>
                      <div
                        className={
                          faqTyperef.current == "Team"
                            ? "faq_cate_main_active"
                            : "faq_cate_main"
                        }
                        onClick={() => setFaqType("Team")}
                      >
                        <i class="fa-solid fa-chevron-right faq_cate_arrow"></i>
                        <span>Team management</span>
                      </div>
                      <div
                        className={
                          faqTyperef.current == "Tradingbot"
                            ? "faq_cate_main_active"
                            : "faq_cate_main"
                        }
                        onClick={() => setFaqType("Tradingbot")}
                      >
                        <i class="fa-solid fa-chevron-right faq_cate_arrow"></i>
                        <span> Trading bots</span>
                      </div>
                      <div
                        className={
                          faqTyperef.current == "Strategies"
                            ? "faq_cate_main_active"
                            : "faq_cate_main"
                        }
                        onClick={() => setFaqType("Strategies")}
                      >
                        <i class="fa-solid fa-chevron-right faq_cate_arrow"></i>
                        <span>Strategies</span>
                      </div>
                      <div
                        className={
                          faqTyperef.current == "Riskandfraud"
                            ? "faq_cate_main_active"
                            : "faq_cate_main"
                        }
                        onClick={() => setFaqType("Riskandfraud")}
                      >
                        <i class="fa-solid fa-chevron-right faq_cate_arrow"></i>
                        <span>Risk and fraud</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className=" col-10 col-lg-10 col-md-9 col-sm-8 p-0 h-100"> */}
                <div className="faq_resp_changed_mainright">
                  <div className="faq_col10_main h-100">
                    {faqTyperef.current == "Trade" && (
                      <div
                        class="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <div className="">
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingOne">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                              >
                                How do I make a trade?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseOne"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingOne"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                KYC verification is essential to comply with
                                regulations and ensure the security of our
                                platform by preventing fraud and other illegal
                                activities.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingTwo">
                              <button
                                class="accordion-button collapsed max-wrap"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwo"
                                aria-expanded="false"
                                aria-controls="flush-collapseTwo"
                              >
                                Why have I still not received my deposit?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseTwo"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingTwo"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                You will need to submit a government-issued ID
                                (like a passport or driver's license) and a
                                recent proof of address (such as a utility bill
                                or bank statement).
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2
                              class="accordion-header"
                              id="flush-headingThree"
                            >
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseThree"
                                aria-expanded="false"
                                aria-controls="flush-collapseThree"
                              >
                                How do I find my deposit address and tag/memos?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseThree"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingThree"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                The verification process typically takes [X
                                hours/days], depending on the volume of requests
                                and the clarity of the documents provided.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingFour">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFour"
                                aria-expanded="false"
                                aria-controls="flush-collapseFour"
                              >
                                How to check the deposit progress?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseFour"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingFour"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                Yes, we use encryption and follow strict data
                                protection regulations to ensure that your
                                personal information remains secure.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingFive">
                              <button
                                class="accordion-button collapsed max-wrap"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFive"
                                aria-expanded="false"
                                aria-controls="flush-collapseFive"
                              >
                                How to check the deposit progress?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseFive"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingFive"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                If your verification is rejected, you will
                                receive an email with the reasons and
                                instructions on how to resolve the issue.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {faqTyperef.current == "Deposit" && (
                      <div
                        class="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <div className="">
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingOne">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                              >
                                How do I make a deposit?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseOne"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingOne"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                KYC verification is essential to comply with
                                regulations and ensure the security of our
                                platform by preventing fraud and other illegal
                                activities.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingTwo">
                              <button
                                class="accordion-button collapsed max-wrap"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwo"
                                aria-expanded="false"
                                aria-controls="flush-collapseTwo"
                              >
                                Why have I still not received my deposit?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseTwo"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingTwo"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                You will need to submit a government-issued ID
                                (like a passport or driver's license) and a
                                recent proof of address (such as a utility bill
                                or bank statement).
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2
                              class="accordion-header"
                              id="flush-headingThree"
                            >
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseThree"
                                aria-expanded="false"
                                aria-controls="flush-collapseThree"
                              >
                                How do I find my deposit address and tag/memos?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseThree"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingThree"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                The verification process typically takes [X
                                hours/days], depending on the volume of requests
                                and the clarity of the documents provided.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingFour">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFour"
                                aria-expanded="false"
                                aria-controls="flush-collapseFour"
                              >
                                How to check the deposit progress?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseFour"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingFour"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                Yes, we use encryption and follow strict data
                                protection regulations to ensure that your
                                personal information remains secure.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingFive">
                              <button
                                class="accordion-button collapsed max-wrap"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFive"
                                aria-expanded="false"
                                aria-controls="flush-collapseFive"
                              >
                                How to check the deposit progress?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseFive"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingFive"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                If your verification is rejected, you will
                                receive an email with the reasons and
                                instructions on how to resolve the issue.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {faqTyperef.current == "Withdraw" && (
                      <div
                        class="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <div className="">
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingOne">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                              >
                                How do I make a Withdraw?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseOne"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingOne"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                KYC verification is essential to comply with
                                regulations and ensure the security of our
                                platform by preventing fraud and other illegal
                                activities.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingTwo">
                              <button
                                class="accordion-button collapsed max-wrap"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwo"
                                aria-expanded="false"
                                aria-controls="flush-collapseTwo"
                              >
                                Why have I still not received my deposit?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseTwo"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingTwo"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                You will need to submit a government-issued ID
                                (like a passport or driver's license) and a
                                recent proof of address (such as a utility bill
                                or bank statement).
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2
                              class="accordion-header"
                              id="flush-headingThree"
                            >
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseThree"
                                aria-expanded="false"
                                aria-controls="flush-collapseThree"
                              >
                                How do I find my deposit address and tag/memos?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseThree"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingThree"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                The verification process typically takes [X
                                hours/days], depending on the volume of requests
                                and the clarity of the documents provided.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingFour">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFour"
                                aria-expanded="false"
                                aria-controls="flush-collapseFour"
                              >
                                How to check the deposit progress?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseFour"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingFour"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                Yes, we use encryption and follow strict data
                                protection regulations to ensure that your
                                personal information remains secure.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingFive">
                              <button
                                class="accordion-button collapsed max-wrap"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFive"
                                aria-expanded="false"
                                aria-controls="flush-collapseFive"
                              >
                                How to check the deposit progress?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseFive"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingFive"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                If your verification is rejected, you will
                                receive an email with the reasons and
                                instructions on how to resolve the issue.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {faqTyperef.current == "Apibind" && (
                      <div
                        class="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <div className="">
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingOne">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                              >
                                How do I make a Apibind?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseOne"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingOne"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                KYC verification is essential to comply with
                                regulations and ensure the security of our
                                platform by preventing fraud and other illegal
                                activities.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingTwo">
                              <button
                                class="accordion-button collapsed max-wrap"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwo"
                                aria-expanded="false"
                                aria-controls="flush-collapseTwo"
                              >
                                Why have I still not received my deposit?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseTwo"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingTwo"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                You will need to submit a government-issued ID
                                (like a passport or driver's license) and a
                                recent proof of address (such as a utility bill
                                or bank statement).
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2
                              class="accordion-header"
                              id="flush-headingThree"
                            >
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseThree"
                                aria-expanded="false"
                                aria-controls="flush-collapseThree"
                              >
                                How do I find my deposit address and tag/memos?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseThree"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingThree"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                The verification process typically takes [X
                                hours/days], depending on the volume of requests
                                and the clarity of the documents provided.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingFour">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFour"
                                aria-expanded="false"
                                aria-controls="flush-collapseFour"
                              >
                                How to check the deposit progress?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseFour"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingFour"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                Yes, we use encryption and follow strict data
                                protection regulations to ensure that your
                                personal information remains secure.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingFive">
                              <button
                                class="accordion-button collapsed max-wrap"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFive"
                                aria-expanded="false"
                                aria-controls="flush-collapseFive"
                              >
                                How to check the deposit progress?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseFive"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingFive"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                If your verification is rejected, you will
                                receive an email with the reasons and
                                instructions on how to resolve the issue.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {faqTyperef.current == "Team" && (
                      <div
                        class="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <div className="">
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingOne">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                              >
                                How do I make a Team?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseOne"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingOne"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                KYC verification is essential to comply with
                                regulations and ensure the security of our
                                platform by preventing fraud and other illegal
                                activities.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingTwo">
                              <button
                                class="accordion-button collapsed max-wrap"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwo"
                                aria-expanded="false"
                                aria-controls="flush-collapseTwo"
                              >
                                Why have I still not received my deposit?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseTwo"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingTwo"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                You will need to submit a government-issued ID
                                (like a passport or driver's license) and a
                                recent proof of address (such as a utility bill
                                or bank statement).
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2
                              class="accordion-header"
                              id="flush-headingThree"
                            >
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseThree"
                                aria-expanded="false"
                                aria-controls="flush-collapseThree"
                              >
                                How do I find my deposit address and tag/memos?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseThree"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingThree"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                The verification process typically takes [X
                                hours/days], depending on the volume of requests
                                and the clarity of the documents provided.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingFour">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFour"
                                aria-expanded="false"
                                aria-controls="flush-collapseFour"
                              >
                                How to check the deposit progress?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseFour"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingFour"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                Yes, we use encryption and follow strict data
                                protection regulations to ensure that your
                                personal information remains secure.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingFive">
                              <button
                                class="accordion-button collapsed max-wrap"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFive"
                                aria-expanded="false"
                                aria-controls="flush-collapseFive"
                              >
                                How to check the deposit progress?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseFive"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingFive"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                If your verification is rejected, you will
                                receive an email with the reasons and
                                instructions on how to resolve the issue.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {faqTyperef.current == "Tradingbot" && (
                      <div
                        class="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <div className="">
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingOne">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                              >
                                How do I make a Tradingbot?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseOne"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingOne"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                KYC verification is essential to comply with
                                regulations and ensure the security of our
                                platform by preventing fraud and other illegal
                                activities.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingTwo">
                              <button
                                class="accordion-button collapsed max-wrap"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwo"
                                aria-expanded="false"
                                aria-controls="flush-collapseTwo"
                              >
                                Why have I still not received my deposit?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseTwo"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingTwo"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                You will need to submit a government-issued ID
                                (like a passport or driver's license) and a
                                recent proof of address (such as a utility bill
                                or bank statement).
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2
                              class="accordion-header"
                              id="flush-headingThree"
                            >
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseThree"
                                aria-expanded="false"
                                aria-controls="flush-collapseThree"
                              >
                                How do I find my deposit address and tag/memos?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseThree"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingThree"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                The verification process typically takes [X
                                hours/days], depending on the volume of requests
                                and the clarity of the documents provided.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingFour">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFour"
                                aria-expanded="false"
                                aria-controls="flush-collapseFour"
                              >
                                How to check the deposit progress?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseFour"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingFour"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                Yes, we use encryption and follow strict data
                                protection regulations to ensure that your
                                personal information remains secure.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingFive">
                              <button
                                class="accordion-button collapsed max-wrap"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFive"
                                aria-expanded="false"
                                aria-controls="flush-collapseFive"
                              >
                                How to check the deposit progress?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseFive"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingFive"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                If your verification is rejected, you will
                                receive an email with the reasons and
                                instructions on how to resolve the issue.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {faqTyperef.current == "Strategies" && (
                      <div
                        class="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <div className="">
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingOne">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                              >
                                How do I make a Strategies?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseOne"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingOne"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                KYC verification is essential to comply with
                                regulations and ensure the security of our
                                platform by preventing fraud and other illegal
                                activities.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingTwo">
                              <button
                                class="accordion-button collapsed max-wrap"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwo"
                                aria-expanded="false"
                                aria-controls="flush-collapseTwo"
                              >
                                Why have I still not received my deposit?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseTwo"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingTwo"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                You will need to submit a government-issued ID
                                (like a passport or driver's license) and a
                                recent proof of address (such as a utility bill
                                or bank statement).
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2
                              class="accordion-header"
                              id="flush-headingThree"
                            >
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseThree"
                                aria-expanded="false"
                                aria-controls="flush-collapseThree"
                              >
                                How do I find my deposit address and tag/memos?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseThree"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingThree"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                The verification process typically takes [X
                                hours/days], depending on the volume of requests
                                and the clarity of the documents provided.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingFour">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFour"
                                aria-expanded="false"
                                aria-controls="flush-collapseFour"
                              >
                                How to check the deposit progress?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseFour"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingFour"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                Yes, we use encryption and follow strict data
                                protection regulations to ensure that your
                                personal information remains secure.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingFive">
                              <button
                                class="accordion-button collapsed max-wrap"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFive"
                                aria-expanded="false"
                                aria-controls="flush-collapseFive"
                              >
                                How to check the deposit progress?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseFive"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingFive"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                If your verification is rejected, you will
                                receive an email with the reasons and
                                instructions on how to resolve the issue.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {faqTyperef.current == "Riskandfraud" && (
                      <div
                        class="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <div className="">
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingOne">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                              >
                                How do I make a Riskandfraud?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseOne"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingOne"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                KYC verification is essential to comply with
                                regulations and ensure the security of our
                                platform by preventing fraud and other illegal
                                activities.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingTwo">
                              <button
                                class="accordion-button collapsed max-wrap"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwo"
                                aria-expanded="false"
                                aria-controls="flush-collapseTwo"
                              >
                                Why have I still not received my deposit?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseTwo"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingTwo"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                You will need to submit a government-issued ID
                                (like a passport or driver's license) and a
                                recent proof of address (such as a utility bill
                                or bank statement).
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2
                              class="accordion-header"
                              id="flush-headingThree"
                            >
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseThree"
                                aria-expanded="false"
                                aria-controls="flush-collapseThree"
                              >
                                How do I find my deposit address and tag/memos?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseThree"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingThree"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                The verification process typically takes [X
                                hours/days], depending on the volume of requests
                                and the clarity of the documents provided.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingFour">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFour"
                                aria-expanded="false"
                                aria-controls="flush-collapseFour"
                              >
                                How to check the deposit progress?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseFour"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingFour"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                Yes, we use encryption and follow strict data
                                protection regulations to ensure that your
                                personal information remains secure.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item-faq font-satoshi">
                            <h2 class="accordion-header" id="flush-headingFive">
                              <button
                                class="accordion-button collapsed max-wrap"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFive"
                                aria-expanded="false"
                                aria-controls="flush-collapseFive"
                              >
                                How to check the deposit progress?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseFive"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingFive"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body-faq">
                                If your verification is rejected, you will
                                receive an email with the reasons and
                                instructions on how to resolve the issue.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
