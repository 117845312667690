import React, { useEffect, useContext, useMemo } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Switch from "react-switch";
import countryList from "react-select-country-list";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import authService from "../services/authservices";
import Select from "react-select";

function Profile() {
  const [checked, setChecked] = useState(false);
  const { logout } = useContext(UserContext);
  const [user, setUser] = useState({
    tfaStatus: 0,
    email: null,
    uid: null,
    referralCode: null,
    referralUrl: null,
    displayname: null,
    country: null,
    isAuthenticated: false,
  });
  const [loading, setLoading] = useState(true);
  const fetchUserDetails = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const data = await authService.getUserDetails(token);
        if (data && data.status) {
          const userDetails = {
            isAuthenticated: true,
            tfaStatus: data.tfastatus,
            email: data.email,
            displayname: data.displayname,
            country: data.country,
            uid: data.uid,
            referralCode: data.referralCode,
            referralUrl: data.referralUrl,
          };
          setUser(userDetails);
        } else {
          setUser({
            isAuthenticated: false,
            tfaStatus: 0,
            email: null,
            uid: null,
            referralCode: null,
            referralUrl: null,
          });
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        setUser({
          isAuthenticated: false,
          tfaStatus: 0,
          email: null,
          uid: null,
          referralCode: null,
          referralUrl: null,
        });
      }
    } else {
      setUser({
        isAuthenticated: false,
        tfaStatus: 0,
        email: null,
        uid: null,
        referralCode: null,
        referralUrl: null,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const navigate = useNavigate();
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };
  const options = useMemo(() => countryList().getData(), []);

  const [username, setUsername] = useState(user.displayname || "");
  const [country, setCountry] = useState(user.country || "");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editableField, setEditableField] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [email, setEmail] = useState(user.email || "");
  const [error, setError] = useState("");

  const openModal = (field) => {
    setEditableField(field);
    if (field === "username") {
      setInputValue(user.displayname);
    } else if (field === "country") {
      setInputValue(user.country);
    } else if (field === "email") {
      setInputValue(user.email);
    }
    setIsModalOpen(true);
  };
  const [selectedCountry, setSelectedCountry] = useState(user.country);

  const handleInputChange = (e) => {
    let value = e.target.value;
    setError("");
    if (editableField === "username") {
      const regex = /^[a-zA-Z0-9 ]*$/;
      if (value.length > 50 || !regex.test(value)) {
        setError("Invalid input or exceeds the limit of 50 characters.");
      } else if (value.startsWith(" ")) {
        setError("Input cannot start with a space.");
      } else if (/\s{2,}/.test(value)) {
        setError("Input cannot contain multiple consecutive spaces.");
      } else {
        setInputValue(value);
      }
    } else if (editableField === "email") {
      const regex = /^[^\s]*$/;
      if (!regex.test(value) || value.length > 250) {
        setError("Invalid email. No spaces allowed and must be under 250 characters.");
      } else {
        setInputValue(value);
      }
    }
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption.value);
  };

  const handleSaveChanges = async () => {
    if (!inputValue && editableField !== "country") return;
    if (editableField === "username") {
      if (inputValue.endsWith(" ")) {
        setError("Input cannot end with a space.");
        return;
      }
    }
    if (editableField === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(inputValue)) {
        setError("Invalid email format.");
        return;
      }
    }

    const updatedData = {};
    if (editableField === "username" && inputValue !== user.displayname) {
      updatedData.displayname = inputValue;
    }
    if (editableField === "email" && inputValue !== user.email) {
      updatedData.email = inputValue;
    }
    if (editableField === "country" && selectedCountry !== user.country) {
      const selectedCountryLabel = options.find(option => option.value === selectedCountry)?.label;
      updatedData.country = selectedCountryLabel;
    }
    if (Object.keys(updatedData).length === 0) {
      toast.info(
        <div className="toast-ml">
          <h4 className="toast-title">Info!</h4>
          <p className="toast-message">No changes detected.</p>
        </div>
      );
      setIsModalOpen(false);
      return;
    }

    try {
      const response = await authService.updateUserDetails(updatedData);
      console.log("Backend Response:", response);

      if (response && response.status) {
        setIsModalOpen(false);
        setUser((prev) => ({
          ...prev,
          ...updatedData,
        }));

        toast.success(
          <div className="toast-ml">
            <h4 className="toast-title">Success!</h4>
            <p className="toast-message">Profile updated successfully</p>
          </div>
        );
      } else {
        toast.error(
          <div className="toast-ml">
            <h4 className="toast-title">Error!</h4>
            <p className="toast-message">
              Failed to update user details. Please try again.
            </p>
          </div>
        );
      }
    } catch (error) {
      console.error("Error updating user details:", error);
      toast.error(
        <div className="toast-ml">
          <h4 className="toast-title">Error!</h4>
          <p className="toast-message">
            An error occurred while updating user details.
          </p>
        </div>
      );
    }
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };
  const copyToClipboard = () => {
    if (user.referralCode) {
      navigator.clipboard
        .writeText(user.referralCode)
        .then(() => {
          toast("code copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    }
  };
  const copyToClipboardurlcopy = () => {
    if (user.referralUrl) {
      navigator.clipboard
        .writeText(user.referralUrl)
        .then(() => {
          toast("link copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    }
  };
  const copyToClipboardlink = () => {
    const referralCode = user.referralCode;
    const referralUrl = `/register?invite=${referralCode}`;

    navigator.clipboard
      .writeText(referralUrl)
      .then(() => {
        toast("Link copied to clipboard!");
        navigate(referralUrl);
      })
      .catch((err) => {
        console.error("Failed to copy or navigate: ", err);
      });
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: "50px",
      minHeight: "44px",
      backgroundColor: state.isFocused ? "#f9f9f9" : "#f9f9f9", // Background color on focus
      borderColor: state.isFocused ? "#dfdede" : "#dfdede", // Change border color on focus
      color: "#909090", // Text color
      boxShadow: state.isFocused ? "none" : "none", // Box-shadow on focus
      fontFamily: '"Helvetica", sans-serif', // Custom font family
      fontSize: "14px", // Custom font size
      padding: "0 8px",
      borderRadius: "10px",
      "&:hover": {
        borderColor: "#dfdede", // Hover border color
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "50px", // Ensure consistent height for the value container (selected value area)
      padding: "0 8px", // Padding inside the value container
    }),
    input: (provided) => ({
      ...provided,
      height: "50px", // Consistent height for input inside the select
      margin: "0", // Ensure the margin does not affect the height
      padding: "0", // Padding inside input
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#cdb308"
        : state.isFocused
          ? "#f0f0f0"
          : "#ffffff", // Background color for selected and hovered options
      color: state.isSelected ? "#000000" : "#909090", // Text color for selected option
      fontFamily: '"Helvetica", sans-serif',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#ffffff", // Background of the dropdown menu
      borderColor: "#dfdede",
      borderRadius: "10px", // Custom border-radius for the dropdown menu
      overflow: "hidden",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#909090", // Color of the selected value
      fontFamily: '"Helvetica", sans-serif',
    }),
  };
  return (
    <div>
      <section>
        <Header />
      </section>
      <div className="profile">
        <div className="container">
          <div className="row prof_row_sm">
            <div className="col-lg-2 dispaly_none_1200">
              <Sidebar />
            </div>

            <div className="col-xl-10 col-lg-12 profile_pad_top">
              <div className="profile_main">
                <div className="profile_span">Profile</div>
                <div className="row">
                  <div className="col-lg-2">
                    <div className="profile_pos_rel">
                      <img
                        src={require("../assests/icons/home-user.png")}
                        alt="usericon"
                        className="user-img-profile"
                      />
                      <div className="profile_abs_edit">
                        <img
                          src={require("../assests/icons/profile-edit.png")}
                          alt="Profile edit"
                          className="profile_edit_img"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div className="profile_right_totalmain">
                      {/* <div className='profile_right_first'>
                                        <div className='profile_suhead_main'>
                                            <img src={require("../assests/icons/profile-info.png")} alt='Profile Info' className='profile_subheads_logo' />
                                            <span className='profile_suhead_span'>
                                            Personal info
                                            </span>
                                        </div>
                                        <div className='profile_rightbox_main'>
                                            <div className='profile_box_divrow'>
                                                <span className='home-viewasset-span'>Username</span>
                                                <span className='prof_boxcenter_span'>Jhonsnow661@gmail.com</span>
                                                <div className='prof_chang_box'>
                                                    <span className='prof_chang_box_span'>Change</span>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className='profile_box_divrow'>
                                                <span className='home-viewasset-span'> User ID</span>
                                                <span className='prof_boxcenter_span'> 28de0ad9-4187-406f-84bd-3863ab6a1ce7</span>
                                                <div className='prof_chang_box'>
                                                    <span className='prof_chang_box_span'>Change</span>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className='profile_box_divrow'>
                                                <span className='home-viewasset-span'>Country/Region</span>
                                                <span className='prof_boxcenter_span'>India</span>
                                                <div className='prof_chang_box'>
                                                    <span className='prof_chang_box_span'>Change</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                      {/* <div className="profile_right_first">
                        <div className="profile_suhead_main">
                          <img
                            src={require("../assests/icons/profile-info.png")}
                            alt="Profile Info"
                            className="profile_subheads_logo"
                          />
                          <span className="profile_suhead_span">
                            Personal info
                          </span>
                        </div>
                        <div className="profile_rightbox_main">
                          <div className="row">
                            <div className="col-4">
                              <span className="home-viewasset-span">
                                Username
                              </span>
                            </div>
                            <div className="col-4 ">
                              <div className="prof_tablecenter_scroll">
                                <span className="prof_boxcenter_span">
                                  Jhonsnow661@gmail.com
                                </span>
                              </div>
                            </div>
                            <div className="col-4 profile_box_divrow">
                              <button
                                className="prof_chang_box"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                <span className="prof_chang_box_span">
                                  Change
                                </span>
                              </button>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-4">
                              <span className="home-viewasset-span">
                                {" "}
                                User ID
                              </span>
                            </div>
                            <div className="col-4">
                              <div className="prof_tablecenter_scroll">
                                <span className="prof_boxcenter_span">
                                  {" "}
                                  28de0ad9-4187-406f-84bd
                                </span>
                              </div>
                            </div>
                            <div className="col-4 profile_box_divrow">
                              <button
                                className="prof_chang_box"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                <span className="prof_chang_box_span">
                                  Change
                                </span>
                              </button>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-4">
                              <span className="home-viewasset-span">
                                Country/Region
                              </span>
                            </div>
                            <div className="col-4 ">
                              <div className="prof_tablecenter_scroll">
                                <span className="prof_boxcenter_span">
                                  India
                                </span>
                              </div>
                            </div>
                            <div className="col-4 profile_box_divrow">
                              <button
                                className="prof_chang_box"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                <span className="prof_chang_box_span">
                                  Change
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="profile_right_first">
                        <div className="profile_suhead_main">
                          <img
                            src={require("../assests/icons/profile-info.png")}
                            alt="Profile Info"
                            className="profile_subheads_logo"
                          />
                          <span className="profile_suhead_span">
                            Personal info
                          </span>
                        </div>
                        <div className="profile_rightbox_main">
                          {/* Username Section */}
                          <div className="row">
                            <div className="col-4">
                              <span className="home-viewasset-span">
                                Username
                              </span>
                            </div>
                            <div className="col-4 ">
                              <div className="prof_tablecenter_scroll">
                                <span className="prof_boxcenter_span">
                                  {user.displayname}
                                </span>{" "}
                                {/* Directly use user.displayname */}
                              </div>
                            </div>
                            <div className="col-4 profile_box_divrow">
                              <button
                                className="prof_chang_box"
                                onClick={() => openModal("username")}
                              >
                                <span className="prof_chang_box_span">
                                  Change
                                </span>
                              </button>
                            </div>
                          </div>

                          <hr />
                          {/* Country Section */}
                          <div className="row">
                            <div className="col-4">
                              <span className="home-viewasset-span">
                                Country/Region
                              </span>
                            </div>
                            <div className="col-4 ">
                              <div className="prof_tablecenter_scroll">
                                <span className="prof_boxcenter_span">
                                  {options.find(option => option.value === user.country)?.label || user.country}
                                </span>
                              </div>
                            </div>
                            <div className="col-4 profile_box_divrow">
                              <button
                                className="prof_chang_box"
                                onClick={() => openModal("country")}
                              >
                                <span className="prof_chang_box_span">
                                  Change
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="profile_right_first">
                        <div className="profile_suhead_main">
                          <img
                            src={require("../assests/icons/account-details.png")}
                            alt="Acount details"
                            className="profile_subheads_logo"
                          />
                          <span className="profile_suhead_span">
                            Account details
                          </span>
                        </div>
                        <div className="profile_rightbox_main">

                          <hr />
                          {/* Email Section */}
                          <div className="row">
                            <div className="col-4">
                              <span className="home-viewasset-span">
                                Email Address
                              </span>
                            </div>
                            <div className="col-4">
                              <div className="prof_tablecenter_scroll">
                                <span className="prof_boxcenter_span">
                                  {user.email}
                                </span>
                              </div>
                            </div>
                            {/* <div className="col-4 profile_box_divrow">
                              <button
                                className="prof_chang_box"
                                onClick={() => openModal("email")} // Open modal for email change
                              >
                                <span className="prof_chang_box_span">Change</span>
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      {/* <div className="profile_right_first">
                        <div className="profile_suhead_main">
                          <img
                            src={require("../assests/icons/account-details.png")}
                            alt="Acount details"
                            className="profile_subheads_logo"
                          />
                          <span className="profile_suhead_span">
                            Account details
                          </span>
                        </div>
                        <div className="profile_rightbox_main">
                          <div className="row">
                            <div className="col-4">
                              <span className="home-viewasset-span">
                                {" "}
                                Email Address
                              </span>
                            </div>
                            <div className="col-4">
                              <div className="prof_tablecenter_scroll">
                                <span className="prof_boxcenter_span">
                                  Jhonsnow661@gmail.com
                                </span>
                              </div>
                            </div>
                            <div className="col-4 profile_box_divrow">
                              <button
                                className="prof_chang_box"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalsecond"
                              >
                                <span className="prof_chang_box_span">
                                  Change
                                </span>
                              </button>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-4">
                              <span className="home-viewasset-span">
                                {" "}
                                login password{" "}
                              </span>
                            </div>
                            <div className="col-4">
                              <div className="prof_tablecenter_scroll">
                                <span className="prof_boxcenter_span">
                                  {" "}
                                  R*********{" "}
                                </span>
                              </div>
                            </div>
                            <div className="col-4 profile_box_divrow">
                              <button
                                className="prof_chang_box"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalsecond"
                              >
                                <span className="prof_chang_box_span">
                                  Change
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className='profile_right_first'>
                                        <div className='profile_suhead_main'>
                                            <img src={require("../assests/icons/profile-share.png")} alt='Share profile' className='profile_subheads_logo' />
                                            <span className='profile_suhead_span'>
                                            Share
                                            </span>
                                        </div>
                                        <div className='profile_rightbox_main'>
                                            <div className='profile_box_divrow'>
                                                <span className='home-viewasset-span'> My invitation code</span>
                                                <span className='prof_boxcenter_span'>https://invest.untrade.io/auth/si...</span>
                                                <div className='prof_chang_box'>
                                                    <span className='prof_chang_box_span'>Change</span>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className='profile_box_divrow'>
                                                <span className='home-viewasset-span'> Invitation link </span>
                                                <span className='prof_boxcenter_span'> https://invest.untrade.io/auth/si... </span>
                                                <div className='prof_chang_box'>
                                                    <span className='prof_chang_box_span'>Change</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                      <div className="profile_right_first">
                        <div className="profile_suhead_main">
                          <img
                            src={require("../assests/icons/profile-share.png")}
                            alt="Share profile"
                            className="profile_subheads_logo"
                          />
                          <span className="profile_suhead_span">Share</span>
                        </div>
                        <div className="profile_rightbox_main">
                          <div className="row">
                            <div className="col-4">
                              <span className="home-viewasset-span">
                                {" "}
                                My invitation code
                              </span>
                            </div>

                            <div className="col-4">
                              <div className="prof_tablecenter_scroll">
                                <span className="prof_boxcenter_span">
                                  {user.referralCode
                                    ? user.referralCode
                                    : "Loading..."}
                                </span>
                              </div>
                            </div>

                            <div className="col-4 profile_box_divrow">
                              <div className="prof_chang_box">
                                <span
                                  className="prof_chang_box_span"
                                  onClick={copyToClipboard}
                                >
                                  Copy
                                </span>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-4">
                              <span className="home-viewasset-span">
                                {" "}
                                Invitation link{" "}
                              </span>
                            </div>

                            <div className="col-4">
                              <div className="prof_tablecenter_scroll">
                                <span
                                  className="prof_boxcenter_span"
                                  onClick={copyToClipboardlink}
                                >
                                  {user.referralUrl || "Generating link..."}
                                </span>
                              </div>
                            </div>

                            <div className="col-4 profile_box_divrow">
                              <div className="prof_chang_box">
                                <span
                                  className="prof_chang_box_span"
                                  onClick={copyToClipboardurlcopy}
                                >
                                  Copy
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="api_exchan_head">
                            <img
                              src={require("../assests/icons/security-withdraw.png")}
                              alt="Withdrawal addresses"
                              className="secu_head_img icons-light"
                            />
                            <img
                              src={require("../assests/icons/security-withdraw-dark.webp")}
                              alt="Withdrawal addresses"
                              className="secu_head_img icons-dark"
                            />
                            <div className="api_exchng_namemain">
                              <span className="api_exchng_namespan">
                                Withdrawal addresses
                              </span>
                              <span className="api_exchng_namedefn">
                                Add your withdraw address
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-3 pad_0_apibind_in"></div>
                        <div className="col-3 profile_box_divrow">
                          <div className="h-100 d-flex align-items-center">
                            <Link to="/security-withdraw">
                              <button
                                className="prof_chang_box"
                                type="button"
                              >
                                <span className="prof_chang_box_span">
                                  Change
                                </span>
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="profile_right_first">
                        <div className="profile_suhead_main">
                          <img
                            src={require("../assests/icons/profile-setting.png")}
                            alt="Profile setting"
                            className="profile_subheads_logo"
                          />
                          <span className="profile_suhead_span">Settings</span>
                        </div>
                        <div className="profile_rightbox_main">
                          <div className="row">
                            <div className="col-4">
                              <span className="home-viewasset-span">
                                {" "}
                                Notification
                              </span>
                            </div>
                            <div className="col-4">
                              <div className="prof_tablecenter_scroll">
                                <span className="prof_boxcenter_span">
                                  Get notified about the trade
                                </span>
                              </div>
                            </div>
                            <div className="col-4 profile_box_divrow">
                              <Switch
                                checked={checked}
                                onChange={handleChange}
                                onColor="#31BD65" // Color inside the switch when on
                                offColor="#b0bec5" // Color inside the switch when off
                                handleDiameter={18} // Diameter of the switch handle (button)
                                height={22} // Height of the switch
                                width={37} // Width of the switch
                                uncheckedIcon={false} // No icon when off
                                checkedIcon={false} // No icon when on
                                handleStyle={{
                                  boxShadow: "none", // This removes the glow or shadow around the handle
                                  backgroundColor: "white", // Ensure the handle is white
                                }}
                              />
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-4">
                              <span className="home-viewasset-span">
                                {" "}
                                Exit WY Scale{" "}
                              </span>
                            </div>
                            <div className="col-4"></div>
                            <div className="col-4 profile_box_divrow">
                              <div
                                className="prof_bottom_logout"
                                onClick={handleLogout}
                              >
                                <span className="prof_bottom_logout_span">
                                  Log Out
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div
                      class="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-dialog-centered modal-md">
                        <div class="modal-content">
                          <div class="modal-header lvl-one-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">
                              <span className="home_user_display">
                                <img
                                  src={require("../assests/icons/profile-info.png")}
                                  alt="Profile Info"
                                  className="profile_subheads_logo mb-1 mx-2"
                                />{" "}
                                Personal info
                              </span>
                            </h1>
                            <button
                              type="button"
                              class="btn-close btn-close-custom"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body personal_verify_body lvl-one-body">
                            <div className="mt-2 d-flex flex-column gap-3">
                              <div className="profile_edit_inpmain">
                                <span className="home-viewasset-span">
                                  Username
                                </span>
                                <input
                                  type="text"
                                  placeholder="Enter your Username"
                                />
                              </div>
                              <div className="profile_edit_inpmain">
                                <span className="home-viewasset-span">
                                  User ID
                                </span>
                                <input
                                  type="text"
                                  placeholder="Enter your User ID"
                                />
                              </div>
                              <div className="profile_edit_inpmain">
                                <span className="home-viewasset-span">
                                  Country/Region
                                </span>
                                <input
                                  type="text"
                                  placeholder="Enter your Country/Region"
                                />
                              </div>
                              <div className="">
                                <div className="home_topstary_follow">
                                  <span>Save</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    {isModalOpen && (
                      <div
                        className="modal fade show"
                        style={{ display: "block" }}
                        tabIndex="-1"
                        role="dialog"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered modal-md"
                          role="document"
                        >
                          <div className="modal-content">
                            <div className="modal-header lvl-one-header">
                              <h1
                                className="modal-title fs-5"
                                id="exampleModalLabel"
                              >
                                Edit{" "}
                                {editableField.charAt(0).toUpperCase() +
                                  editableField.slice(1)}
                              </h1>
                              {/* <button
                                type="button"
                                className="btn-close btn-close-custom"s
                                onClick={() => setIsModalOpen(false)}
                                aria-label="Close"
                              ></button> */}
                              <span className="btn-close-new" onClick={() => setIsModalOpen(false)}>
                                X
                              </span>
                            </div>
                            <div className="modal-body personal_verify_body lvl-one-body">
                              <div className="mt-2 d-flex flex-column gap-3">
                                <div className="profile_edit_inpmain">
                                  <span className="home-viewasset-span">
                                    {editableField.charAt(0).toUpperCase() +
                                      editableField.slice(1)}
                                  </span>
                                  {editableField === "country" ? (
                                    <Select
                                      options={options}
                                      value={options.find(option => option.value === selectedCountry)}
                                      onChange={handleCountryChange}
                                      placeholder="Select your country"
                                      styles={customStyles}
                                      className="register-country-dropdown"
                                    />
                                  ) : (
                                    <input
                                      type="text"
                                      placeholder={`Enter your ${editableField}`}
                                      value={inputValue}
                                      onChange={handleInputChange}
                                    />
                                  )}
                                  {error && (
                                    <div
                                      className="error-message"
                                      style={{ color: "red", marginTop: "5px" }}
                                    >
                                      {error}
                                    </div>
                                  )}
                                </div>
                                <div>
                                  <button
                                    className="btn btn-primary"
                                    onClick={handleSaveChanges}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}



                    <div
                      class="modal fade"
                      id="exampleModalsecond"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-dialog-centered modal-md">
                        <div class="modal-content">
                          <div class="modal-header lvl-one-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">
                              <span className="home_user_display">
                                <img
                                  src={require("../assests/icons/account-details.png")}
                                  alt="Acount details"
                                  className="profile_subheads_logo mb-1 mx-2"
                                />{" "}
                                Account details
                              </span>
                            </h1>
                            <button
                              type="button"
                              class="btn-close btn-close-custom"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body personal_verify_body lvl-one-body">
                            <div className="mt-2 d-flex flex-column gap-3">
                              <div className="profile_edit_inpmain">
                                <span className="home-viewasset-span">
                                  Email Address
                                </span>
                                <input
                                  type="text"
                                  placeholder="Enter your Email Address"
                                />
                              </div>
                              <div className="profile_edit_inpmain">
                                <span className="home-viewasset-span">
                                  login password
                                </span>
                                <input
                                  type="text"
                                  placeholder="Enter your login password"
                                />
                              </div>
                              <div className="mt-3">
                                <div className="home_topstary_follow">
                                  <span>Save</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
