
import React, { useState, useRef, useEffect, useCallback } from "react";
import useStateRef from "react-usestateref";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";

import { Link } from "react-router-dom";

import service from "../services/authservices";

function DepositHistory() {
    const [currency, setCurrency] = useState("USDC");
    const [network, setNetwork] = useState("BEP20");
    const [addressData, setAddressData] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchAddressData = async () => {
        try {
            const data = await service.getUserAddressNetwork(currency, network);
            if (data.status) {
                setAddressData(data.data);
            } else {
                console.log(data.Message);
            }
        } catch (error) {
            console.error("Error fetching address data:", error);
        }
    };

    const fetchTransactions = async () => {
        setLoading(true);
        try {
            const data = await service.getTransactions();
            if (data.status) {
                if (Array.isArray(data.deposits) && data.deposits.length > 0) {
                    // Filter unique transactions based on txID
                    const uniqueTransactions = data.deposits.filter(
                        (value, index, self) =>
                            index === self.findIndex((t) => t.txID === value.txID)
                    );
                    setTransactions(uniqueTransactions);
                } else {
                    // If no deposits or empty deposits, set an empty array
                    setTransactions([]);
                    //toast.error("No transactions found");
                }
            } else {
                setTransactions([]);
                //toast.error("No transactions found");
            }
        } catch (error) {
            console.error("Error fetching transactions:", error);
            setTransactions([]);
            toast.error(
                <div className="toast-ml">
                    <h4 className="toast-title">Error!</h4>
                    <p className="toast-message">Failed to load transactions</p>
                </div>
            );
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAddressData();
        fetchTransactions();
    }, [currency, network]);

    const handleCopy = (txID) => {
        // Prevent triggering multiple times rapidly
        if (handleCopy.timeout) clearTimeout(handleCopy.timeout);

        handleCopy.timeout = setTimeout(() => {
            navigator.clipboard
                .writeText(txID)
                .then(() => {
                    toast.success(
                        <div className="toast-ml">
                            <h4 className="toast-title">Success!</h4>
                            <p className="toast-message">
                                Transaction ID copied to clipboard!
                            </p>
                        </div>
                    );
                })
                .catch((err) => {
                    console.error("Failed to copy text: ", err);
                    toast.error(
                        <div className="toast-ml">
                            <h4 className="toast-title">Error!</h4>
                            <p className="toast-message">Failed to copy Transaction ID</p>
                        </div>
                    );
                });
        }, 300); // 300ms debounce time
    };

    const handleCopyAdd = (address) => {
        // Prevent triggering multiple times rapidly
        if (handleCopyAdd.timeout) clearTimeout(handleCopyAdd.timeout);

        handleCopyAdd.timeout = setTimeout(() => {
            navigator.clipboard
                .writeText(address)
                .then(() => {
                    toast.success(
                        <div className="toast-ml">
                            <h4 className="toast-title">Success!</h4>
                            <p className="toast-message">Address copied to clipboard!</p>
                        </div>
                    );
                })
                .catch((err) => {
                    console.error("Failed to copy text: ", err);
                    toast.error(
                        <div className="toast-ml">
                            <h4 className="toast-title">Error!</h4>
                            <p className="toast-message">Failed to copy address</p>
                        </div>
                    );
                });
        }, 300); // 300ms debounce time
    };



    return (

        <div className="recent_depo_main">
            <div className="manage_btn_row">
                <span className="home_right_marketspan">Recent Deposit</span>
                <Link to="/deposit">
                    <div className="d-flex align-items-center gap-2 cursor-pointer">
                        <span className="home_starty_view">Back to Deposit</span>
                        <i class="fa-solid fa-chevron-right home_starty_viewarrow"></i>
                    </div>
                </Link>
            </div>
            <div className="recent_depo_tablemain pb-0">
                <div className="table-responsive table-borderless home_recent_table">
                    {loading ? (
                        <p className="depo_no_record">Loading...</p>
                    ) : (
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="text_left">Currency</th>
                                    <th>Date & Time</th>
                                    <th>Network</th>
                                    <th>Amount</th>
                                    <th>Transaction ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transactions.length > 0 ? (
                                    transactions.slice(0, 5).map((transaction, index) => (
                                        <tr key={index}>
                                            <td>
                                                <div className="home_recent_firstdata">
                                                    <img
                                                        src={require("../assests/usdt-logo.png")}
                                                        alt="USDT"
                                                        className="usdt_home_recent"
                                                    />
                                                    <span>{transaction.currency}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <span>
                                                    {isNaN(new Date(transaction.timestamp))
                                                        ? "null"
                                                        : dayjs(transaction.timestamp).format(
                                                            "DD MMMM YYYY, hh:mm A"
                                                        )}
                                                </span>
                                            </td>
                                            <td>
                                                <span>{transaction.network}</span>
                                            </td>
                                            <td>
                                                <span>{transaction.amount}</span>
                                            </td>
                                            <td>
                                                <div className="home_uid_main">
                                                    <span>{transaction.txID}</span>
                                                    <i
                                                        className="ri-file-copy-line cursor-pointer uid_copy_recent"
                                                        onClick={() =>
                                                            handleCopy(transaction.txID)
                                                        }
                                                    ></i>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5">
                                            <span className="depo_no_record">
                                                No transactions found
                                            </span>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    )
}


export default DepositHistory