import { Link } from "react-router-dom";
import Header from "./Header";

const PageNotFound = () => {
  return (
    <>
      <div className="page-head-none">
        <Header />
      </div>

      <div className="page-not-found">
        <div className="not-found-img-wrap">
          <img
            src={require("../assests/not-found.webp")}
            alt="page-not-found"
            className="page-img-light"
          />
          <img
            src={require("../assests/not-found-dark.webp")}
            alt="page-not-found"
            className="page-img-dark"
          />
        </div>
        <h3 className="not-found-title">Page Not Found</h3>
        <p className="not-found-content">
          The page you're looking for seems to be missing.
        </p>
        <Link to="/" className="not-found-btn">
          Go Back Home Page
        </Link>
      </div>
    </>
  );
};

export default PageNotFound;
