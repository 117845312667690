import React, { useMemo, useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import countryList from "react-select-country-list";
import authService from "../services/authservices";
import { UserContext } from "../contexts/UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Register() {
  const [value, setValue] = useState(null);
  const [signloader, setSignloader] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const location = useLocation();
  const [referralCode, setReferralCode] = useState("");
  const { user } = useContext(UserContext);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const inviteCode = queryParams.get("invite");

    if (inviteCode) {
      setReferralCode(inviteCode);
    }
  }, [location]);

  const [formData, setFormData] = useState({
    email: "",
    country: "",
    password: "",
    confirmPassword: "",
    referralCode: "",
    agree: false,
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  const options = useMemo(() => countryList().getData(), []);

  const handleChange = (selectedOption) => {
    setValue(selectedOption);
    setFormData({ ...formData, country: selectedOption.label });

    // Clear the error for the country field if a valid country is selected
    let newErrors = { ...errors };
    if (selectedOption.label) {
      delete newErrors.country;
    }
    setErrors(newErrors);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Individual field validation
    let newErrors = { ...errors };
    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!value) newErrors.email = "Email is required";
      else if (!emailRegex.test(value))
        newErrors.email = "Invalid email format";
      else if (value.length > 250)
        newErrors.email = "Email cannot exceed 250 characters";
      else delete newErrors.email;
    } else if (name === "password") {
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/;
      if (!value) newErrors.password = "Password is required";
      else if (!passwordRegex.test(value))
        newErrors.password =
          "Password must be 8-15 characters long, include uppercase, lowercase, number, and special character";
      else delete newErrors.password;
    } else if (name === "confirmPassword") {
      if (!value) newErrors.confirmPassword = "Confirm Password is required";
      else if (value !== formData.password)
        newErrors.confirmPassword = "Passwords do not match";
      else delete newErrors.confirmPassword;
    } else if (name === "referralCode") {
      const referralCodeRegex = /^[a-zA-Z0-9]{1,13}$/;
      if (value && !referralCodeRegex.test(value))
        newErrors.referralCode =
          "Referral code must be alphanumeric and 1-13 characters long";
      else delete newErrors.referralCode;
    }

    setErrors(newErrors);
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, agree: e.target.checked });

    // Clear the error for the agree field if the checkbox is checked
    let newErrors = { ...errors };
    if (e.target.checked) {
      delete newErrors.agree;
    }
    setErrors(newErrors);
  };

  const validate = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/;
    const referralCodeRegex = /^[a-zA-Z0-9]{1,13}$/;

    if (!formData.email) {
      newErrors.email = "Email is required";
      return newErrors;
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Invalid email format";
      return newErrors;
    } else if (formData.email.length > 250) {
      newErrors.email = "Email cannot exceed 250 characters";
      return newErrors;
    }

    if (!formData.country) {
      newErrors.country = "Country is required";
      return newErrors;
    }

    if (!formData.password) {
      newErrors.password = "Password is required";
      return newErrors;
    } else if (!passwordRegex.test(formData.password)) {
      newErrors.password =
        "Password must be 8-15 characters long, include uppercase, lowercase, number, and special character";
      return newErrors;
    }

    if (!formData.confirmPassword) {
      newErrors.confirmPassword = "Confirm Password is required";
      return newErrors;
    } else if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
      return newErrors;
    }

    if (
      formData.referralCode &&
      !referralCodeRegex.test(formData.referralCode)
    ) {
      newErrors.referralCode =
        "Referral code must be alphanumeric and 1-13 characters long";
      return newErrors;
    }

    if (!formData.agree) {
      newErrors.agree = "You must agree to the terms and conditions";
      return newErrors;
    }

    return newErrors;
  };

  const handleKeyDown = (e, type) => {
    if (type === "email") {
      const regex = /^[a-zA-Z0-9@._-]+$/;
      if (
        !regex.test(e.key) ||
        (e.target.value.length >= 250 && e.key !== "Backspace")
      ) {
        e.preventDefault();
      }
    } else if (type === "password" || type === "confirmPassword") {
      if (
        e.key === " " ||
        (e.target.value.length >= 15 && e.key !== "Backspace")
      ) {
        e.preventDefault();
      }
    } else if (type === "referralCode") {
      const regex = /^[a-zA-Z0-9]*$/;
      if (
        !regex.test(e.key) ||
        (e.target.value.length >= 13 && e.key !== "Backspace")
      ) {
        e.preventDefault();
      }
    }
  };
  const registrationData = {
    ...formData,
    referralCode: user?.referralCode || "", // Add the referral code if available
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);

      // Focus on the first invalid field and return immediately
      const firstErrorField = Object.keys(validationErrors)[0];
      const firstErrorElement = document.getElementsByName(firstErrorField)[0];
      if (firstErrorElement) {
        firstErrorElement.focus();
      }
      return; // Stop further processing
    }
    try {
      setSignloader(true); //button disabled
      const registrationData = {
        ...formData,
        referralCode:
          new URLSearchParams(window.location.search).get("invite") || "", // Get referral code from URL
      };
      const response = await authService.register(registrationData);
      setSignloader(false); //button enabled
      if (response.status) {
        setUser({ email: formData.email });
        toast.success(
          <div className="toast-ml">
            <h4 className="toast-title">Success!</h4>
            <p className="toast-message">{response.Message}</p>
          </div>
        );
        setTimeout(() => {
          navigate("/verification");
        }, 1000);
      } else {
        toast.error(
          <div className="toast-ml">
            <h4 className="toast-title">Error!</h4>
            <p className="toast-message">{response.Message}</p>
          </div>
        );
      }
    } catch (error) {
      setSignloader(false); //button enabled
      toast.error(
        <div className="toast-ml">
          <h4 className="toast-title">Error!</h4>
          <p className="toast-message">
            Something went wrong, please try again later.
          </p>
        </div>
      );
      console.error("Error during registration:", error);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: "50px",
      minHeight: "44px",
      backgroundColor: state.isFocused ? "#f9f9f9" : "#f9f9f9", // Background color on focus
      borderColor: state.isFocused ? "#dfdede" : "#dfdede", // Change border color on focus
      color: "#909090", // Text color
      boxShadow: state.isFocused ? "none" : "none", // Box-shadow on focus
      fontFamily: '"Helvetica", sans-serif', // Custom font family
      fontSize: "14px", // Custom font size
      padding: "0 8px",
      borderRadius: "10px",
      "&:hover": {
        borderColor: "#dfdede", // Hover border color
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "50px", // Ensure consistent height for the value container (selected value area)
      padding: "0 8px", // Padding inside the value container
    }),
    input: (provided) => ({
      ...provided,
      height: "50px", // Consistent height for input inside the select
      margin: "0", // Ensure the margin does not affect the height
      padding: "0", // Padding inside input
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#cdb308"
        : state.isFocused
        ? "#f0f0f0"
        : "#ffffff", // Background color for selected and hovered options
      color: state.isSelected ? "#000000" : "#909090", // Text color for selected option
      fontFamily: '"Helvetica", sans-serif',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#ffffff", // Background of the dropdown menu
      borderColor: "#dfdede",
      borderRadius: "10px", // Custom border-radius for the dropdown menu
      overflow: "hidden",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#909090", // Color of the selected value
      fontFamily: '"Helvetica", sans-serif',
    }),
  };

  return (
    <div>
      <div className="row register_main container-fluid">
        <div className="col-lg-6">
          <div className="d-flex flex-column mb-5">
            <div className="register_head_logo">
              <Link to="/">
                <img
                  src={require("../assests/Logo.png")}
                  alt="Logo"
                  className="register_logo cursor-pointer"
                />
              </Link>
            </div>
            <div className="register_left_main mt-3">
              <div className="hero_center">
                <div className="register_form">
                  <span className="register_head_sign">Sign Up</span>
                  <div className="register_inner_main">
                    <div className="register_inner_submain">
                      <div className="reg_inner_small">
                        <span className="register_spans">Email Address</span>
                        <input
                          type="text"
                          name="email"
                          placeholder="Enter the email address"
                          className="reg_input"
                          value={formData.email}
                          onChange={handleInputChange}
                          onKeyDown={(e) => handleKeyDown(e, "email")}
                        />
                        {errors.email && (
                          <span className="error">{errors.email}</span>
                        )}
                      </div>

                      <div className="reg_inner_small">
                        <span className="register_spans">Country</span>
                        <Select
                          options={options}
                          value={value}
                          onChange={handleChange}
                          placeholder="Select your country"
                          // className='reg_input_drop'
                          styles={customStyles}
                          className="register-country-dropdown"
                        />
                        {errors.country && (
                          <span className="error">{errors.country}</span>
                        )}
                      </div>
                      <div className="reg_inner_small">
                        <span className="register_spans">Password</span>
                        <div className="reg_pos_rel">
                          <input
                            type={passwordVisible ? "text" : "password"}
                            name="password"
                            placeholder="Enter the password"
                            className="reg_input"
                            value={formData.password}
                            onChange={handleInputChange}
                            onKeyDown={(e) => handleKeyDown(e, "password")}
                          />
                          <i
                            className={`fa-regular ${
                              passwordVisible ? "fa-eye" : "fa-eye-slash"
                            } reg_eye`}
                            onClick={togglePasswordVisibility}
                          />
                        </div>
                        {errors.password && (
                          <span className="error">{errors.password}</span>
                        )}
                      </div>
                      <div className="reg_inner_small">
                        <span className="register_spans">Confirm Password</span>
                        <div className="reg_pos_rel">
                          <input
                            type={confirmPasswordVisible ? "text" : "password"}
                            name="confirmPassword"
                            placeholder="Re-enter the password"
                            className="reg_input"
                            value={formData.confirmPassword}
                            onChange={handleInputChange}
                            onKeyDown={(e) =>
                              handleKeyDown(e, "confirmPassword")
                            }
                          />
                          <i
                            className={`fa-regular ${
                              confirmPasswordVisible ? "fa-eye" : "fa-eye-slash"
                            } reg_eye`}
                            onClick={toggleConfirmPasswordVisibility}
                          />
                        </div>
                        {errors.confirmPassword && (
                          <span className="error">
                            {errors.confirmPassword}
                          </span>
                        )}
                      </div>
                      <div className="reg_inner_small">
                        <span className="register_spans">
                          Referral Code (Optional)
                        </span>
                        <input
                          type="text"
                          name="referralCode"
                          placeholder="Enter the referral code"
                          className="reg_input"
                          value={referralCode}
                          onChange={handleInputChange}
                          onKeyDown={(e) => handleKeyDown(e, "referralCode")}
                        />
                      </div>
                    </div>
                    <div className="getting_card_content">
                      <div className="reg_check_main align-center">
                        <div class="checkbox-container">
                          <input
                            id="custom-checkbox"
                            type="checkbox"
                            className="input-field regular_checkbox"
                            checked={formData.agree}
                            onChange={handleCheckboxChange}
                          />
                          <label htmlFor="custom-checkbox"></label>
                        </div>
                        <span
                          htmlFor="custom-checkbox"
                          className="register_spans mt-1"
                        >
                          I agree to the
                          <span className="reg_check_span">
                            {" "}
                            terms and conditions.
                          </span>
                        </span>
                      </div>
                      {errors.agree && (
                        <span className="error">{errors.agree}</span>
                      )}
                      {signloader === true ? (
                        <div className="reg_btn">
                          <span className="hero_invest"> Loading... </span>
                        </div>
                      ) : (
                        <div className="reg_btn" onClick={handleSubmit}>
                          <span className="hero_invest"> Sign Up </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <span className="reg_bottom_span">
                  Already have an account?
                  <Link to="/login">
                    {" "}
                    <span className="reg_check_span">Log In.</span>{" "}
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 register_right_main">
          <div className="register_right">
            <div className="footer_links_div">
              <span className="register_head">
                Empower Your Trades, Sign Up!
              </span>
              <span className="register_subhead">
                {" "}
                Join us! Sign up to unlock powerful trading tools and <br />
                elevate your trading experience!
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
