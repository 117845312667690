import React, { useContext, useEffect, useRef } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";

import { Link, useNavigate } from "react-router-dom";
import authService from "../services/authservices";
import { UserContext } from "../contexts/UserContext";
import { ToastContainer, toast } from "react-toastify";
import { getUserDetails } from "../services/authservices";
import { getExchanges } from "../services/authservices";
import "react-toastify/dist/ReactToastify.css";

function ApiBind() {
  const [otpSent, setOtpSent] = useState(false);
  const [timer, setTimer] = useState(120);
  const [apiKey, setApiKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [isBounded, setIsBounded] = useState(false);
  const [exchanges, setExchanges] = useState([]);
  const [error, setError] = useState(null);
  const [bindStates, setBindStates] = useState({});
  const [exchangeName, setExchangeName] = useState("Binance");
  const [formStates, setFormStates] = useState({});

  // Individual error states
  const [apiKeyError, setApiKeyError] = useState("");
  const [secretKeyError, setSecretKeyError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [user, setUser] = useState({
    tfaStatus: 0,
    email: null,
    uid: null,
    referralCode: null,
    referralUrl: null,
    displayname: null,
    country: null,
    isAuthenticated: false,
    exchanges: [],
  });
  const [loading, setLoading] = useState(false);
  const fetchUserDetails = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const data = await getUserDetails(token);
        if (data && data.status) {
          const selectedExchange = data.exchanges.find(
            (exchange) => exchange.name === data.exchangeName
          );

          const userDetails = {
            isAuthenticated: true,
            tfaStatus: data.tfastatus,
            email: data.email,
            displayname: data.displayname,
            country: data.country,
            uid: data.uid,
            referralCode: data.referralCode,
            referralUrl: data.referralUrl,
            exchanges: data.exchanges,
            apiKey: selectedExchange?.apiKey || null,
            secretKey: selectedExchange?.secretKey || null,
            exchangeName: selectedExchange?.name || null,
          };

          setUser(userDetails);
          console.log(userDetails, "User Details Updated");

          if (selectedExchange?.apiKey && selectedExchange?.secretKey) {

            setExchanges((prevExchanges) =>
              prevExchanges.map((exchange) =>
                exchange.name === selectedExchange.name
                  ? {
                    ...exchange,
                    isBounded: true,
                    apiKey: selectedExchange.apiKey,
                    secretKey: selectedExchange.secretKey,
                  }
                  : exchange
              )
            );
          }
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        setUser({
          isAuthenticated: false,
          tfaStatus: 0,
          email: null,
          uid: null,
          referralCode: null,
          referralUrl: null,
        });
      }
    } else {
      setUser({
        isAuthenticated: false,
        tfaStatus: 0,
        email: null,
        uid: null,
        referralCode: null,
        referralUrl: null,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserDetails();
    fetchExchanges();
  }, []);

  const fetchExchanges = async () => {
    try {
      const data = await getExchanges();
      if (Array.isArray(data) && data.length > 0) {
        setExchanges(data);
      } else {
        console.error("No exchanges returned from API");
        setExchanges([]);
      }
    } catch (err) {
      console.error("Error fetching exchanges:", err);
      setExchanges([]);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === " " && !e.ctrlKey && !e.metaKey) {
      e.preventDefault();
    }
  };

  const handleKeyDownApiSecret = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const sendOtp = async (index) => {
    if (!formStates[index]?.apiKey || !formStates[index]?.secretKey) {
      toast.error(
        <div className="toast-ml">
          <h4 className="toast-title">Error!</h4>
          <p className="toast-message">
            Please fill in both API Key and Secret Key fields before sending
            OTP.
          </p>
        </div>
      );
      return;
    }

    if (!user.email) {
      toast.error(
        <div className="toast-ml">
          <h4 className="toast-title">Error!</h4>
          <p className="toast-message">
            User email is not available. Please try again.
          </p>
        </div>
      );
      return;
    }

    try {
      const response = await authService.resendOtp({ email: user.email });
      if (response.data.status) {
        toast.success(
          <div className="toast-ml">
            <h4 className="toast-title">Success!</h4>
            <p className="toast-message">OTP sent successfully!</p>
          </div>
        );
        setOtpSent(true);
        setTimer(120);
      } else {
        toast.error(
          // response.data.Message || "Failed to send OTP. Please try again."

          <div className="toast-ml">
            <h4 className="toast-title">Error!</h4>
            <p className="toast-message">
              {response.data.Message || "Failed to send OTP. Please try again."}
            </p>
          </div>
        );
      }
    } catch (error) {
      toast.error(
        <div className="toast-ml">
          <h4 className="toast-title">Error!</h4>
          <p className="toast-message">An error occurred. Please try again.</p>
        </div>
      );
    }
  };

  const handleCopy = (text) => {
    if (handleCopy.timeout) clearTimeout(handleCopy.timeout);

    handleCopy.timeout = setTimeout(() => {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          toast.success(
            <div className="toast-ml">
              <h4 className="toast-title">Success!</h4>
              <p className="toast-message">Text copied to clipboard!</p>
            </div>
          );
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
          toast.error(
            <div className="toast-ml">
              <h4 className="toast-title">Error!</h4>
              <p className="toast-message">Failed to copy text</p>
            </div>
          );
        });
    }, 300);
  };

  useEffect(() => {
    let countdown;
    if (otpSent && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setOtpSent(false);
      clearInterval(countdown);
    }
    return () => clearInterval(countdown);
  }, [timer, otpSent]);
  const handleInputChange = (e, index, field) => {
    const value = e.target.value;
    if (value.length > 128) {
      return;
    }
    setApiKey(value);
    if (value === "") {
      setApiKeyError("API Key is required");
    } else if (value.length < 8) {
      setApiKeyError("API Key must be at least 20 characters long");
    }
    //  else if (!/^[a-zA-Z0-9]{32,}$/.test(value)) {
    //   setApiKeyError('Please enter a valid API Key');
    // }
    else {
      setApiKeyError("");
    }
    setFormStates((prevFormStates) => ({
      ...prevFormStates,
      [index]: {
        ...prevFormStates[index],
        [field]: value,
      },
    }));

    // Add validation if required
    if (field === "apiKey" && value === "") {
      setApiKeyError("API Key is required");
    } else if (field === "secretKey" && value === "") {
      setSecretKeyError("Secret Key is required");
    } else {
      setApiKeyError("");
      setSecretKeyError("");
    }
  };
  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    setFormStates((prevFormStates) => ({
      ...prevFormStates,
      [index]: {
        ...prevFormStates[index],
        verificationCode: value,
      },
    }));
    if (value === "") {
      setOtpError("OTP is required");
    } else if (value.length !== 6) {
      setOtpError("OTP should be exactly 6 digits");
    } else {
      setOtpError("");
    }
  };
  const handleSubmit = async (e, index) => {
    e.preventDefault();
    const { apiKey, secretKey, verificationCode } = formStates[index] || {};

    if (!apiKey || !secretKey || !verificationCode) {
      toast.error(
        <div className="toast-ml">
          <h4 className="toast-title">Error!</h4>
          <p className="toast-message">Please fill in all fields.</p>
        </div>
      );
      return;
    }

    try {
      // Step 1: Verify OTP
      const otpResponse = await authService.verifyOtp({
        email: user.email,
        emailOtp: Number(verificationCode),
      });

      if (!otpResponse.status) {
        setOtpError(otpResponse.Message);
        toast.error(
          <div className="toast-ml">
            <h4 className="toast-title">Error!</h4>
            <p className="toast-message">{otpResponse.Message}</p>
          </div>
        );
        return;
      }

      // toast.success(
      //   <div className="toast-ml">
      //     <h4 className="toast-title">Success!</h4>
      //     <p className="toast-message">OTP verified successfully!</p>
      //   </div>
      // );


      const storeResponse = await authService.storeApiKey({
        apiKey,
        secretKey,
        exchangeName: exchanges[index].name,
      });
      console.log(storeResponse, ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
      if (storeResponse) {
        toast.success(
          <div className="toast-ml">
            <h4 className="toast-title">Success!</h4>
            <p className="toast-message">API key stored successfully!</p>
          </div>
        );
        //console.log(storeResponse, ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
        // Update exchanges state
        // setExchanges((prevExchanges) =>
        //   prevExchanges.map((exchange, i) =>
        //     i === index
        //       ? { ...exchange, isBounded: false, apiKey, secretKey }
        //       : exchange
        //   )
        // );

        // setFormStates((prevFormStates) => ({
        //   ...prevFormStates,
        //   [index]: { apiKey, secretKey, verificationCode, isBounded: true },
        // }));

        fetchUserDetails();

        // Sync data to localStorage
        localStorage.setItem(`apiKey-${exchanges[index].name}`, apiKey);
        localStorage.setItem(`secretKey-${exchanges[index].name}`, secretKey);
        localStorage.setItem(`isBounded-${exchanges[index].name}`, "true");
      } else {
        toast.error(
          <div className="toast-ml">
            <h4 className="toast-title">Error!</h4>
            <p className="toast-message">Failed to store API key.</p>
          </div>
        );
      }
    } catch (error) {
      toast.error(
        <div className="toast-ml">
          <h4 className="toast-title">Error!</h4>
          <p className="toast-message">
            An error occurred while binding the API keys.
          </p>
        </div>
      );
    }
  };
  useEffect(() => {
    const fetchExchangeDetailsFromUser = () => {
      const updatedExchanges = exchanges.map((exchange) => {
        const userExchange = user.exchanges
          ? user.exchanges.find((e) => e.name === exchange.name)
          : null;

        const apiKey = userExchange ? userExchange.apiKey : "";
        const secretKey = userExchange ? userExchange.secretKey : "";
        const isBounded = userExchange ? userExchange.isBounded : false;

        return {
          ...exchange,
          apiKey,
          secretKey,
          isBounded,
        };
      });

      setExchanges(updatedExchanges);
      console.log(updatedExchanges, "???????????????????????????????");
    };

    if (user && user.exchanges) {
      fetchExchangeDetailsFromUser();
    }
  }, [user]);

  useEffect(() => {
    const userExchanges = user.exchanges || [];

    const initializeFormStates = exchanges.reduce((acc, exchange, index) => {
      const userExchange = userExchanges.find(
        (userExchange) => userExchange.exchangeName === exchange.name
      );
      acc[index] = {
        apiKey: userExchange?.apiKey || "",
        secretKey: userExchange?.secretKey || "",
        isBounded: userExchange?.isBounded || false,
      };
      return acc;
    }, {});

    setFormStates(initializeFormStates);
  }, [user, exchanges]);

  const [binanceContinuestate, setBinanceContinuestate] = useState(
    new Array(exchanges.length).fill(false)
  );

  const handleContinueClick = (index) => {
    setBinanceContinuestate((prevStates) => {
      const updatedStates = [...prevStates];
      updatedStates[index] = true;
      return updatedStates;
    });
  };

  const handleBindToggle = (index) => {
    setFormStates((prevFormStates) => ({
      ...prevFormStates,
      [index]: {
        ...prevFormStates[index],
        isBounded: !prevFormStates[index]?.isBounded,
      },
    }));

    setExchanges((prevExchanges) =>
      prevExchanges.map((exchange, i) =>
        i === index ? { ...exchange, isBounded: !exchange.isBounded } : exchange
      )
    );
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <section>
        <Header />
      </section>

      <div className="api-bind-section">
        <div className="container">
          <div className="row prof_row_sm">
            <div className="col-lg-2 dispaly_none_1200">
              <Sidebar />
            </div>
            <div className="col-xl-10 col-lg-12 deposit_pad_top">
              <div className="row api_main">
                <div className="col-xl-5 col-lg-8 col-md-10 col-sm-9 text-center">
                  <span className="api_top_center">
                    Bind Exchange for seamless crypto trading
                  </span>
                </div>
              </div>
              <div className="api_second_main">
                {exchanges.map((exchange, index) => (
                  <div className="apibind_top_work" key={exchange._id}>
                    <div className="row">
                      <div className="col-6">
                        <div className="api_exchan_head">
                          <img
                            src={exchange.icon}
                            alt={exchange.name}
                            className="api_exchng_img"
                          />
                          <div className="api_exchng_namemain">
                            <span className="api_exchng_namespan">
                              {exchange.name}
                            </span>
                            <span className="api_exchng_namedefn">
                              {exchange.description}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-3 pad_0_apibind_in">
                        <div className="api_inner_center">
                          {user.exchanges?.some(
                            (userExchange) =>
                              userExchange.exchangeName === exchange.name
                          ) ? (
                            <>
                              <i className="fa-regular fa-circle-check home_user_info_correct"></i>
                              <span className="api_boundchange_span">
                                Bounded
                              </span>
                            </>
                          ) : (
                            <>
                              <i
                                className="bi bi-exclamation-circle home_user_info"
                                style={{
                                  color: "#ee516d",
                                  marginBottom: "1px",
                                }}
                              ></i>
                              <span className="api_unbound_span">
                                Unbounded
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-3 profile_box_divrow">
                        <div className="h-100 d-flex align-items-center">
                          <button
                            className="prof_chang_box"
                            type="button"
                            onClick={() => handleBindToggle(index)}
                          >
                            <span className="prof_chang_box_span">
                              {exchange.isBounded ? "Cancel" : "Bind"}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>

                    {exchange.isBounded && (
                      <div
                        className="binance_bind_truemain"
                        style={{
                          maxHeight: exchange.isBounded ? "780px" : "0px",
                          overflow: "hidden",
                          transition: "max-height 1s ease",
                        }}
                      >
                        <div className="row">
                          <div className="col-lg-6">
                            {!binanceContinuestate[index] && (
                              <div className="bina_bind_inimp">
                                <div className="bina_bind_inimp_top">
                                  <span className="bina_bind_inimp_heading">
                                    Bind {exchange.name} Exchange
                                  </span>
                                  <div className="bin_bind_innersmall">
                                    <div className="bin_bind_innerinnn">
                                      <span className="bin_bind_innerinnn_subhead">
                                        1. Log in to {exchange.name}
                                      </span>
                                      <span className="bin_bind_innsubhead_sub">
                                        Go to{" "}
                                        <a
                                          href={`https://www.${exchange.name.toLowerCase()}.com`}
                                        >
                                          www.{exchange.name.toLowerCase()}.com
                                        </a>{" "}
                                        and log into your account.
                                      </span>
                                    </div>
                                    <div className="bin_bind_innerinnn">
                                      <span className="bin_bind_innerinnn_subhead">
                                        2. Create API Key
                                      </span>
                                      <span className="bin_bind_innsubhead_sub">
                                        Navigate to "API Management," create a
                                        new API key, and complete the
                                        verification.
                                      </span>
                                    </div>
                                    <div className="bin_bind_innerinnn">
                                      <span className="bin_bind_innerinnn_subhead">
                                        3. Copy API Key and Secret
                                      </span>
                                      <span className="bin_bind_innsubhead_sub">
                                        Copy the API Key and Secret after
                                        creation; keep them secure.
                                      </span>
                                    </div>
                                    <div className="bin_bind_innerinnn">
                                      <span className="bin_bind_innerinnn_subhead">
                                        4. Bind to WY Scale
                                      </span>
                                      <span className="bin_bind_innsubhead_sub">
                                        Log into WY Scale, paste the API Key and
                                        Secret in the "API Bind" section, and
                                        save changes.
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="bin_bind_submit_div"
                                  onClick={() => handleContinueClick(index)}
                                // onClick={() => setBinanceContinuestate(true)}
                                >
                                  <span className="bin_bin_sub_span">
                                    Continue
                                  </span>
                                </div>
                              </div>
                            )}
                            {binanceContinuestate[index] && (
                              <div className="bina_bind_inimp">
                                <div className="bina_bind_inimp_top">
                                  <div className="bin_bind_innersmall_next">
                                    <span className="bind_bi_precaution">
                                      Precautions
                                    </span>
                                    <span className="bind_bi_precaution_def">
                                      Please enter the correct API, please do
                                      not enter special characters
                                    </span>
                                  </div>
                                  <div className="bin_bind_innersmall_next w-100">
                                    <span className="bind_bi_precaution">
                                      IP group binding
                                    </span>
                                    <span className="bind_bi_precaution_def">
                                      For security purpose, {exchange.name}{" "}
                                      Exchange recommends binding the server IP
                                      address when creating the API.
                                    </span>
                                    <div className="pos_rel w-100">
                                      <input
                                        className="bind_bin_next_inp"
                                        placeholder={exchange.ip}
                                        readOnly
                                      />
                                      <span
                                        className="bind_bin_next_inp_Copy"
                                        onClick={() =>
                                          handleCopy(exchange.ip)
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        Copy
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="bin_bin_apimain">
                                  <span className="bin_bin_apimain_span">
                                    API Key
                                  </span>

                                  <input
                                    className="bind_bin_next_inp"
                                    placeholder="Please enter API key"
                                    type="text"
                                    value={formStates[index]?.apiKey || ""}
                                    onChange={(e) =>
                                      handleInputChange(e, index, "apiKey")
                                    }
                                    onKeyDown={handleKeyDownApiSecret}
                                  />
                                  {apiKeyError && (
                                    <div className="error">{apiKeyError}</div>
                                  )}
                                </div>
                                <div className="bin_bin_apimain">
                                  <span className="bin_bin_apimain_span">
                                    Secret Key
                                  </span>
                                  <input
                                    className="bind_bin_next_inp"
                                    placeholder="Please enter Secret key"
                                    type="text"
                                    value={formStates[index]?.secretKey || ""}
                                    onChange={(e) =>
                                      handleInputChange(e, index, "secretKey")
                                    }
                                    onKeyDown={handleKeyDownApiSecret}
                                  />
                                  {secretKeyError && (
                                    <div className="error">
                                      {secretKeyError}
                                    </div>
                                  )}
                                </div>

                                <div className="bin_bin_apimain">
                                  <span className="bin_bin_apimain_span">
                                    Enter OTP
                                  </span>
                                  <div className="pos_rel w-100">
                                    <input
                                      type="text"
                                      maxLength="6"
                                      name="emailOtp"
                                      placeholder="Enter the verification code"
                                      className="bind_bin_next_inp"
                                      value={
                                        formStates[index]?.verificationCode ||
                                        ""
                                      }
                                      onChange={(e) =>
                                        handleOtpChange(e, index)
                                      }
                                      onKeyDown={handleKeyDown}
                                    />
                                    {otpSent ? (
                                      <span className="bind_bin_next_inp_Copy">
                                        Resend ({timer}s)
                                      </span>
                                    ) : (
                                      <span
                                        className="bind_bin_next_inp_Copy"
                                        onClick={() => sendOtp(index)}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {timer === 120 ? "Send OTP" : "Resend"}
                                      </span>
                                    )}
                                  </div>
                                  {otpError &&
                                    formStates[index]?.verificationCode
                                      ?.length !== 6 && (
                                      <p className="error">
                                        OTP should be exactly 6 digits.
                                      </p>
                                    )}
                                </div>

                                <div
                                  className="bin_bind_submit_div"
                                  onClick={(e) => handleSubmit(e, index)}
                                >
                                  <span className="bin_bin_sub_span">
                                    Confirm
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApiBind;
//   return (
//     <div>
//       <section>
//         <Header />
//       </section>

//       <div className="api-bind-section">
//         <div className="container">
//           <div className="row prof_row_sm">
//             <div className="col-lg-2 dispaly_none_1200">
//               <Sidebar />
//             </div>
//             <div className="col-xl-10 col-lg-12 deposit_pad_top">
//               <div className="row api_main">
//                 <div className="col-xl-5 col-lg-8 col-md-10 col-sm-9 text-center">
//                   <span className="api_top_center">
//                     Bind Exchange for seamless crypto trading
//                   </span>
//                 </div>
//               </div>
//               <div className="api_second_main">
//                 <div className="apibind_top_work">
//                   {/* <div className="apibind_exchange_main"> */}
//                   <div className="row">
//                     <div className="col-6">
//                       <div className="api_exchan_head">
//                         <img
//                           src={require("../assests/icons/binance-icon.png")}
//                           alt="Binance"
//                           className="api_exchng_img"
//                         />
//                         <div className="api_exchng_namemain">
//                           <span className="api_exchng_namespan">Binance</span>
//                           <span className="api_exchng_namedefn">
//                             Bind Binance for diverse cryptocurrencies and
//                             trading options.
//                           </span>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-3 pad_0_apibind_in">
//                       <div className="api_inner_center">
//                         {isBounded ? (
//                           <>
//                             <i class="fa-regular fa-circle-check home_user_info_correct"></i>
//                             <span className="api_boundchange_span">Bounded</span>
//                           </>
//                         ) : (
//                           <>
//                             <i
//                               class="bi bi-exclamation-circle home_user_info"
//                               style={{ color: "#ee516d", marginBottom: "1px" }}
//                             ></i>
//                             <span className="api_unbound_span">Un Bounded</span>
//                           </>
//                         )}
//                       </div>
//                     </div>
//                     <div className="col-3 profile_box_divrow">
//                       <div className="h-100 d-flex align-items-center">
//                         {binanceBindstateref.current == true ? (
//                           <button
//                             className="prof_chang_box"
//                             type="button"
//                             onClick={() => setBinanceBindstate(false)}
//                           >
//                             <span className="prof_chang_box_span">Cancel</span>
//                           </button>
//                         ) : (
//                           <button
//                             className="prof_chang_box"
//                             type="button"
//                             onClick={() => setBinanceBindstate(true)}
//                           >
//                             <span className="prof_chang_box_span">Bind</span>
//                           </button>
//                         )}
//                       </div>
//                     </div>
//                   </div>
//                   {/* {binanceBindstateref.current == true ? ( */}
//                   <div
//                     className=""
//                     style={{
//                       maxHeight: binanceBindstate ? "780px" : "0px", // Adjust maxHeight
//                       overflow: "hidden", // Hide overflowing content when collapsed
//                       transition: "max-height 1s ease", // Smooth transition effect
//                     }}
//                   >
//                     {binanceBindstate ? (
//                       <div className="binance_bind_truemain">
//                         <div className="row">
//                           <div className="col-lg-6">
//                             {binanceContinuestateref.current == false ? (
//                               <div className="bina_bind_inimp">
//                                 <div className="bina_bind_inimp_top">
//                                   <span className="bina_bind_inimp_heading">
//                                     Bind Binance Exchange
//                                   </span>
//                                   <div className="bin_bind_innersmall">
//                                     <div className="bin_bind_innerinnn">
//                                       <span className="bin_bind_innerinnn_subhead">
//                                         1. Log in to Binance
//                                       </span>
//                                       <span className="bin_bind_innsubhead_sub">
//                                         {" "}
//                                         Go to <Link to="">
//                                           www.binance.com
//                                         </Link>{" "}
//                                         and log into your account.
//                                       </span>
//                                     </div>
//                                     <div className="bin_bind_innerinnn">
//                                       <span className="bin_bind_innerinnn_subhead">
//                                         2. Create API Key
//                                       </span>
//                                       <span className="bin_bind_innsubhead_sub">
//                                         {" "}
//                                         Navigate to "API Management," create a
//                                         new API key, and complete the
//                                         verification.
//                                       </span>
//                                     </div>
//                                     <div className="bin_bind_innerinnn">
//                                       <span className="bin_bind_innerinnn_subhead">
//                                         3. Copy API Key and Secret
//                                       </span>
//                                       <span className="bin_bind_innsubhead_sub">
//                                         {" "}
//                                         Copy the API Key and Secret after
//                                         creation; keep them secure.
//                                       </span>
//                                     </div>
//                                     <div className="bin_bind_innerinnn">
//                                       <span className="bin_bind_innerinnn_subhead">
//                                         4. Bind to WY Scale
//                                       </span>
//                                       <span className="bin_bind_innsubhead_sub">
//                                         Log into WY Scale, paste the API Key and
//                                         Secret in the "API Bind" section, and
//                                         save changes.
//                                       </span>
//                                     </div>
//                                   </div>
//                                 </div>
//                                 <div
//                                   className="bin_bind_submit_div"
//                                   onClick={() => setBinanceContinuestate(true)}
//                                 >
//                                   <span className="bin_bin_sub_span">
//                                     Continue
//                                   </span>
//                                 </div>
//                               </div>
//                             ) : (
//                               <div className="bina_bind_inimp">
//                                 <div className="bina_bind_inimp_top">
//                                   <div className="bina_bind_inimp_top_next">
//                                     <span className="bina_bind_inimp_heading">
//                                       Bind Binance Exchange
//                                     </span>
//                                     <div className="bin_bind_innersmall_next">
//                                       <span className="bind_bi_precaution">
//                                         Precautions
//                                       </span>
//                                       <span className="bind_bi_precaution_def">
//                                         Please enter the correct API, please do not enter special characters
//                                       </span>
//                                     </div>
//                                     <div className="bin_bind_innersmall_next w-100">
//                                       <span className="bind_bi_precaution">
//                                         IP group binding
//                                       </span>
//                                       <span className="bind_bi_precaution_def">
//                                         For security purpose, Binance Exchange recommends binding the server IP address when creating the API. For users
//                                       </span>
//                                       <div className="pos_rel w-100">
//                                         <input
//                                           className="bind_bin_next_inp"
//                                           placeholder="35.240.162.178"
//                                           readOnly
//                                         />
//                                         <span
//                                           className="bind_bin_next_inp_Copy"
//                                           onClick={() => handleCopy('35.240.162.178')}
//                                           style={{ cursor: 'pointer' }}
//                                         >
//                                           Copy
//                                         </span>
//                                       </div>
//                                     </div>
//                                   </div>
//                                   <div className="bin_bind_next_inbot">
//                                     <div className="bin_bin_apimain">
//                                       <span className="bin_bin_apimain_span">API Key</span>
//                                       <input
//                                         className="bind_bin_next_inp"
//                                         placeholder="Please enter API key"
//                                         type="text"
//                                         value={apiKey}
//                                         onChange={handleApiKeyChange}
//                                         onKeyDown={handleKeyDownApiSecret}
//                                       />
//                                       {apiKeyError && <div className="error">{apiKeyError}</div>}
//                                     </div>

//                                     <div className="bin_bin_apimain">
//                                       <span className="bin_bin_apimain_span">Secret Key</span>
//                                       <input
//                                         className="bind_bin_next_inp"
//                                         placeholder="Please enter Secret key"
//                                         type="text"
//                                         value={secretKey}
//                                         onChange={handleSecretKeyChange}
//                                         onKeyDown={handleKeyDownApiSecret}
//                                       />
//                                       {secretKeyError && <div className="error">{secretKeyError}</div>}

//                                     </div>
//                                     <div className="bin_bin_apimain">
//                                       <span className="bin_bin_apimain_span">Enter OTP</span>
//                                       <div className="pos_rel w-100">
//                                         <input
//                                           type="text"
//                                           maxLength="6"
//                                           name="emailOtp"
//                                           placeholder="Enter the verification code"
//                                           className="bind_bin_next_inp"
//                                           value={verificationCode}
//                                           onChange={handleOtpChange}
//                                           onKeyDown={handleKeyDown}     // Restrict non-numeric input
//                                         />
//                                         {otpSent ? (

//                                           <span className="bind_bin_next_inp_Copy">Resend ({timer}s)</span>
//                                         ) : (
//                                           <span
//                                             className="bind_bin_next_inp_Copy"
//                                             onClick={sendOtp}  // Send OTP
//                                             style={{ cursor: 'pointer' }}
//                                           >
//                                             {timer === 120 ? "Send OTP" : "Resend"}
//                                           </span>
//                                         )}
//                                       </div>
//                                       {otpError && verificationCode.length !== 6 && (
//                                         <p className="error">OTP should be exactly 6 digits.</p>
//                                       )}
//                                     </div>
//                                   </div>
//                                 </div>
//                                 <div
//                                   className="bin_bind_submit_div"
//                                   onClick={(e) => {
//                                     e.preventDefault(); // Prevent default form submission
//                                     setBinanceBindstate(true);
//                                     handleSubmit(e); // Pass the event to handleSubmit
//                                   }}
//                                 >
//                                   <span className="bin_bin_sub_span">Confirm</span>
//                                 </div>

//                               </div>

//                             )}
//                           </div>
//                         </div>
//                       </div>
//                     ) : null}
//                   </div>
//                   {/* ) : ("")} */}
//                 </div>

//                 <div className="apibind_top_work">
//                   {/* <div className="apibind_exchange_main"> */}
//                   <div className="row">
//                     <div className="col-6">
//                       <div className="api_exchan_head">
//                         <img
//                           src={require("../assests/icons/bybit-icon.png")}
//                           alt="Binance"
//                           className="api_exchng_img"
//                         />
//                         <div className="api_exchng_namemain">
//                           <span className="api_exchng_namespan">Bybit</span>
//                           <span className="api_exchng_namedefn">
//                             Connect with Bybit for a wide range of
//                             cryptocurrencies and trading features.
//                           </span>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-3 pad_0_apibind_in">
//                       <div className="api_inner_center">
//                         <i
//                           class="bi bi-exclamation-circle home_user_info"
//                           style={{ color: "#ee516d", marginBottom: "1px" }}
//                         ></i>
//                         <span className="api_unbound_span">Un Bounded</span>
//                       </div>
//                     </div>
//                     <div className="col-3 profile_box_divrow">
//                       <div className="h-100 d-flex align-items-center">
//                         <button className="prof_chang_box" type="button">
//                           <span className="prof_chang_box_span">Change</span>
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="apibind_top_work">
//                   {/* <div className="apibind_exchange_main"> */}
//                   <div className="row">
//                     <div className="col-6">
//                       <div className="api_exchan_head">
//                         <img
//                           src={require("../assests/icons/kucoin-icon.png")}
//                           alt="Binance"
//                           className="api_exchng_img"
//                         />
//                         <div className="api_exchng_namemain">
//                           <span className="api_exchng_namespan">Kucoin</span>
//                           <span className="api_exchng_namedefn">
//                             Link with KuCoin for a variety of cryptocurrencies
//                             and trading tools.
//                           </span>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-3 pad_0_apibind_in">
//                       <div className="api_inner_center">
//                         <i class="fa-regular fa-circle-check home_user_info_correct"></i>
//                         <span className="api_boundchange_span">Bounded</span>
//                       </div>
//                     </div>
//                     <div className="col-3 profile_box_divrow">
//                       <div className="h-100 d-flex align-items-center">
//                         <button className="prof_chang_box" type="button">
//                           <span className="prof_chang_box_span">Change</span>
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="apibind_top_work">
//                   {/* <div className="apibind_exchange_main"> */}
//                   <div className="row">
//                     <div className="col-6">
//                       <div className="api_exchan_head">
//                         <img
//                           src={require("../assests/icons/gateio-icon.png")}
//                           alt="Binance"
//                           className="api_exchng_img"
//                         />
//                         <div className="api_exchng_namemain">
//                           <span className="api_exchng_namespan">gate.io</span>
//                           <span className="api_exchng_namedefn">
//                             Connect with Gate.io for a diverse range of
//                             cryptocurrencies and trading.
//                           </span>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-3 pad_0_apibind_in">
//                       <div className="api_inner_center">
//                         <i
//                           class="bi bi-exclamation-circle home_user_info"
//                           style={{ color: "#ee516d", marginBottom: "1px" }}
//                         ></i>
//                         <span className="api_unbound_span">Un Bounded</span>
//                       </div>
//                     </div>
//                     <div className="col-3 profile_box_divrow">
//                       <div className="h-100 d-flex align-items-center">
//                         <button className="prof_chang_box" type="button">
//                           <span className="prof_chang_box_span">Change</span>
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <ToastContainer />
//     </div>
//   );
// }
