import React, { useEffect } from 'react';
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import authService from '../services/authservices';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function SecurityWithdraw() {
  const [otpSent, setOtpSent] = useState(false);
  const [timer, setTimer] = useState(120);
  const navigate = useNavigate();
  const [otpError, setOtpError] = useState('');
  const [resendLoader, setResendLoader] = useState(false);
  const [formData, setFormData] = useState({
    label: "",
    token: "",
    address: "",
    network: "",
    otp: "",
  });
  const [user, setUser] = useState({
    tfaStatus: 0,
    email: null,
    uid: null,
    referralCode: null,
    referralUrl: null,
    displayname: null,
    country: null,
    isAuthenticated: false,
    exchanges: []
  });
  const [loading, setLoading] = useState(true);
  const fetchUserDetails = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const data = await authService.getUserDetails(token);
        if (data && data.status) {
          const userDetails = {
            isAuthenticated: true,
            tfaStatus: data.tfastatus,
            email: data.email,
            displayname: data.displayname,
            country: data.country,
            uid: data.uid,
            referralCode: data.referralCode,
            referralUrl: data.referralUrl,
          };
          setUser(userDetails);
        } else {
          setUser({
            isAuthenticated: false,
            tfaStatus: 0,
            email: null,
            uid: null,
            referralCode: null,
            referralUrl: null,
          });
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
        setUser({
          isAuthenticated: false,
          tfaStatus: 0,
          email: null,
          uid: null,
          referralCode: null,
          referralUrl: null,
        });
      }
    } else {
      setUser({
        isAuthenticated: false,
        tfaStatus: 0,
        email: null,
        uid: null,
        referralCode: null,
        referralUrl: null,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);


  const sendOtp = async () => {


    if (!user.email) {
      toast.error('User email is not available. Please try again.');
      return;
    }
    if (!formData.address || !formData.label || !formData.network || !formData.token) {
      toast.error(
        <div className="toast-ml">
          <h4 className="toast-title">Error!</h4>
          <p className="toast-message">
            Please fill all fields before sending
            OTP.
          </p>
        </div>
      );
      return;
    }
    try {
      setResendLoader(true);
      const response = await authService.withdrawOtp({ email: user.email });
      setResendLoader(false);
      if (response.data.status) {
        console.log("enterrrrrrrrrrrrrrr")
        toast.success('OTP sent successfully!');
        setOtpSent(true);
        setTimer(120);
      } else {
        toast.error(response.data.Message || 'Failed to send OTP. Please try again.');
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };




  useEffect(() => {
    let countdown;
    if (otpSent && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setOtpSent(false);
      clearInterval(countdown);
    }
    return () => clearInterval(countdown);
  }, [timer, otpSent]);


  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value === "") {

      setOtpError("OTP is required");
    } else if (value.length !== 6) {
      setOtpError("OTP should be exactly 6 digits");
    } else {
      setOtpError("");
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleTokenChange = (selectedOption) => {
    setFormData({ ...formData, token: selectedOption.value });
  };

  const handleNetworkChange = (selectedOption) => {
    setFormData({ ...formData, network: selectedOption.value });
  };
  const handleSubmit = async () => {
    const payload = {
      label: formData.label,
      token: formData.token,
      withdrawAddress: formData.address,
      network: formData.network,
      otp: formData.otp,
    };

    try {

      const otpResponse = await authService.verifywithdrawOtp({ ...payload, withdrawOtp: Number(payload.otp) });
      console.log(payload.otp, "????????????????????????")
      if (!otpResponse.status) {
        toast.error(otpResponse.Message);
        return;
      }

      // toast.success(otpResponse.Message);
      const response = await authService.addWithdrawAddress(payload);

      if (response.status) {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/withdraw");
        }, 2000);

      } else {
        toast.error(response.Message);
      }
    } catch (error) {
      console.error("Error while adding withdraw address:", error);
      toast.error("An unexpected error occurred. Please try again.");
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === " " && !e.ctrlKey && !e.metaKey) {
      e.preventDefault();
    }
  };
  const handleKeyDownApiSecret = (e) => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  };

  const options = [
    { value: "USDC", label: "USDC" },
    { value: "USDT", label: "USDT" },
  ];

  const NetworkOptions = [
    { value: "TRC20", label: "TRC20" },
    { value: "BEP20", label: "BEP20" },
  ];
  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#f9f9f9",
      border: "1px solid #ccc",
      padding: "4px",
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      color: "#fff",
      backgroundColor: isFocused ? "#292b31" : "#181a20",
      cursor: "pointer",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#000",
    }),
  };


  return (
    <div>
      <section>
        <Header />
      </section>

      <div className="container">
        <div className="row prof_row_sm">
          <div className="col-lg-2 dispaly_none_1200">
            <Sidebar />
          </div>
          <div className="col-xl-10 col-lg-12 deposit_pad_top">
            <div className="security_with_topmain">
              <Link to="/security">
                <span className="Security_center_navinner">
                  Security center
                </span>
              </Link>
              <i class="fa-solid fa-chevron-right Securitycenter_i_navinner "></i>
              <span className=" Change_Password_secwith">
                Add withdraw address
              </span>
            </div>
            <div className="row mt-4">
              <div className="col-lg-6">
                <div className="secwith_main">
                  <div className="with_sec_topcon">
                    <span className="change-password">
                      Add withdraw address
                    </span>
                    <span className="with_sec_top_subhead">
                      {" "}
                      You cannot add a new wallet address for the next 24 hours.
                      This is to ensure the security of your account and
                      transactions.
                    </span>
                  </div>
                  <div className="with_securi_inbotmain">
                    <div className="with_securi_inbotmain_top">
                      <div className="reg_inner_small">
                        <span className="register_spans"> Address label</span>
                        <input
                          className="reg_input"
                          type="text"
                          name="label"
                          value={formData.label}
                          onChange={handleInputChange}
                          onKeyDown={handleKeyDownApiSecret}
                          placeholder=" 4-20 characters. e.g. Rahes’s address"
                        />
                      </div>
                      <div className="reg_inner_small">
                        <span className="register_spans">Token</span>
                        <Select
                          options={options}
                          styles={customStyles}
                          placeholder="Select Token"
                          onChange={handleTokenChange}
                          className="withdraw-dropdown-menu"
                        />
                      </div>
                      <div className="reg_inner_small">
                        <span className="register_spans">Address</span>
                        <input
                          className="reg_input"
                          type="text"
                          name="address"
                          value={formData.address}
                          onChange={handleInputChange}
                          onKeyDown={handleKeyDownApiSecret}
                          placeholder="Enter address here"
                        />
                      </div>
                      <div className="reg_inner_small">
                        <span className="register_spans">Network</span>
                        <Select
                          options={NetworkOptions}
                          styles={customStyles}
                          placeholder="Select Network"
                          onChange={handleNetworkChange}
                          className="withdraw-dropdown-menu"
                        />
                      </div>
                      <div className="reg_inner_small">
                        <span className="register_spans">Enter OTP</span>
                        <div className="pos_rel w-100">
                          <input
                            type="Number"
                            maxLength="6"
                            name="otp"
                            value={formData.otp}
                            onChange={handleInputChange}
                            placeholder="Enter the verification code"
                            className="bind_bin_next_inp"
                            onKeyDown={handleKeyDown}
                          />
                          {otpSent ? (
                            <span className="bind_bin_next_inp_Copy">Resend ({timer}s)</span>
                          ) : (
                            <span
                              className="bind_bin_next_inp_Copy"
                              onClick={() => sendOtp()}
                              style={{ cursor: 'pointer' }}
                            >
                              {resendLoader == false ? (
                                <>
                                  {timer === 120 ? "Send OTP" : "Resend"}
                                </>
                              ) : (
                                "Loading..."
                              )}

                            </span>
                          )}
                        </div>
                        {otpError &&
                          formData.otp
                            ?.length !== 6 && (
                            <p className="error">
                              OTP should be exactly 6 digits.
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="bin_bind_submit_div" onClick={handleSubmit}>
                      <span className="bin_bin_sub_span">Confirm</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-5">
                <div className="container m-top-20 p-0">
                  <div
                    class="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div className="faq">
                      <h5 className="faq-title">FAQ</h5>
                      <div class="accordion-item font-satoshi">
                        <h2 class="accordion-header" id="flush-headingOne">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            1. How do I make a deposit?
                            <span class="icon">
                              <i class="fas fa-chevron-down"></i>
                            </span>
                          </button>
                        </h2>
                        <div
                          id="flush-collapseOne"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            KYC verification is essential to comply with
                            regulations and ensure the security of our platform
                            by preventing fraud and other illegal activities.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item font-satoshi">
                        <h2 class="accordion-header" id="flush-headingTwo">
                          <button
                            class="accordion-button collapsed max-wrap"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwo"
                          >
                            2. Why have I still not received my deposit?
                            <span class="icon">
                              <i class="fas fa-chevron-down"></i>
                            </span>
                          </button>
                        </h2>
                        <div
                          id="flush-collapseTwo"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingTwo"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            You will need to submit a government-issued ID (like
                            a passport or driver's license) and a recent proof
                            of address (such as a utility bill or bank
                            statement).
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item font-satoshi">
                        <h2 class="accordion-header" id="flush-headingThree">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree"
                            aria-expanded="false"
                            aria-controls="flush-collapseThree"
                          >
                            3. How do I find my deposit address and tag/memos?
                            <span class="icon">
                              <i class="fas fa-chevron-down"></i>
                            </span>
                          </button>
                        </h2>
                        <div
                          id="flush-collapseThree"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingThree"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            The verification process typically takes [X
                            hours/days], depending on the volume of requests and
                            the clarity of the documents provided.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item font-satoshi">
                        <h2 class="accordion-header" id="flush-headingFour">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFour"
                            aria-expanded="false"
                            aria-controls="flush-collapseFour"
                          >
                            4. How to check the deposit progress?
                            <span class="icon">
                              <i class="fas fa-chevron-down"></i>
                            </span>
                          </button>
                        </h2>
                        <div
                          id="flush-collapseFour"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingFour"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            Yes, we use encryption and follow strict data
                            protection regulations to ensure that your personal
                            information remains secure.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item font-satoshi">
                        <h2 class="accordion-header" id="flush-headingFive">
                          <button
                            class="accordion-button collapsed max-wrap"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFive"
                            aria-expanded="false"
                            aria-controls="flush-collapseFive"
                          >
                            5. How to check the deposit progress?
                            <span class="icon">
                              <i class="fas fa-chevron-down"></i>
                            </span>
                          </button>
                        </h2>
                        <div
                          id="flush-collapseFive"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingFive"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            If your verification is rejected, you will receive
                            an email with the reasons and instructions on how to
                            resolve the issue.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default SecurityWithdraw;
