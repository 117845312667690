import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Select from "react-select";
import { getUserDetails } from "../services/authservices";
import authService from "../services/authservices";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { verifyTFA } from '../services/authservices'
import dayjs from "dayjs";
function Withdraw() {
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [timer, setTimer] = useState(120);
  const [emailOtp, setEmailOtp] = useState("");
  const [tfaCode, setTfaCode] = useState("");
  const [coinType, setCoinType, coinTyperef] = useState("USDC");


  const [addressOptions, setAddressOptions] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [label, setLabel] = useState("");
  const [currency, setCurrency] = useState("USDC");
  const [network, setNetwork] = useState("BEP20");
  const [transactions, setTransactions] = useState([]);
  const [addressData, setAddressData] = useState(null);
  const [resendLoader, setResendLoader] = useState(false);
  const [user, setUser] = useState({
    tfaStatus: 0,
    email: null,
    uid: null,
    referralCode: null,
    referralUrl: null,
    displayname: null,
    country: null,
    isAuthenticated: false,
  });
  const [loading, setLoading] = useState(true);
  const fetchUserDetails = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const data = await authService.getUserDetails(token);
        if (data && data.status) {
          const userDetails = {
            isAuthenticated: true,
            tfaStatus: data.tfastatus,
            email: data.email,
            displayname: data.displayname,
            country: data.country,
            uid: data.uid,
            referralCode: data.referralCode,
            referralUrl: data.referralUrl,
          };
          setUser(userDetails);
        } else {
          setUser({
            isAuthenticated: false,
            tfaStatus: 0,
            email: null,
            uid: null,
            referralCode: null,
            referralUrl: null,
          });
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
        setUser({
          isAuthenticated: false,
          tfaStatus: 0,
          email: null,
          uid: null,
          referralCode: null,
          referralUrl: null,
        });
      }
    } else {
      setUser({
        isAuthenticated: false,
        tfaStatus: 0,
        email: null,
        uid: null,
        referralCode: null,
        referralUrl: null,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);
  const handleCopy = (txID) => {
    // Prevent triggering multiple times rapidly
    if (handleCopy.timeout) clearTimeout(handleCopy.timeout);

    handleCopy.timeout = setTimeout(() => {
      navigator.clipboard
        .writeText(txID)
        .then(() => {
          toast.success(
            <div className="toast-ml">
              <h4 className="toast-title">Success!</h4>
              <p className="toast-message">
                Transaction ID copied to clipboard!
              </p>
            </div>
          );
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
          toast.error(
            <div className="toast-ml">
              <h4 className="toast-title">Error!</h4>
              <p className="toast-message">Failed to copy Transaction ID</p>
            </div>
          );
        });
    }, 300); // 300ms debounce time
  };
  const [withdrawDetails, setWithdrawDetails] = useState({
    minWithdraw: 0,
    maxWithdraw: 0,
    availableBalance: 0,
    fees: 0,
  });
  useEffect(() => {
    const fetchWithdrawDetails = async () => {
      const result = await authService.getWithdrawDetails(coinType);
      if (result) {
        setWithdrawDetails({
          minWithdraw: result.data.minWithdrawLimit,
          maxWithdraw: result.data.maxWithdrawLimit,
          availableBalance: result.data.balance,
          fees: result.data.withdrawFee,
        });
      } else {
        console.error("Failed to fetch withdrawal details:", result.message);
      }
    };

    fetchWithdrawDetails();
  }, [coinType]);

  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#f9f9f9",
      border: "1px solid #ccc",
      padding: "4px",
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      color: "#fff",
      backgroundColor: isFocused ? "#292b31" : "#181a20",
      cursor: "pointer",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#000",
    }),
  };

  useEffect(() => {
    const fetchAddresses = async () => {
      const result = await authService.getWithdrawAddresses(coinType);
      const filteredAddresses = result.data.filter(
        (address) => address.token === coinType && address.network === network
      );

      const formattedAddresses = filteredAddresses.map((address) => ({
        value: address.address,
        label: address.label || address.address,
        token: address.token,
        network: address.network,
      }));

      setAddressOptions(formattedAddresses);
    };


    fetchAddresses();
  }, [coinType, network]);
  const [withdrawalHistory, setWithdrawalHistory] = useState([]);

  useEffect(() => {
    const fetchWithdrawalHistory = async () => {
      const response = await authService.getWithdrawalHistory();
      if (response.status) {
        setWithdrawalHistory(response.data);
      } else {
        console.error('Failed to fetch withdrawal history:', response.Message);
      }
    };

    fetchWithdrawalHistory();
  }, []);


  const handlechange = () => {
    navigate('/security-tfa');
  };
  const handleSelectChange = (selectedOption) => {
    setSelectedAddress(selectedOption);
  };

  const customFilter = (option, inputValue) => {
    const sanitizedInput = inputValue.trim().toLowerCase();
    const label = option.label.toLowerCase();
    const address = option.value.toLowerCase();

    console.log("Searching for:", sanitizedInput);
    console.log("Checking label:", label, "Address:", address);
    const labelMatch = label.includes(sanitizedInput);
    const addressMatch = address.includes(sanitizedInput);

    console.log("Label match:", labelMatch, "Address match:", addressMatch);
    return labelMatch || addressMatch;
  };
  const handleKeyDown = (e) => {
    if (e.key === " " && !e.ctrlKey && !e.metaKey) {
      e.preventDefault();
    }
  };
  const handleKeyDown2 = (e) => {
    const allowedKeys = [
      "Backspace", // Allow deleting with Backspace
      "Delete",    // Allow deleting with Delete
      "ArrowLeft", // Allow moving left
      "ArrowRight", // Allow moving right
      "Tab"        // Allow tabbing
    ];

    // Allow numbers, one decimal point, and the allowed keys
    if (
      (!/[0-9.]/.test(e.key) && !allowedKeys.includes(e.key)) ||
      (e.key === "." && e.target.value.includes(".")) ||
      e.key === " " // Disallow spaces
    ) {
      e.preventDefault();
    }
  };



  const handleWithdrawSubmit = async () => {
    if (!amount || !selectedAddress || !emailOtp || !tfaCode) {
      toast.error("Please fill all required fields.");
      return;
    }
    const otpResponse = await authService.verifyOtp({ emailOtp: Number(emailOtp) });
    console.log(emailOtp, "????????????????????????")
    if (!otpResponse.status) {
      toast.error(otpResponse.Message);
      return;
    }

    // toast.success(otpResponse.Message);
    const token = localStorage.getItem('token');
    const tfaResponse = await verifyTFA(token, Number(tfaCode));
    console.log(tfaResponse.Message);
    if (tfaResponse.status == 200) {
      toast.success(tfaResponse.Message);
      console.log(tfaCode, "????????????????????????")
    }
    if (!tfaResponse.status) {
      toast.error(tfaResponse.Message);
      return;
    }


    const numericAmount = parseFloat(amount);
    if (numericAmount < withdrawDetails.minWithdraw) {
      toast.error(`Amount must be at least ${withdrawDetails.minWithdraw.toFixed(6)}.`);
      return;
    }
    if (numericAmount > withdrawDetails.maxWithdraw) {
      toast.error(`Amount must not exceed ${withdrawDetails.maxWithdraw.toFixed(6)}.`);
      return;
    }
    if (numericAmount > withdrawDetails.availableBalance) {
      toast.error("Insufficient balance.");
      return;
    }

    const requestData = {
      amount,
      address: selectedAddress.value,
      currency_symbol: coinType,
      emailOtp,
      tfaCode,
      network,
    };
    try {
      const response = await authService.withdrawSubmit(requestData);
      if (response.status) {
        toast.success(response.Message);
        setAmount("");
        setTfaCode("");
        setAddressData("");
        setNetwork("BEP20");
        setEmailOtp("");
      } else {
        toast.error(response.Message);
      }
    } catch (error) {
      toast.error("An error occurred while processing the request.");
    }
  };


  const sendOtp = async () => {


    if (!user.email) {
      toast.error('User email is not available. Please try again.');
      return;
    }

    try {
      const response = await authService.resendOtp({ email: user.email });
      if (response.data.status) {
        toast.success('OTP sent successfully!');
        setOtpSent(true);
        setTimer(120);
      } else {
        toast.error(response.data.Message || 'Failed to send OTP. Please try again.');
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };

  const handleNetworkChange = (selectedOption) => {
    setNetwork(selectedOption.value);
  };

  const NetworkOptions = [
    { value: "TRC20", label: "TRC20" },
    { value: "BEP20", label: "BEP20" },
  ];
  useEffect(() => {
    let countdown;
    if (otpSent && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setOtpSent(false);
      clearInterval(countdown);
    }
    return () => clearInterval(countdown);
  }, [timer, otpSent]);



  const handleInputChange = (inputValue) => {
    setLabel(inputValue);
  };

  useEffect(() => {
    setSelectedAddress(null);
  }, [coinType]);

  return (
    <div>
      <section>
        <Header />
      </section>
      <div className="withdraw-section">
        <div className="container">
          <div className="row prof_row_sm">
            <div className="col-lg-2 dispaly_none_1200">
              <Sidebar />
            </div>
            <div className="col-xl-10 col-lg-12 deposit_pad_top">
              <span className="profile_span">Withdraw</span>
              <div className="row mt-4">
                <div className="col-lg-7">
                  <div className="deposit_left_main">
                    <div className="depo_inner_divmain">
                      <span className="home-viewasset-span">Select crypto</span>
                      <div className="depo_nav_design">
                        <div
                          className={coinType === "USDC" ? "depo_nav_active" : "depo_nav_notactive"}
                          onClick={() => setCoinType("USDC")}
                        >
                          <img src={require("../assests/usdc-logo.png")} alt="USDC" className="logout-img" />
                          <span>USDC</span>
                        </div>
                        <div
                          className={coinType === "USDT" ? "depo_nav_active" : "depo_nav_notactive"}
                          onClick={() => setCoinType("USDT")}
                        >
                          <img
                            src={require("../assests/usdt-logo.png")}
                            alt="USDT"
                            className="logout-img"
                          />
                          <span>USDT</span>
                        </div>
                      </div>
                    </div>
                    <div className="depo_inner_divmain">
                      <span className="register_spans">Network</span>
                      <Select
                        options={NetworkOptions}
                        styles={customStyles}
                        placeholder="Select Network"
                        onChange={handleNetworkChange}
                        className="withdraw-dropdown-menu"
                      />
                    </div>
                    <div className="depo_inner_divmain">
                      <div className="manage_btn_row">
                        <span className="home-viewasset-span">
                          Wallet Address
                        </span>
                        <Link to="/security-withdraw">
                          <div className="d-flex align-items-center gap-2 cursor-pointer">
                            <span className="home_starty_view">
                              {" "}
                              Add Address
                            </span>
                            <i class="fa-solid fa-chevron-right home_starty_viewarrow"></i>
                          </div>
                        </Link>
                      </div>
                      <Select
                        options={addressOptions}
                        styles={customStyles}
                        placeholder="Select or type the address"
                        className="withdraw-dropdown-menu"
                        onChange={handleSelectChange}
                        value={selectedAddress}
                        isSearchable={true}
                        getOptionLabel={(e) => `${e.value}`}
                        filterOption={customFilter}
                        onInputChange={handleInputChange}
                      />

                    </div>
                    <div className="depo_in_notesdiv">
                      <div className="depoin_notes_icon">
                        <i class="bi bi-exclamation-triangle depo_note_ico"></i>
                      </div>
                      <div className="depo_notes_defspan">
                        Make sure the address you provided is correct.
                        Transactions cannot be reversed once completed.
                      </div>
                    </div>
                    <div className="withdraw_fes_main">
                      <div className="depo_inner_divmain">
                        <span className="home-viewasset-span">
                          Withdraw Amount
                        </span>
                        <div className="pos_rel">
                          <input
                            className="withdraw_amnt"
                            placeholder="Enter the amount"
                            type="text"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)} // Bind amount input
                            onKeyDown={handleKeyDown2}
                          />
                          <span className="withdraw_amnt_max">max</span>
                        </div>
                      </div>
                      <div className="depo_inner_divmain">
                        <span className="home-viewasset-span">Enter OTP</span>
                        <div className="pos_rel w-100">

                          <input
                            type="number"
                            maxLength="6"
                            name="emailOtp"
                            value={emailOtp}
                            onChange={(e) => setEmailOtp(e.target.value)}
                            placeholder="Enter the verification code"
                            className="bind_bin_next_inp"
                            onKeyDown={handleKeyDown}
                          />
                          {otpSent ? (
                            <span className="bind_bin_next_inp_Copy">Resend ({timer}s)</span>
                          ) : (
                            <span
                              className="bind_bin_next_inp_Copy"
                              onClick={() => sendOtp()}
                              style={{ cursor: 'pointer' }}
                            >
                              {resendLoader == false ? (
                                <>
                                  {timer === 120 ? "Send OTP" : "Resend"}
                                </>
                              ) : (
                                "Loading..."
                              )}

                            </span>
                          )}

                        </div>
                      </div>
                      <div className="depo_inner_divmain">
                        <span className="home-viewasset-span">Enter 2FA</span>
                        <div className="pos_rel w-100">
                          <input
                            type="number"
                            maxLength="6"
                            name="tfaCode"
                            value={tfaCode}
                            onChange={(e) => setTfaCode(e.target.value)}
                            placeholder="Enter the 2FA code"
                            className="bind_bin_next_inp"
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                      </div>
                      <div className="with_draw_feeses">
                        <div className="with_fees_spacebet">
                          <span className="top_startgy_inbottomadvance">Fees</span>
                          <span className="home-viewasset-span">{withdrawDetails.fees}%</span>
                        </div>
                        <div className="with_fees_spacebet">
                          <span className="top_startgy_inbottomadvance">Available balance</span>
                          <span className="home-viewasset-span">{withdrawDetails.availableBalance}</span>
                        </div>
                        <div className="with_fees_spacebet">
                          <span className="top_startgy_inbottomadvance">Minimum withdraw</span>
                          <span className="home-viewasset-span">{withdrawDetails.minWithdraw}</span>
                        </div>
                        <div className="with_fees_spacebet">
                          <span className="top_startgy_inbottomadvance">Maximum withdraw</span>
                          <span className="home-viewasset-span">{withdrawDetails.maxWithdraw}</span>
                        </div>

                      </div>
                    </div>
                    {user.tfaStatus == 0 ? (
                      <div className="withdraw_submit_div" onClick={handlechange}>
                        <span>Enable Tfa</span>
                      </div>)
                      :
                      (<div className="withdraw_submit_div" onClick={handleWithdrawSubmit}>
                        <span>Submit</span>
                      </div>
                      )}
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="container m-top-20 p-0">
                    <div
                      class="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div className="faq">
                        <h5 className="faq-title">FAQ</h5>
                        <div class="accordion-item font-satoshi">
                          <h2 class="accordion-header" id="flush-headingOne">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseOne"
                              aria-expanded="false"
                              aria-controls="flush-collapseOne"
                            >
                              1. How do I make a withdrawal?
                              <span class="icon">
                                <i class="fas fa-chevron-down"></i>
                              </span>
                            </button>
                          </h2>
                          <div
                            id="flush-collapseOne"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingOne"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div class="accordion-body">
                              KYC verification is essential to comply with
                              regulations and ensure the security of our
                              platform by preventing fraud and other illegal
                              activities.
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item font-satoshi">
                          <h2 class="accordion-header" id="flush-headingTwo">
                            <button
                              class="accordion-button collapsed max-wrap"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseTwo"
                              aria-expanded="false"
                              aria-controls="flush-collapseTwo"
                            >
                              2. Why have I still not received my withdrawal?
                              <span class="icon">
                                <i class="fas fa-chevron-down"></i>
                              </span>
                            </button>
                          </h2>
                          <div
                            id="flush-collapseTwo"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingTwo"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div class="accordion-body">
                              You will need to submit a government-issued ID
                              (like a passport or driver's license) and a recent
                              proof of address (such as a utility bill or bank
                              statement).
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item font-satoshi">
                          <h2 class="accordion-header" id="flush-headingThree">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseThree"
                              aria-expanded="false"
                              aria-controls="flush-collapseThree"
                            >
                              3. How do I select the right network?
                              <span class="icon">
                                <i class="fas fa-chevron-down"></i>
                              </span>
                            </button>
                          </h2>
                          <div
                            id="flush-collapseThree"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingThree"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div class="accordion-body">
                              The verification process typically takes [X
                              hours/days], depending on the volume of requests
                              and the clarity of the documents provided.
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item font-satoshi">
                          <h2 class="accordion-header" id="flush-headingFour">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseFour"
                              aria-expanded="false"
                              aria-controls="flush-collapseFour"
                            >
                              4. How to check the deposit progress?
                              <span class="icon">
                                <i class="fas fa-chevron-down"></i>
                              </span>
                            </button>
                          </h2>
                          <div
                            id="flush-collapseFour"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingFour"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div class="accordion-body">
                              Yes, we use encryption and follow strict data
                              protection regulations to ensure that your
                              personal information remains secure.
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item font-satoshi">
                          <h2 class="accordion-header" id="flush-headingFive">
                            <button
                              class="accordion-button collapsed max-wrap"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseFive"
                              aria-expanded="false"
                              aria-controls="flush-collapseFive"
                            >
                              5. Do I need to pay fees for deposit and
                              withdrawal?
                              <span class="icon">
                                <i class="fas fa-chevron-down"></i>
                              </span>
                            </button>
                          </h2>
                          <div
                            id="flush-collapseFive"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingFive"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div class="accordion-body">
                              If your verification is rejected, you will receive
                              an email with the reasons and instructions on how
                              to resolve the issue.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="recent_depo_main">
                <div className="manage_btn_row">
                  <span className="home_right_marketspan">Recent Withdraw</span>
                  <Link to="/withdrawhistory">
                    <div className="d-flex align-items-center gap-2 cursor-pointer">
                      <span className="home_starty_view">View All</span>
                      <i class="fa-solid fa-chevron-right home_starty_viewarrow"></i>
                    </div>
                  </Link>
                </div>
                <div className="recent_depo_tablemain pb-0">
                  <div className="table-responsive table-borderless home_recent_table">
                    {loading ? (
                      <p className="depo_no_record">Loading...</p>
                    ) : (
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text_left">Currency</th>
                            <th>Date & Time</th>
                            <th>Network</th>
                            <th>Amount</th>
                            <th>Transaction ID</th>
                          </tr>
                        </thead>
                        <tbody>
                          {withdrawalHistory.length > 0 ? (
                            withdrawalHistory.slice(0, 5).map((withdrawal, index) => (
                              <tr key={index}>
                                <td>
                                  <div className="home_recent_firstdata">
                                    <img
                                      src={require("../assests/usdt-logo.png")}
                                      alt="USDT"
                                      className="usdt_home_recent"
                                    />
                                    <span>{withdrawal.currency}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <span>
                                    {isNaN(new Date(withdrawal.date))
                                      ? "null"
                                      : dayjs(withdrawal.date).format(
                                        "DD MMMM YYYY, hh:mm A"
                                      )}
                                  </span>
                                </td>
                                <td>
                                  <span>{withdrawal.network}</span>
                                </td>
                                <td>
                                  <span>{withdrawal.amount}</span>
                                </td>
                                <td>
                                  <div className="home_uid_main">
                                    <span>{withdrawal.transactionId}</span>
                                    <i
                                      className="ri-file-copy-line cursor-pointer uid_copy_recent"
                                      onClick={() =>
                                        handleCopy(withdrawal.transactionId)
                                      }
                                    ></i>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="5">
                                <span className="depo_no_record">
                                  No transactions found
                                </span>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Withdraw;
