import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import authService from "../services/authservices";
import { UserContext } from "../contexts/UserContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EmailVerify() {
  const [verificationCode, setVerificationCode] = useState("");
  const [otpSent, setOtpSent] = useState(true);
  const [timer, setTimer] = useState(120); // Start with 120 seconds
  const [signloader, setSignloader] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const sendOtp = async () => {
    try {
      setOtpSent(true);
      setTimer(120); // Start the timer at 120 seconds
      const response = await authService.resendOtp({ email: user.email });
      if (response.data.status) {
        toast.success(
          <div className="toast-ml">
            <h4 className="toast-title">Success!</h4>
            <p className="toast-message">OTP resent successfully!</p>
          </div>
        );
      } else {
        toast.error(
          // response.data.Message || "Failed to resend OTP. Please try again."

          <div className="toast-ml">
            <h4 className="toast-title">Error!</h4>
            <p className="toast-message">
              {response.data.Message ||
                "Failed to resend OTP. Please try again."}
            </p>
          </div>
        );
        setOtpSent(false);
      }
    } catch (error) {
      console.error("Error during OTP resend:", error);
      toast.error(
        <div className="toast-ml">
          <h4 className="toast-title">Error!</h4>
          <p className="toast-message">An error occurred. Please try again.</p>
        </div>
      );
      setOtpSent(false);
    }
  };

  useEffect(() => {
    let countdown;
    if (timer > 0 && otpSent) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setOtpSent(false);
      clearInterval(countdown);
    }
    return () => clearInterval(countdown);
  }, [timer, otpSent]);

  const handleInputChange = (e) => {
    setVerificationCode(e.target.value);
    if (error) {
      setError("");
    }
  };
  const handleKeyDown = (e) => {
    if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
      e.preventDefault();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!verificationCode) {
      setError("OTP is required");
      return;
    }
    try {
      setSignloader(true);
      const response = await authService.verifyOtp({
        email: user.email,
        emailOtp: Number(verificationCode),
      });
      setSignloader(false);
      if (response.status) {
        toast.success(
          <div className="toast-ml">
            <h4 className="toast-title">Success!</h4>
            <p className="toast-message">OTP verified successfully!</p>
          </div>
        );
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        setError(response.Message);
      }
    } catch (error) {
      setSignloader(false);
      console.error("Error during OTP verification:", error);
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <div>
      <div className="row register_main container-fluid">
        <div className="col-lg-6">
          <div className="d-flex flex-column mb-5">
            <div className="register_head_logo">
              <Link to="/">
                <img
                  src={require("../assests/Logo.png")}
                  alt="Logo"
                  className="register_logo cursor-pointer"
                />
              </Link>
            </div>
            <div className="register_left_main mt-4">
              <div className="hero_center">
                <div className="register_form">
                  <span className="register_head_sign">
                    {" "}
                    Email Verification{" "}
                  </span>
                  <div className="register_inner_main">
                    <div className="getting_card_content w-100">
                      <div className="register_inner_submain w-100">
                        <div className="reg_inner_small">
                          <span className="register_spans">Email Code</span>
                          <div className="reg_pos_rel">
                            <input
                              type="number"
                              name="emailOtp"
                              placeholder="Enter the verification code"
                              className="reg_input email_padding_resp"
                              value={verificationCode}
                              onChange={handleInputChange}
                              onKeyDown={handleKeyDown}
                            />
                            {otpSent ? (
                              <span className="pos_abs_resend">
                                Resend ({timer}s)
                              </span>
                            ) : (
                              <span
                                className="pos_abs_resend"
                                onClick={sendOtp}
                                style={{ cursor: "pointer" }}
                              >
                                Resend
                              </span>
                            )}
                          </div>

                          {error && <span className="error">{error}</span>}
                        </div>
                      </div>
                    </div>
                    <div className="getting_card_content">
                      {signloader === true ? (
                        <div className="reg_btn">
                          <span className="hero_invest"> Loading... </span>
                        </div>
                      ) : (
                        <div className="reg_btn" onClick={handleSubmit}>
                          <span className="hero_invest"> Verify </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <span className="reg_bottom_span">
                  <Link to="/register">
                    {" "}
                    <span className="reg_check_span">
                      Back To Sign Up.
                    </span>{" "}
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 emailverify_right_main">
          <div className="login_right">
            <div className="footer_links_div">
              <span className="register_head"> Verify Your Email</span>
              <span className="register_subhead">
                We have sent you an verification code to the email <br />{" "}
                address you have entered
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailVerify;
