import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { getQrcode, changeTFA } from "../services/authservices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import authService from "../services/authservices";
function SecurityTfa() {
  const [user, setUser] = useState({
    tfaStatus: 0,
    email: null,
    uid: null,
    referralCode: null,
    referralUrl: null,
    displayname: null,
    country: null,
    isAuthenticated: false,
  });

  const fetchUserDetails = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const data = await authService.getUserDetails(token);
        if (data && data.status) {
          const userDetails = {
            isAuthenticated: true,
            tfaStatus: data.tfastatus,
            email: data.email,
            displayname: data.displayname,
            country: data.country,
            uid: data.uid,
            referralCode: data.referralCode,
            referralUrl: data.referralUrl,
          };
          setUser(userDetails);
        } else {
          setUser({
            isAuthenticated: false,
            tfaStatus: 0,
            email: null,
            uid: null,
            referralCode: null,
            referralUrl: null,
          });
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
        setUser({
          isAuthenticated: false,
          tfaStatus: 0,
          email: null,
          uid: null,
          referralCode: null,
          referralUrl: null,
        });
      }
    } else {
      setUser({
        isAuthenticated: false,
        tfaStatus: 0,
        email: null,
        uid: null,
        referralCode: null,
        referralUrl: null,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);
  const tfaStatus = user.tfaStatus;
  const [qrCode, setQrCode] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    console.log("tfaStatus-->", tfaStatus)
    if (!token) {
      navigate("/login");
      return;
    }

    if (tfaStatus === 0) {
      const fetchQrCode = async () => {
        try {
          const data = await getQrcode(token);
          console.log("QR Code Data:", data);
          if (data.status) {
            setQrCode(data.data.tfa_url);
            setSecretKey(data.data.tfaenablekey);
          } else {
            console.error("Failed to fetch QR code:", data.Message);
          }
        } catch (error) {
          console.error("Error fetching QR code:", error);
        }
      };
      fetchQrCode();
    }
  }, [tfaStatus, navigate]);

  const handleToggle2FA = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const data = await changeTFA(token, code);

      if (data.status) {
        const newStatus = tfaStatus === 1 ? 0 : 1;
        setUser((prev) => ({ ...prev, tfaStatus: newStatus }));
        toast.success("TFA Status Changed Successfully")
        setTimeout(() => {
          navigate("/security", { state: { tfaStatus: newStatus } });
        }, 2000);

      } else {
        toast.error(
          <div className="toast-ml">
            <h4 className="toast-title">Error!</h4>
            <p className="toast-message">{data.Message}</p>
          </div>
        );
      }
    } catch (error) {
      console.error("Error changing TFA status:", error);
      toast.error(
        <div className="toast-ml">
          <h4 className="toast-title">Error!</h4>
          <p className="toast-message">
            Error changing TFA status. Please try again.
          </p>
        </div>
      );
    } finally {
      setLoading(false);
    }
  };
  const copyToClipboard = (text) => {
    if (copyToClipboard.timeout) clearTimeout(copyToClipboard.timeout);

    copyToClipboard.timeout = setTimeout(() => {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          toast.success(
            <div className="toast-ml">
              <h4 className="toast-title">Success!</h4>
              <p className="toast-message">Copied to authenticated code!</p>
            </div>
          );
        })
        .catch((err) => {
          console.error("Failed to copy text:", err);
          toast.error(
            <div className="toast-ml">
              <h4 className="toast-title">Error!</h4>
              <p className="toast-message">Failed to copy text</p>
            </div>
          );
        });
    }, 300); // 300ms debounce time
  };

  return (
    <div>
      <section>
        <Header />
      </section>

      <div className="container">
        <div className="row prof_row_sm">
          <div className="col-lg-2 dispaly_none_1200">
            <Sidebar />
          </div>
          <div className="col-xl-10 col-lg-12 deposit_pad_top">
            <div className="security_with_topmain">
              <Link to="/security">
                <span className="Security_center_navinner">
                  Security center
                </span>
              </Link>
              <i class="fa-solid fa-chevron-right Securitycenter_i_navinner "></i>
              <span className="Change_Password_secwith">
                Link authenticator app
              </span>
            </div>
            <div className="row mt-4">
              <div className="col-lg-8 col-xl-6 col-md-8">
                <div className="secwith_main">
                  <span className="change-password">
                    Link authenticator app
                  </span>
                  {tfaStatus === 0 && (
                    <div className="tfa_securi_inbotmain">
                      <div className="tfa_qr_upper">
                        {qrCode ? (
                          <img
                            src={qrCode}
                            alt="QR code"
                            className="QR-sectfa-img"
                          />
                        ) : (
                          <p>Loading QR code...</p>
                        )}
                      </div>
                      <div className="reg_pos_rel w-100">
                        <input
                          className="secu_tfa_input"
                          type="text"
                          value={secretKey}
                          disabled
                          readOnly
                        />
                        <i
                          className="ri-file-copy-line cursor-pointer uid_copy_tfasec"
                          onClick={() => copyToClipboard(secretKey)}
                        ></i>
                      </div>
                    </div>
                  )}
                  <div className="tfa_top_imp">
                    <span className="tfa_sacnqr_subspan">2FA code</span>
                    <input
                      className="reg_input"
                      type="text"
                      placeholder="Enter your 2FA code here"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </div>
                  <div
                    className="bin_bind_submit_div"
                    onClick={handleToggle2FA}
                  >
                    <span>
                      {loading
                        ? "Loading..."
                        : tfaStatus === 1
                          ? "Disable"
                          : "Enable"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecurityTfa;
