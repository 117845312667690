import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

const PrivateRoute = ({ children }) => {
    const { user, setUser } = useContext(UserContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const verifyAuth = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                // Assuming some verification or fetch to check token validity
                try {
                    // e.g., verify token or fetch user details
                    const response = await fetch('/api/verifyToken', {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    if (response.ok) {
                        setUser({ isAuthenticated: true });
                    } else {
                        setUser({ isAuthenticated: false });
                    }
                } catch (error) {
                    console.error("Token verification failed", error);
                    setUser({ isAuthenticated: false });
                }
            } else {
                setUser({ isAuthenticated: false });
            }
            setLoading(false);
        };

        verifyAuth();
    }, [setUser]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return user.isAuthenticated ? children : <Navigate to="/login" replace />;
};

export default PrivateRoute;
