import React from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Select from "react-select";
import { Link } from "react-router-dom";

function Policy() {
  const [showType, setShowType, showTyperef] = useState("Privacy");

  return (
    <div>
      <section>
        <Header />
      </section>
      <div className="">
        <div className="container">
          <div className="row prof_row_sm">
            <div className="col-lg-2 dispaly_none_1200">
              <Sidebar />
            </div>

            <div className="col-xl-10 deposit_pad_top">
              <div className="row">
                <div className="col-lg-9">
                  {showTyperef.current == "Privacy" ? (
                    <div className="policy_main">
                      <div className="policy_top_main">
                        <span className="policy_head">Privacy Policies</span>
                        <div className="policy_top_submain">
                          <div className="policy_state_active">
                            <span className="">Privacy Policies</span>
                          </div>
                          <div
                            className="policy_state_deactive"
                            onClick={() => setShowType("Terms")}
                          >
                            <span className="">Terms & Conditions</span>
                          </div>
                        </div>
                      </div>
                      <div className="policy_second_main">
                        <div className="policy_top_main">
                          <span className="policy_esti_date">
                            Effective Date: 07 October 2024
                          </span>
                          <span className="Under_estidate_cont">
                            At <b>WY Scale</b>, your privacy and security are
                            our top priorities. This Privacy Policy explains how
                            we collect, use, and protect your personal
                            information when you use our website and services.
                            By accessing or using our platform, you agree to the
                            terms of this policy.
                          </span>
                        </div>
                        <div className="policy_second_main">
                          <div className="policy_second_innimp">
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                                1. Information We Collect
                              </span>
                              <span className="polisy_inn_content_span">
                                We collect the following types of information to
                                provide and improve our services:
                              </span>
                            </div>
                            <div className="policy_top_main">
                              <span className="policy_esti_date">
                                1.1 Personal Information
                              </span>
                              <div>
                                <ul className="priv_inn_inn_cont">
                                  <li>
                                    <span className="polisy_inn_content_span">
                                      Account Information: When you sign up, we
                                      collect your name, email address, and
                                      other necessary account details.
                                    </span>
                                  </li>
                                  <li>
                                    <span className="polisy_inn_content_span">
                                      KYC (Know Your Customer): If required by
                                      law, we may request additional
                                      identification documents to verify your
                                      identity.
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="policy_top_main">
                              <span className="policy_esti_date">
                                1.2 Transaction and Financial Information
                              </span>
                              <div>
                                <ul className="priv_inn_inn_cont">
                                  <li>
                                    <span className="polisy_inn_content_span">
                                      Deposit and Withdrawal Records: We collect
                                      details of your deposits, withdrawals, and
                                      trading activity on the platform.
                                    </span>
                                  </li>
                                  <li>
                                    <span className="polisy_inn_content_span">
                                      Exchange Integration: If you bind your
                                      account to any top cryptocurrency
                                      exchange, we may collect API keys or other
                                      necessary credentials to allow seamless
                                      bot trading.
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="policy_top_main">
                              <span className="policy_esti_date">
                                1.3 Usage Information
                              </span>
                              <div>
                                <ul className="priv_inn_inn_cont">
                                  <li>
                                    <span className="polisy_inn_content_span">
                                      Bot Usage Data: We collect data on how you
                                      activate, deactivate, or adjust the
                                      settings of the trading bot, as well as
                                      the strategies you follow.
                                    </span>
                                  </li>
                                  <li>
                                    <span className="polisy_inn_content_span">
                                      Technical Data: This includes your IP
                                      address, browser type, operating system,
                                      and other analytics to improve your user
                                      experience.
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="policy_second_innimp">
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                                2. How We Use Your Information
                              </span>
                              <span className="polisy_inn_content_span">
                                We use your information for the following
                                purposes:
                              </span>
                            </div>

                            <div>
                              <ul className="priv_inn_inn_cont">
                                <li>
                                  <span className="polisy_inn_content_span">
                                    To Provide Services: Your information is
                                    essential for activating and deactivating
                                    bots, integrating with exchanges, and
                                    executing your chosen strategies.
                                  </span>
                                </li>
                                <li>
                                  <span className="polisy_inn_content_span">
                                    To Personalize User Experience: We may use
                                    your usage data to offer customized
                                    recommendations and trading strategies.
                                  </span>
                                </li>
                                <li>
                                  <span className="polisy_inn_content_span">
                                    To Improve Security: Personal and
                                    transactional data help us ensure the
                                    security of your account and prevent
                                    fraudulent activities.{" "}
                                  </span>
                                </li>
                                <li>
                                  <span className="polisy_inn_content_span">
                                    To Communicate with You: We use your contact
                                    details to send updates, provide customer
                                    support, and share notifications about
                                    changes to your account or our services.{" "}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className="policy_second_innimp">
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                                3. Information Sharing and Disclosure
                              </span>
                              <span className="polisy_inn_content_span">
                                We do not sell or rent your personal information
                                to third parties. However, we may share your
                                data in the following circumstances:
                              </span>
                            </div>

                            <div>
                              <ul className="priv_inn_inn_cont">
                                <li>
                                  <span className="polisy_inn_content_span">
                                    With Third-Party Service Providers: To
                                    perform essential services like exchange
                                    integration, security measures, or KYC
                                    verification.{" "}
                                  </span>
                                </li>
                                <li>
                                  <span className="polisy_inn_content_span">
                                    For Legal Compliance: We may share
                                    information if required to comply with legal
                                    obligations, government requests, or court
                                    orders.{" "}
                                  </span>
                                </li>
                                <li>
                                  <span className="polisy_inn_content_span">
                                    Business Transfers: In case of mergers or
                                    acquisitions, your data may be transferred
                                    to the new entity as part of the transition.{" "}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className="policy_second_innimp">
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                                4. Data Security
                              </span>
                              <span className="polisy_inn_content_span">
                                We implement robust security measures to protect
                                your data, including encryption, secure storage,
                                and restricted access to sensitive information.
                                While we strive to protect your information, no
                                method of transmission or storage is completely
                                secure. We encourage you to use strong passwords
                                and maintain account security.
                              </span>
                            </div>
                          </div>

                          <div className="policy_second_innimp">
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                                5. Your Privacy Rights
                              </span>
                              <span className="polisy_inn_content_span">
                                You have the following rights concerning your
                                data:
                              </span>
                            </div>

                            <div>
                              <ul className="priv_inn_inn_cont">
                                <li>
                                  <span className="polisy_inn_content_span">
                                    Access and Correction: You can access,
                                    update, or correct your personal information
                                    at any time through your account settings.
                                  </span>
                                </li>
                                <li>
                                  <span className="polisy_inn_content_span">
                                    Deletion: You may request the deletion of
                                    your personal information, but please note
                                    this may limit your ability to use certain
                                    services.
                                  </span>
                                </li>
                                <li>
                                  <span className="polisy_inn_content_span">
                                    Opt-out of Marketing Communications: You can
                                    unsubscribe from promotional emails at any
                                    time by following the instructions in those
                                    messages.
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className="policy_second_innimp">
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                                6. Data Retention
                              </span>
                              <span className="polisy_inn_content_span">
                                We retain your personal and financial
                                information for as long as your account remains
                                active or as needed to comply with legal
                                obligations, resolve disputes, and enforce our
                                agreements.{" "}
                              </span>
                            </div>
                          </div>

                          <div className="policy_second_innimp">
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                                7. Changes to this Privacy Policy
                              </span>
                              <span className="polisy_inn_content_span">
                                We may update this Privacy Policy from time to
                                time to reflect changes in our practices or
                                legal requirements. We will notify you of any
                                significant updates via email or through
                                notifications on our platform.{" "}
                              </span>
                            </div>
                          </div>

                          <div className="policy_second_innimp">
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                                8. Contact Us
                              </span>
                              <span className="polisy_inn_content_span">
                                If you have any questions or concerns about this
                                Privacy Policy or our practices, please contact
                                us at xyzxyz661@gmail.com.{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="policy_main">
                      <div className="policy_top_main">
                        <span className="policy_head">Terms & Conditions</span>
                        <div className="policy_top_submain">
                          <div
                            className="policy_state_deactive"
                            onClick={() => setShowType("Privacy")}
                          >
                            <span className="">Privacy Policies</span>
                          </div>
                          <div className="policy_state_active">
                            <span className="">Terms & Conditions</span>
                          </div>
                        </div>
                      </div>
                      <div className="policy_second_main">
                        <div className="policy_top_main">
                          <span className="policy_esti_date">
                            Effective Date: 07 October 2024
                          </span>
                          <span className="Under_estidate_cont">
                            Welcome to <b>WY Scale</b>. By accessing or using
                            our website and services, you agree to be bound by
                            these Terms & Conditions ("Terms"). Please read them
                            carefully before using the platform. If you do not
                            agree with any part of these Terms, please do not
                            use our services.
                          </span>
                        </div>
                        <div className="policy_second_main">
                          <div className="policy_second_innimp">
                            <span className="policy_inner_head_span">
                              1. General Terms
                            </span>
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                                1.1 Eligibility
                              </span>
                              <span className="polisy_inn_content_span">
                                You must be at least 18 years old to use the WY
                                Scale platform. By using our services, you
                                confirm that you meet this age requirement and
                                have full legal capacity to enter into a binding
                                contract.
                              </span>
                            </div>
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                                1.2 Account Registration
                              </span>
                              <span className="polisy_inn_content_span">
                                To use the trading bot, you must create an
                                account. You agree to provide accurate and
                                up-to-date information during registration and
                                to maintain the security of your account
                                credentials. You are responsible for all
                                activities that occur under your account.
                              </span>
                            </div>
                          </div>

                          <div className="policy_second_innimp">
                            <span className="policy_inner_head_span">
                              2. Use of the Trading Bot
                            </span>
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                                2.1 Activation and Deactivation
                              </span>
                              <span className="polisy_inn_content_span">
                                You may activate or deactivate the trading bot
                                at any time through your account dashboard. Once
                                activated, the bot will execute trades based on
                                the strategy and settings you have selected{" "}
                              </span>
                            </div>
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                                2.2 Strategy Selection
                              </span>
                              <span className="polisy_inn_content_span">
                                WY Scale provides various trading strategies,
                                including custom and pre-defined ones. You are
                                solely responsible for selecting, configuring,
                                and managing the trading strategies. WY Scale is
                                not liable for any losses incurred due to the
                                bot's performance based on your selected
                                strategies.{" "}
                              </span>
                            </div>
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                                2.3 Exchange Binding
                              </span>
                              <span className="polisy_inn_content_span">
                                The bot can be integrated with multiple top
                                exchanges via API keys provided by you. You are
                                responsible for ensuring the accuracy of the
                                exchange credentials and the security of the API
                                keys. WY Scale is not responsible for any
                                unauthorized access or use of your exchange
                                accounts.
                              </span>
                            </div>
                          </div>

                          <div className="policy_second_innimp">
                            <span className="policy_inner_head_span">
                              3. Fees and Payment
                            </span>
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                                3.1 Subscription Fees
                              </span>
                              <span className="polisy_inn_content_span">
                                WY Scale offers a subscription-based service.
                                The subscription fee will be clearly displayed
                                during the sign-up process. By subscribing, you
                                agree to pay all applicable fees for the
                                selected plan. Fees are non-refundable, except
                                as required by law or stated in these Terms.
                              </span>
                            </div>
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                                3.2 Exchange Fees
                              </span>
                              <span className="polisy_inn_content_span">
                                While using the bot, note that the exchanges you
                                bind may charge transaction fees for each trade.
                                These fees are separate from our subscription
                                fees, and WY Scale is not responsible for any
                                fees imposed by the exchanges.
                              </span>
                            </div>
                          </div>

                          <div className="policy_second_innimp">
                            <span className="policy_inner_head_span">
                              4. Fees and Payment
                            </span>
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                                4.1 Subscription Fees
                              </span>
                              <span className="polisy_inn_content_span">
                                WY Scale offers a subscription-based service.
                                The subscription fee will be clearly displayed
                                during the sign-up process. By subscribing, you
                                agree to pay all applicable fees for the
                                selected plan. Fees are non-refundable, except
                                as required by law or stated in these Terms.
                              </span>
                            </div>
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                                4.2 Exchange Fees
                              </span>
                              <span className="polisy_inn_content_span">
                                While using the bot, note that the exchanges you
                                bind may charge transaction fees for each trade.
                                These fees are separate from our subscription
                                fees, and WY Scale is not responsible for any
                                fees imposed by the exchanges.
                              </span>
                            </div>
                          </div>

                          <div className="policy_inner_mainhead">
                            <span className="policy_inner_head_span">
                              5. Subscription Fees
                            </span>
                            <span className="polisy_inn_content_span">
                              WY Scale offers a subscription-based service. The
                              subscription fee will be clearly displayed during
                              the sign-up process. By subscribing, you agree to
                              pay all applicable fees for the selected plan.
                              Fees are non-refundable, except as required by law
                              or stated in these Terms.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Policy;
