import React, { useState, useEffect } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import { Link, useNavigate } from 'react-router-dom';
import { getQrcode } from '../services/authservices';
import { useUser } from '../contexts/usercontext2';
import { getUserDetails } from '../services/authservices';

function Security() {
  const { user, setUser } = useUser();
  const [maskedPassword, setMaskedPassword] = useState(localStorage.getItem('maskedPassword') || 'R*********');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login"); // Redirect to login if no token is found
      return;
    }

    const fetchUserDetails = async () => {
      try {
        const data = await getUserDetails(token); // Fetch user details function you have
        console.log('Fetched user details:', data);

        if (data.status) {
          const tfaStatus = data.tfastatus === 1 ? 'Enabled' : 'Disabled';
          setUser((prev) => ({ ...prev, tfaStatus })); // Update context correctly
          console.log('Updated TFA status in context:', tfaStatus);
        } else {
          console.error('Failed to fetch user details:', data);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
        alert('Error fetching user details. Please try again later.');
      }
    };

    const fetchQrcode = async () => {
      try {
        const data = await getQrcode(token);
        console.log('Fetched TFA status:', data);
        console.log('Fetched data:', data.data);
      } catch (error) {
        console.error("Error fetching TFA status:", error);
        alert("Error fetching TFA status. Please try again later.");
      }
    };

    fetchUserDetails();
    fetchQrcode();
  }, [setUser, navigate]);
  return (
    <div>
      <section>
        <Header />
      </section>
      <div className="container">
        <div className="row prof_row_sm">
          <div className="col-lg-2 dispaly_none_1200">
            <Sidebar />
          </div>
          <div className="col-xl-10 col-lg-12 deposit_pad_top">
            <span className="profile_span">Security Center</span>
            <div className="security_main">
              {/* <div className="row">
            
                <div className="col-6">
                  <div className="api_exchan_head">
                    <img
                      src={require('../assests/icons/security-withdraw.png')}
                      alt="Withdrawal addresses"
                      className="secu_head_img icons-light"
                    />
                    <img
                      src={require("../assests/icons/security-withdraw-dark.webp")}
                      alt="Withdrawal addresses"
                      className="secu_head_img icons-dark"
                    />
                    <div className="api_exchng_namemain">
                      <span className="api_exchng_namespan">
                        Withdrawal addresses
                      </span>
                      <span className="api_exchng_namedefn">
                        Add your withdraw address
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-3 pad_0_apibind_in"></div>
                <div className="col-3 profile_box_divrow">
                  <div className="h-100 d-flex align-items-center">
                    <Link to="/security-withdraw">
                      <button className="prof_chang_box" type="button">
                        <span className="prof_chang_box_span">Change</span>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <hr /> */}

              {/* TFA Section */}
              <div className="row">
                <div className="col-6">
                  <div className="api_exchan_head">
                    <img
                      src={require('../assests/icons/security-tfa.png')}
                      alt="Authenticator app"
                      className="secu_head_img icons-light"
                    />
                    <img
                      src={require("../assests/icons/security-tfa-dark.webp")}
                      alt="Authenticator app"
                      className="secu_head_img icons-dark"
                    />
                    <div className="api_exchng_namemain">
                      <span className="api_exchng_namespan">
                        Authenticator app
                      </span>
                      <span className="api_exchng_namedefn">
                        Use authentication codes for login and other functions
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-3 pad_0_apibind_in">
                  <div className="api_inner_center">
                    {user.tfaStatus === 'Disabled' && (
                      <i className="bi bi-exclamation-circle home_user_info" style={{ color: '#ee516d' }}></i>
                    )}
                    <span className="api_unbound_span" style={{ color: user.tfaStatus === 'Enabled' ? 'green' : '#ee516d' }}>
                      {user.tfaStatus} {/* Display status based on context */}
                    </span>
                  </div>
                </div>
                <div className="col-3 profile_box_divrow">
                  <div className="h-100 d-flex align-items-center">
                    <Link to="/security-tfa">
                      <button className="prof_chang_box" type="button">
                        <span className="prof_chang_box_span">Change</span>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <hr />

              {/* Login Password Section */}
              <div className="row">
                <div className="col-6">
                  <div className="api_exchan_head">
                    <img
                      src={require('../assests/icons/security-loginpass.png')}
                      alt="Login Password"
                      className="secu_head_img icons-light"
                    />
                    <img
                      src={require("../assests/icons/security-loginpass-dark.webp")}
                      alt="Login Password"
                      className="secu_head_img icons-dark"
                    />
                    <div className="api_exchng_namemain">
                      <span className="api_exchng_namespan">
                        Login Password
                      </span>
                      <span className="api_exchng_namedefn">
                        Use authentication codes for login and other functions
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-3 pad_0_apibind_in">
                  <div className="d-flex justify-content-center align-items-center h-100">
                    {maskedPassword ? (
                      <span className="security_login_center">
                        {maskedPassword}
                      </span>
                    ) : (
                      <span className="security_login_center">R*******[**</span>
                    )}
                  </div>
                </div>
                <div className="col-3 profile_box_divrow">
                  <div className="h-100 d-flex align-items-center">
                    <Link to="/security-loginpassword">
                      <button className="prof_chang_box" type="button">
                        <span className="prof_chang_box_span">Change</span>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Security;
