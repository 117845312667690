import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Select from "react-select";
import { Link } from "react-router-dom";
import authService from "../services/authservices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Upgrade = () => {
  const [coinType, setCoinType, coinTyperef] = useState("USDC");

  const options = [
    {
      value: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
      label: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
    },
    {
      value: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
      label: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
    },
    {
      value: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
      label: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
    },
  ];

  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#f9f9f9",
      border: "1px solid #ccc",
      padding: "4px",
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      color: "#fff",
      backgroundColor: isFocused ? "#292b31" : "#181a20",
      cursor: "pointer",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#000",
    }),
  };

  useEffect(() => {
    getPlan();
  }, [0]);

  const [getplan, setGetplan] = useState("");
  const [pageLoader, setpageLoader] = useState(false);
  const [planLoader, setplanLoader] = useState(false);

  const getPlan = async () => {
    try {
      setpageLoader(true);
      const token = localStorage.getItem("token");
      const response = await authService.getPlan(token);
      setpageLoader(false);
      if (response.status) {
        setGetplan(response.data);
      } else {
      }
    } catch (error) {}
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(
      <div className="toast-ml">
        <h4 className="toast-title">Success!</h4>
        <p className="toast-message">{message}</p>
      </div>
    );
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(
      <div className="toast-ml">
        <h4 className="toast-title">Error!</h4>
        <p className="toast-message">{message}</p>
      </div>
    );
  };

  const PlanUpgrade = async (data) => {
    try {
      setplanLoader(true);
      var obj = {
        subid: data.subid,
      };
      const response = await authService.planUpgrade(obj);
      setplanLoader(false);
      if (response.status) {
        showsuccessToast(response.Message);
      } else {
        showerrorToast(response.Message);
      }
    } catch (error) {
      console.log(error, "-----------------");
    }
  };

  return (
    <div>
      <header>
        <Header />
      </header>
      {pageLoader == true ? (
        "Loading...."
      ) : (
        <div className="withdraw-section">
          <div className="container">
            <div className="row prof_row_sm">
              <div className="col-lg-2 dispaly_none_1200">
                <Sidebar />
              </div>
              <div className="col-xl-10 col-lg-12 deposit_pad_top">
                <span className="profile_span">Upgrade</span>
                <div className="row mt-4">
                  <div className="col-lg-7">
                    <div>
                      <div className="upgrade-plan-box">
                        <div>
                          <h6 className="upgrade-plan-title plan-title-clr">
                            Upgrade your plan
                          </h6>
                          <h5>
                            <span className="upgrade-span-num">
                              ${!getplan ? "0" : getplan.subscriptionAmount}
                            </span>{" "}
                            <span className="upgrade-profit-num">
                              /
                              {!getplan
                                ? "0"
                                : getplan.subscriptionprofitpercentage}
                              X Profit
                            </span>
                          </h5>
                          <p className="upgrade-plan-content">
                            Upgrade to unlock exclusive features and enhance
                            your experience.
                          </p>
                          <div className="upgrade-btn-wrapper">
                            {planLoader == true ? (
                              <>
                                <span>
                                  <Link to="" className="upgrade-activate-btn">
                                    Loading...
                                  </Link>
                                </span>
                              </>
                            ) : (
                              <>
                                <span onClick={() => PlanUpgrade(getplan)}>
                                  <Link to="" className="upgrade-activate-btn">
                                    Activate
                                  </Link>
                                </span>
                              </>
                            )}

                            <span>
                              <Link
                                to="/deposit"
                                className="upgrade-deposit-btn"
                              >
                                Deposit
                              </Link>
                            </span>
                          </div>
                        </div>
                        {/* <div>
                        <img
                          src={require("../assests/upgrade-img.webp")}
                          alt="upgrade"
                        />
                      </div> */}
                      </div>
                      <div className="upgrade-features-box">
                        <h6 className="upgrade-plan-title ">Features</h6>
                        <div className="d-flex justify-content-between gap-4 mt-3">
                          <p className="upgrade-features-content">
                            Opportunity to link their exchange and earn profits.
                          </p>
                          <span className="upgrade-check">
                            <i class="fa-solid fa-check"></i>
                          </span>
                        </div>
                        <div className="d-flex justify-content-between gap-4">
                          <p className="upgrade-features-content">
                            Unlimited trading transactions.
                          </p>
                          <span className="upgrade-check">
                            <i class="fa-solid fa-check"></i>
                          </span>
                        </div>
                        <div className="d-flex justify-content-between gap-4">
                          <p className="upgrade-features-content">
                            Non-custodial trading options.
                          </p>
                          <span className="upgrade-check">
                            <i class="fa-solid fa-check"></i>
                          </span>
                        </div>
                        <div className="d-flex justify-content-between gap-4">
                          <p className="upgrade-features-content m-0">
                            Selection of multiple exchange options.
                          </p>
                          <span className="upgrade-check">
                            <i class="fa-solid fa-check"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="container m-top-20 p-0">
                      <div
                        class="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <div className="faq">
                          <h5 className="faq-title">FAQ</h5>
                          <div class="accordion-item font-satoshi">
                            <h2 class="accordion-header" id="flush-headingOne">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                              >
                                1. How do I make a withdrawal?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseOne"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingOne"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body">
                                KYC verification is essential to comply with
                                regulations and ensure the security of our
                                platform by preventing fraud and other illegal
                                activities.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item font-satoshi">
                            <h2 class="accordion-header" id="flush-headingTwo">
                              <button
                                class="accordion-button collapsed max-wrap"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwo"
                                aria-expanded="false"
                                aria-controls="flush-collapseTwo"
                              >
                                2. Why have I still not received my withdrawal?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseTwo"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingTwo"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body">
                                You will need to submit a government-issued ID
                                (like a passport or driver's license) and a
                                recent proof of address (such as a utility bill
                                or bank statement).
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item font-satoshi">
                            <h2
                              class="accordion-header"
                              id="flush-headingThree"
                            >
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseThree"
                                aria-expanded="false"
                                aria-controls="flush-collapseThree"
                              >
                                3. How do I select the right network?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseThree"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingThree"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body">
                                The verification process typically takes [X
                                hours/days], depending on the volume of requests
                                and the clarity of the documents provided.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item font-satoshi">
                            <h2 class="accordion-header" id="flush-headingFour">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFour"
                                aria-expanded="false"
                                aria-controls="flush-collapseFour"
                              >
                                4. How to check the deposit progress?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseFour"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingFour"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body">
                                Yes, we use encryption and follow strict data
                                protection regulations to ensure that your
                                personal information remains secure.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item font-satoshi">
                            <h2 class="accordion-header" id="flush-headingFive">
                              <button
                                class="accordion-button collapsed max-wrap"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFive"
                                aria-expanded="false"
                                aria-controls="flush-collapseFive"
                              >
                                5. Do I need to pay fees for deposit and
                                withdrawal?
                                <span class="icon">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </h2>
                            <div
                              id="flush-collapseFive"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingFive"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body">
                                If your verification is rejected, you will
                                receive an email with the reasons and
                                instructions on how to resolve the issue.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Upgrade;
