import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import Header from "./Header";
import "react-toastify/dist/ReactToastify.css";
import { getUserDetails } from "../services/authservices";
import authService from "../services/authservices";
import { ToastContainer, toast } from "react-toastify";
import dayjs from "dayjs";
function Home() {
  const [currency, setCurrency] = useState("USDC");
  const [network, setNetwork] = useState("BEP20");
  const [transactions, setTransactions] = useState([]);
  const [addressData, setAddressData] = useState(null);
  const [user, setUser] = useState({
    tfaStatus: 0,
    email: null,
    uid: null,
    referralCode: null,
    referralUrl: null,
    botStatus: "DeActive",
    displayname: null,
    country: null,
    isAuthenticated: false,
  });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchUserDetails = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const data = await getUserDetails(token);
        if (data && data.status) {
          const userDetails = {
            isAuthenticated: true,
            tfaStatus: data.tfastatus,
            email: data.email,
            displayname: data.displayname,
            country: data.country,
            uid: data.uid,
            referralCode: data.referralCode,
            referralUrl: data.referralUrl,
            botStatus: data.botStatus,
          };
          setUser(userDetails);
        } else {
          setUser({
            isAuthenticated: false,
            tfaStatus: 0,
            email: null,
            uid: null,
            referralCode: null,
            referralUrl: null,
          });
        }
      } catch (error) {
        setUser({
          isAuthenticated: false,
          tfaStatus: 0,
          email: null,
          uid: null,
          referralCode: null,
          referralUrl: null,
        });
      }
    } else {
      setUser({
        isAuthenticated: false,
        tfaStatus: 0,
        email: null,
        uid: null,
        referralCode: null,
        referralUrl: null,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserDetails();
    getStratagy();
    marketCurrencyList();
    fetchBalance();
  }, []);
  const [marketData, setmarketData, marketDataref] = useState([]);
  const [marketLoader, setmarketLoader] = useState(false);

  // useEffect(() => {
  //   // Connect to WebSocket server
  //   const ws = new WebSocket("ws://localhost:8080");

  //   ws.onmessage = (event) => {
  //     const { data } = JSON.parse(event.data);

  //     if (data) {
  //       setmarketData(data);
  //     }
  //   };

  //   ws.onerror = (error) => {
  //   };

  //   return () => {
  //     ws.close();
  //   };
  // }, []);

  const marketCurrencyList = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        setmarketLoader(true);
        const data = await authService.marketCurrencyList(token);
        if (data.status) {
          setmarketLoader(false);
          setmarketData(data.data);
        } else {
          setmarketLoader(false);
          setmarketData([]);
        }
      } catch (error) { }
    }
  };
  const handleCopy = (txID) => {
    // Prevent triggering multiple times rapidly
    if (handleCopy.timeout) clearTimeout(handleCopy.timeout);

    handleCopy.timeout = setTimeout(() => {
      navigator.clipboard
        .writeText(txID)
        .then(() => {
          toast.success(
            <div className="toast-ml">
              <h4 className="toast-title">Success!</h4>
              <p className="toast-message">
                Transaction ID copied to clipboard!
              </p>
            </div>
          );
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
          toast.error(
            <div className="toast-ml">
              <h4 className="toast-title">Error!</h4>
              <p className="toast-message">Failed to copy Transaction ID</p>
            </div>
          );
        });
    }, 300); // 300ms debounce time
  };
  const copyToClipboard = (uid) => {
    if (copyToClipboard.timeout) clearTimeout(copyToClipboard.timeout);
    copyToClipboard.timeout = setTimeout(() => {
      navigator.clipboard
        .writeText(uid)
        .then(() => {
          showsuccessToast("UID copied to clipboard!");
        })
        .catch((err) => {
          showerrorToast("Failed to copy UID");
        });
    }, 300);
  };
  const handleFollowStrategy = async (data) => {
    try {
      var obj = {
        action: data.buttonLabel,
        strategyId: data._id,
      };
      const response = await authService.handleFollowStrategy(obj);
      setStratagyLoader(false);
      if (response.status == true) {
        showsuccessToast(response.Message);
        getStratagy();
      } else {
        showerrorToast(response.Message);
        getStratagy();
      }
    } catch (err) { }
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(
      <div className="toast-ml">
        <h4 className="toast-title">Success!</h4>
        <p className="toast-message">{message}</p>
      </div>
    );
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(
      <div className="toast-ml">
        <h4 className="toast-title">Error!</h4>
        <p className="toast-message">{message}</p>
      </div>
    );
  };

  const [StratagyLoader, setStratagyLoader] = useState(false);
  const [StratagyList, setStratagyList, StratagyListref] = useState([]);

  const getStratagy = async (data) => {
    try {
      setStratagyLoader(true);
      const token = localStorage.getItem("token");
      const response = await authService.topStratagies(token);
      setStratagyLoader(false);
      if (response.status == true) {
        setStratagyList(response.data);
      } else {
        setStratagyList(response.data);
      }
    } catch (error) { }
  };

  const navigateStratagy = () => {
    navigate("/strategies");
  };

  const updateUserBotStatus = async (data) => {
    try {
      var obj = {
        action: data,
      };
      const response = await authService.updateUserBotStatus(obj);
      setStratagyLoader(false);
      if (response.status == true) {
        showsuccessToast(response.Message);
        getStratagy();
        fetchUserDetails();
      } else {
        showerrorToast(response.Message);
        getStratagy();
        fetchUserDetails();
      }
    } catch (err) { }
  };
  const fetchAddressData = async () => {
    try {
      const data = await authService.getUserAddressNetwork(currency, network);
      if (data.status) {
        setAddressData(data.data);
      } else {
        console.log(data.Message);
      }
    } catch (error) {
      console.error("Error fetching address data:", error);
    }
  };

  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const data = await authService.getTransactions();
      if (data.status) {
        if (Array.isArray(data.deposits) && data.deposits.length > 0) {
          // Filter unique transactions based on txID
          const uniqueTransactions = data.deposits.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.txID === value.txID)
          );
          setTransactions(uniqueTransactions);
        } else {
          // If no deposits or empty deposits, set an empty array
          setTransactions([]);
          //toast.error("No transactions found");
        }
      } else {
        setTransactions([]);
        //toast.error("No transactions found");
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setTransactions([]);
      toast.error(
        <div className="toast-ml">
          <h4 className="toast-title">Error!</h4>
          <p className="toast-message">Failed to load transactions</p>
        </div>
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAddressData();
    fetchTransactions();
  }, [currency, network]);

  const [totalBalance, setTotalBalance, totalBalanceref] = useState(0);
  const fetchBalance = async () => {
    try {
      const response = await authService.getTransactions();
      if (response.status) {
        setTotalBalance(parseFloat(response.totalBalanceUSDT) || 0);
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };
  return (
    <div>
      <section>
        <Header />
      </section>
      <div className="home">
        <div className="container">
          <div className="home_main">
            <div className="home_first_main">
              <div className="home_first_left">
                <div className="home_user_main">
                  <img
                    src={require("../assests/icons/home-user.png")}
                    alt="User"
                    className="home_user_icon"
                  />
                  <div className="home_user_name">
                    <span className="home_user_display">{user.email}</span>
                    <div className="home_uid_main">
                      <span className="home_user_uid">{user.uid}</span>
                      <i
                        class="ri-file-copy-line cursor-pointer uid_copy"
                        onClick={() => copyToClipboard(user.uid)}
                      ></i>
                    </div>
                  </div>
                </div>
                <span className="home_slash">|</span>
                <div className="home_bln_top">
                  <div className="home_user_bln">
                    <span className="home_userbln_head">
                      Total Value (USDT)
                    </span>
                    <span className="footer_head">
                      {totalBalanceref.current}
                    </span>
                  </div>
                  <div className="home_user_bln">
                    <span className="home_userbln_head">
                      Binance Balance (USDT)
                    </span>
                    <span className="footer_head">0.00</span>
                  </div>
                  <div className="home_user_bln">
                    <span className="home_userbln_head">Today’s Pnl</span>
                    <span className="footer_head">0.00%</span>
                  </div>
                  <div className="home_user_bln">
                    <span className="home_userbln_head"> 7 Days Pnl</span>
                    <span className="footer_head">0.00%</span>
                  </div>
                  <div className="home_user_bln">
                    <span className="home_userbln_head"> 30 Days Pnl</span>
                    <span className="footer_head">0.00%</span>
                  </div>
                </div>
              </div>
              <Link to="/assets">
                <div className="dash_first_right">
                  <img
                    src={require("../assests/icons/home-assest-view.png")}
                    alt="View Asset"
                    className="home_viewasset_img"
                  />
                  <span className="home-viewasset-span">View Assets</span>
                </div>
              </Link>
            </div>
            <div className="row mt-4">
              <div className="col-lg-8">
                <div className="h-100">
                  <img
                    src={require("../assests/home-banner.webp")}
                    alt="Trading Bot"
                    className="home_second_img"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="home_second_rightmain">
                  <div className="manage_btn_row">
                    <span className="home_botstatus_span">Bot status</span>

                    <div className="home_bottop_right">
                      <i
                        class="bi bi-exclamation-circle home_user_info"
                        style={{ color: "#ee516d" }}
                      ></i>
                      {/* <i class="fa-regular fa-circle-check home_user_info_correct"></i> */}
                      <span className="home_bot_inactive">
                        {" "}
                        {user.botStatus == "Active"
                          ? "Active"
                          : "In Active"}{" "}
                      </span>
                      {/* <span className="home_bot_active">Active</span> */}
                    </div>
                  </div>
                  <div className="home_activate_main">
                    {/* <div className="home_activate_mainactivate"> */}
                    <span className="home_botstatus_def">
                      Your bot is inactive.
                      <span class="bot_condition">
                        {user.botStatus == "Active" ? "Activate" : "Deactivate"}{" "}
                      </span>
                      your Bot!
                    </span>
                    {/* <span className="home_botstatus_def">
                      Your bot is active. You can 
                      <span class="bot_condition_ceactive"> De-activate </span>
                      your Bot!
                    </span> */}

                    {user.botStatus == "Active" ? (
                      <div
                        class="invest_main"
                        onClick={() => updateUserBotStatus("DeActive")}
                      >
                        <span class="invest_now">
                          {" "}
                          {user.botStatus == "Active"
                            ? "Activate"
                            : "De Activate"}{" "}
                        </span>
                        <span class="invest_new">
                          {" "}
                          {user.botStatus == "Active"
                            ? "Activate"
                            : "De Activate"}{" "}
                        </span>
                      </div>
                    ) : (
                      <div
                        class="invest_main"
                        onClick={() => updateUserBotStatus("Active")}
                      >
                        <span class="invest_now">
                          {" "}
                          {user.botStatus == "Active"
                            ? "Activate"
                            : "De Activate"}{" "}
                        </span>
                        <span class="invest_new">
                          {" "}
                          {user.botStatus == "Active"
                            ? "Activate"
                            : "De Activate"}{" "}
                        </span>
                      </div>
                    )}

                    {/* <div class="invest_main_deactive">
                      <span class="invest_now_deactivenow">De-activate</span>
                      <span class="invest_new_deactivenew">De-activate</span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-8">
                <div className="home_third_left">
                  <div className="home_third_topmain">
                    <div className="manage_btn_row">
                      <span className="home_botstatus_span">
                        Top strategies
                      </span>
                      <div
                        className="d-flex align-items-center gap-2 cursor-pointer"
                        onClick={navigateStratagy}
                      >
                        <span className="home_starty_view">View All</span>
                        <i class="fa-solid fa-chevron-right home_starty_viewarrow"></i>
                      </div>
                    </div>
                    {StratagyLoader == true ? (
                      <span className="home_loading">Loading....</span>
                    ) : (
                      <div className="row">
                        {StratagyListref.current.length > 0
                          ? StratagyListref.current
                            .slice(0, 3)
                            .map((options) => {
                              return (
                                <div className="col-lg-4">
                                  <div className="home_topstarty_inmain">
                                    <div className="home_topstary_inntop">
                                      <div className="top_startgy_intopintop">
                                        <span className="dca_main">
                                          {options.stratagyName}
                                        </span>
                                        <div className="reg_check_main">
                                          <div className="">
                                            <img
                                              src={require("../assests/icons/topstartegy-users.png")}
                                              alt="Users"
                                              className="topstartegy-users"
                                            />
                                            <span className="top-99">
                                              {" "}
                                              {options.followers}
                                            </span>
                                          </div>
                                          <div className="topstartegy_color_div">
                                            <span>
                                              Leverage: {options.leverage}X
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="top_startgy_intopinbottom">
                                        <div className="top_startgy_inbottomleft">
                                          <span className="top_startgy_inbottomadvance">
                                            Annualized ROI
                                          </span>
                                          <span className="top_startgy_inbottompercen">
                                            {options.annualROI}%
                                          </span>
                                        </div>
                                        <img
                                          src={require("../assests/topstrategy-chart.png")}
                                          alt="Startegy chart"
                                          className="startegy_chart"
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="home_topstary_follow"
                                      onClick={() =>
                                        handleFollowStrategy(options)
                                      }
                                    >
                                      <span>{options.buttonLabel}</span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    )}
                  </div>
                  <div className="home_third_topmain pb-0">
                    <div className="manage_btn_row">
                      <span className="home-viewasset-span">
                        {" "}
                        Recent Depsoit
                      </span>
                      <Link to="/allhistory">
                        <div className="d-flex align-items-center gap-2 cursor-pointer">
                          <span className="home_starty_view">View All</span>
                          <i class="fa-solid fa-chevron-right home_starty_viewarrow"></i>
                        </div>
                      </Link>
                    </div>
                    <div className="recent_depo_tablemain pb-0">
                      <div className="table-responsive table-borderless home_recent_table">
                        {loading ? (
                          <p className="depo_no_record">Loading...</p>
                        ) : (
                          <table className="table">
                            <thead>
                              <tr>
                                <th className="text_left">Currency</th>
                                <th>Date & Time</th>
                                <th>Network</th>
                                <th>Amount</th>
                                <th>Transaction ID</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transactions.length > 0 ? (
                                transactions.slice(0, 5).map((transaction, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div className="home_recent_firstdata">
                                        <img
                                          src={require("../assests/usdt-logo.png")}
                                          alt="USDT"
                                          className="usdt_home_recent"
                                        />
                                        <span>{transaction.currency}</span>
                                      </div>
                                    </td>
                                    <td>
                                      <span>
                                        {isNaN(new Date(transaction.timestamp))
                                          ? "null"
                                          : dayjs(transaction.timestamp).format(
                                            "DD MMMM YYYY, hh:mm A"
                                          )}
                                      </span>
                                    </td>
                                    <td>
                                      <span>{transaction.network}</span>
                                    </td>
                                    <td>
                                      <span>{transaction.amount}</span>
                                    </td>
                                    <td>
                                      <div className="home_uid_main">
                                        <span>{transaction.txID}</span>
                                        <i
                                          className="ri-file-copy-line cursor-pointer uid_copy_recent"
                                          onClick={() =>
                                            handleCopy(transaction.txID)
                                          }
                                        ></i>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="5">
                                    <span className="depo_no_record">
                                      No transactions found
                                    </span>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="home_third_topmain mb-2">
                  <span className="home_right_marketspan">Market prices</span>
                  <div className="table-responsive table-borderless home_recent_table">
                    <table className="table">
                      <thead>
                        <th className="text_left">Name</th>
                        <th>Price</th>
                        <th>Change</th>
                      </thead>
                      {marketLoader === false ? (
                        <tbody>
                          {marketDataref.current.length > 0
                            ? marketDataref.current
                              .slice(0, 7)
                              .map((options) => {
                                return (
                                  <tr>
                                    <td>
                                      <div className="home_recent_firstdata">
                                        <img
                                          src={options.imageUrl}
                                          alt={options.symbol}
                                          className="usdt_home_recent"
                                        />
                                        <span> {options.symbol}</span>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="home_market_center">
                                        <span> {options.price}</span>
                                        <span className="home_market_center_dim">
                                          ${options.Usdprice}
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <span
                                        className={
                                          options.change24Hour > 0
                                            ? "home_market_lastdata"
                                            : "home_market_lastdatared "
                                        }
                                      >
                                        {" "}
                                        {options.change24Hour}%
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })
                            : ""}
                        </tbody>
                      ) : (
                        <span className="home_loading">Loading....</span>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
