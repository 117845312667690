import React, { createContext, useContext, useState, useEffect } from 'react';

const TfaContext = createContext();

export const TfaProvider = ({ children }) => {
    const [tfaStatus, setTfaStatus] = useState(() => {
        const savedStatus = localStorage.getItem('tfaStatus');
        return savedStatus ? Number(savedStatus) : 0; 
    });

    useEffect(() => {
        try {
            localStorage.setItem('tfaStatus', tfaStatus);
        } catch (error) {
            console.error('Error saving tfaStatus to localStorage:', error);
        }
    }, [tfaStatus]);

    return (
        <TfaContext.Provider value={{
            tfaStatus,
            setTfaStatus,
            displayStatus: tfaStatus === 1 ? 'Enabled' : 'Disabled' 
        }}>
            {children}
        </TfaContext.Provider>
    );
};

export const useTfa = () => useContext(TfaContext);
