import React, { useState, useEffect, useContext } from "react";
import Logo from "../assests/Logo.png";
import authService from "../services/authservices";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";


import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";

import { Link, useNavigate, useLocation } from "react-router-dom";
import UserIcon from "../assests/icons/profile.png";
import { UserContext } from "../contexts/UserContext";
import { getUserDetails } from "../services/authservices";

dayjs.extend(relativeTime);
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  appBarItems: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileMenuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  drawerIcon: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  drawer: {
    width: 300,
  },
}));

function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [NotiEl, setNotiEl] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [loginCheck, setloginCheck] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");
  const location = useLocation();
  const classes = useStyles();
  const { logout } = useContext(UserContext);
  const [user, setUser] = useState({
    tfaStatus: 0,
    email: null,
    uid: null,
    referralCode: null,
    referralUrl: null,
    displayname: null,
    country: null,
    isAuthenticated: false,
  });
  const [loading, setLoading] = useState(true);

  const fetchUserDetails = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const data = await getUserDetails(token);
        if (data && data.status) {
          const userDetails = {
            isAuthenticated: true,
            tfaStatus: data.tfastatus,
            email: data.email,
            displayname: data.displayname,
            country: data.country,
            uid: data.uid,
            referralCode: data.referralCode,
            referralUrl: data.referralUrl,
          };
          setUser(userDetails);
        } else {
          setUser({
            isAuthenticated: false,
            tfaStatus: 0,
            email: null,
            uid: null,
            referralCode: null,
            referralUrl: null,
          });
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        setUser({
          isAuthenticated: false,
          tfaStatus: 0,
          email: null,
          uid: null,
          referralCode: null,
          referralUrl: null,
        });
      }
    } else {
      setUser({
        isAuthenticated: false,
        tfaStatus: 0,
        email: null,
        uid: null,
        referralCode: null,
        referralUrl: null,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose1 = () => {
    setNotiEl(null);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick1 = (event) => {
    setNotiEl(event.currentTarget);
  };

  const handleThemeToggle = () => {
    let currentTheme = localStorage.getItem("theme");

    if (currentTheme === "dark") {
      localStorage.setItem("theme", "light");
      setTheme("light");
    } else {
      localStorage.setItem("theme", "dark");
      setTheme("dark");
    }
  };
  useEffect(() => {
    // Apply the theme to the body when the theme state changes
    if (theme === "dark") {
      document.body.classList.add("dark");
      document.body.classList.remove("light");
    } else {
      document.body.classList.add("light");
      document.body.classList.remove("dark");
    }
  }, [theme]); // Trigger when the theme changes

  useEffect(() => {
    let userToken = sessionStorage.getItem("user_token");
    if (userToken) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }
  }, []);

  useEffect(() => {
    const path = location.pathname.split("/").pop();
    setActiveLink(path);
  }, [location]);

  const isActive = (path) => {
    return activeLink === path ? "active-link" : "";
  };

  const obfuscateEmail = (email) => {
    if (!email) return "";
    const [localPart, domainPart] = email.split("@");
    const firstFive = localPart.slice(0, 3);
    return `${firstFive}***@${domainPart}`;
  };

  const [open1, setOpen] = useState(false);

  const navigate = useNavigate();

  const notifyNav = () => {
    navigate("/loginHistory");
  };
  const copyToClipboard = (uid) => {
    if (copyToClipboard.timeout) clearTimeout(copyToClipboard.timeout);
    copyToClipboard.timeout = setTimeout(() => {
      navigator.clipboard
        .writeText(uid)
        .then(() => {
          toast.success(
            <div className="toast-ml">
              <h4 className="toast-title">Success!</h4>
              <p className="toast-message">UID copied to clipboard!</p>
            </div>
          );
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
          toast.error(
            <div className="toast-ml">
              <h4 className="toast-title">Error!</h4>
              <p className="toast-message">Failed to copy UID</p>
            </div>
          );
        });
    }, 300);
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };
  const [loginHistory, setLoginHistory] = useState([]);


  useEffect(() => {
    const fetchLoginHistory = async () => {
      const response = await authService.getLoginHistory();
      if (response.status) {
        setLoginHistory(response.data.slice(0, 5)); // Limit to 5 records
      } else {
        console.error('Failed to fetch login history:', response.Message);
      }
      setLoading(false);
    };

    fetchLoginHistory();
  }, []);
  return (
    <div>
      <header className="scrolled">
        <div>
          <div className={`${classes.root} `}>
            <AppBar position="static">
              <Toolbar className="container-fluid pad-0 w-100">
                <Typography variant="h6" className={classes.title}>
                  <div className="d-flex">
                    <Link to="/" className="logo-brand">
                      <img src={Logo} className="img-fluid" alt="logo" />
                    </Link>
                    <div className={`menu_new_typr ${classes.appBarItems}`}>
                      {/* <Link to="/" color="inherit" className="contact_button"> */}
                      <Link
                        to="/home"
                        color="inherit"
                        className={`${isActive("home")}`}
                      >
                        Home
                      </Link>
                      <Link
                        to="/strategies"
                        color="inherit"
                        className={`${isActive("strategies")}`}
                      >
                        Strategies
                      </Link>
                      <Link
                        to="/assets"
                        color="inherit"
                        className={`${isActive("assets")}`}
                      >
                        Assets
                      </Link>
                      <Link
                        to="/referral"
                        color="inherit"
                        className={`${isActive("referral")}`}
                      >
                        Refer & Earn
                      </Link>
                    </div>
                  </div>
                </Typography>

                <div className="mobile-theme-icon">
                  {/* {theme == "dark" ? (
                    <Link
                      onClick={handleThemeToggle}
                      aclassName="contact_button"
                    >
                      <span className="theme-icons">
                        {" "}
                        <i clss="bi bi-brightness-high"></i>
                      </span>
                    </Link>
                  ) : (
                    <Link
                      onClick={handleThemeToggle}
                      className="contact_button"
                    >
                      <span className="theme-icons">
                        <i class="bi bi-moon"></i>
                      </span>
                    </Link>
                  )} */}

                  {theme == "dark" ? (
                    <Link
                      onClick={handleThemeToggle}
                      className="contact_button"
                    >
                      <span className="theme-icons">
                        <i class="bi bi-brightness-high"></i>
                      </span>
                    </Link>
                  ) : (
                    <Link
                      onClick={handleThemeToggle}
                      className="contact_button"
                    >
                      <span className="theme-icons">
                        {" "}
                        <i class="bi bi-moon"></i>
                      </span>
                    </Link>
                  )}
                </div>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  className={` m-0 p-0 ${classes.mobileMenuButton} ${classes.drawerIcon}`}
                >
                  <MenuIcon className="meus" onClick={handleMobileMenuOpen} />
                </IconButton>

                <div className={`menu_new_typr_right ${classes.appBarItems}`}>
                  {/* {loginCheck ? (
                    ""
                  ) : (
                    <Link to="/login">
                      <button className="head-btn-login">
                        <span className="head-btn-login-now">Log In</span>
                        <span className="head-btn-login-new">Log In</span>
                        </button>
                    </Link>
                  )}
                  {loginCheck ? (
                    ""
                  ) : (
                    <Link to="/register">
                      <button className="head-btn">
                        <span className="head-btn-now">Sign Up</span>
                        <span className="head-btn-new">Sign Up</span>
                        </button>
                    </Link>
                  )} */}

                  {!user.isAuthenticated ? (
                    <>
                      <Link to="/login">
                        <button className="head-btn">
                          <span className="head-btn-now">Log In</span>
                          <span className="head-btn-now">Log In</span>
                        </button>
                      </Link>

                      <Link to="/register">
                        <button className="head-btn">
                          <span className="head-btn-now">Sign Up</span>
                          <span className="head-btn-new">Sign Up</span>
                        </button>
                      </Link>
                    </>
                  ) : (
                    ""
                  )}

                  {/* {loginCheck ? ( */}
                  {user.isAuthenticated && (
                    <Link to="/deposit">
                      <button className="head-btn">
                        <span className="head-btn-now">Deposit</span>
                        <span className="head-btn-new">Deposit</span>
                      </button>
                    </Link>
                  )}

                  {/* ) : (
                      ""
                    )} */}
                  <span className="das mx-1">|</span>
                  {/* <div class="btn-group more-wrapper">
                      <button
                        class="btn btn-secondary btn-lg dropdown-toggle more-select bell-notify  nav-primary-icons"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <div className="head-drops cursor-pointer">
                        <img
                          src={require("../assests/icons/Notification.png")}
                          className="moons"
                          width="30px"
                        />
                        </div>
                      </button>

                      <ul class="dropdown-menu notify-dropdown">
                        <div className="notify-contents">
                          <div className="notify-head-wrapper d-flex align-items-center justify-content-between">
                            <h5>Notifications</h5>
                            <button onClick={notifyNav}>
                              View All <i class="ri-arrow-right-s-line"></i>
                            </button>
                          </div>

                        
                        </div>
                      </ul>
                    </div> */}

                  <>
                    {user.isAuthenticated && (
                      <h6
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        className=" head-drops cursor-pointer"
                        onClick={handleClick1}
                      >
                        {/* {loginCheck ? ( */}
                        <img
                          src={require("../assests/icons/Notification.png")}
                          width="30px"
                          className="dark_display_none nav-primary-icons"
                        />
                        {/* ) : (
                          ""
                        )} */}
                        {/* <img
                          src={require("../assets/profile_dark.png")}
                          className="dark_profile "
                        /> */}
                      </h6>
                    )}
                    {user.isAuthenticated && (
                      <Menu
                        id="simple-menu"
                        anchorEl={NotiEl}
                        keepMounted
                        open={Boolean(NotiEl)}
                        onClose={handleClose1}
                        className="MuiList-padding-notify"
                      >
                        {/* {loginCheck ? ( */}
                        <div className="frame-container notify-dropdown">
                          {/* user details */}
                          <div className="user-details-noti">
                            <div className="notify-contents">
                              <div className="notify-head-wrapper d-flex align-items-center justify-content-between w-100">
                                <h5>Notifications</h5>
                                <div
                                  className="d-flex align-items-center gap-2 cursor-pointer"
                                  onClick={notifyNav}
                                >
                                  <span className="home_starty_view">
                                    View All
                                  </span>
                                  <i class="fa-solid fa-chevron-right home_starty_viewarrow"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="noti_drop_margin">
                            {loading ? (
                              <p>Loading...</p>
                            ) : (
                              loginHistory.map((login, index) => (
                                <div key={index} className="d-flex align-items-center justify-content-between my-2 py-2">
                                  <span className="top_startgy_inbottomadvance">
                                    Login Successfully
                                  </span>
                                  <span className="noti_inner_right">
                                    {dayjs(login.createdTime).fromNow()}
                                  </span>
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                        {/* ) : (
                        ""
                      )} */}
                      </Menu>
                    )}
                  </>

                  <>
                    {user.isAuthenticated && (
                      <h6
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        className=" head-drops cursor-pointer"
                        onClick={handleClick}
                      >
                        {/* {loginCheck ? ( */}
                        <img
                          src={require("../assests/icons/profile.png")}
                          width="30px"
                          className="dark_display_none nav-primary-icons"
                        />
                        {/* ) : (
                          ""
                        )} */}
                        {/* <img
                          src={require("../assets/profile_dark.png")}
                          className="dark_profile "
                        /> */}
                      </h6>
                    )}
                    {user.isAuthenticated && (
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        className="MuiList-padding"
                      >
                        {/* {loginCheck ? ( */}
                        <div className="frame-container">
                          {/* user details */}
                          <div className="user-details">
                            <img
                              src={require("../assests/icons/home-user.png")}
                              alt="usericon"
                              className="user-img"
                            />
                            <div className="details">
                              <span className="details-mail">{user.email}</span>
                              <span className="details-udi">
                                UID: {user.uid}
                                <i
                                  className="fa-regular fa-copy cursor-pointer"
                                  onClick={() => copyToClipboard(user.uid)}
                                ></i>
                              </span>
                            </div>
                          </div>
                          {/* links */}
                          <ul className="links">
                            <li>
                              <Link to="/profile" className="link-content">
                                <div className="head_link_flex">
                                  <img
                                    src={require("../assests/icons/user-icon.png")}
                                    // width="30px"
                                    className="nav-primary-icons-headinner"
                                  />
                                  <span>Profile</span>
                                </div>
                                <span className="text_head_arrow hover-show">
                                  <i class="fa-solid fa-chevron-right"></i>
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/deposit" className="link-content">
                                <div className="head_link_flex">
                                  <img
                                    src={require("../assests/icons/deposit-icon.png")}
                                    // width="30px"
                                    className="nav-primary-icons-headinner"
                                  />
                                  <span>Deposit</span>
                                </div>
                                <span className="text_head_arrow hover-show">
                                  <i class="fa-solid fa-chevron-right"></i>
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/withdraw" className="link-content">
                                <div className="head_link_flex">
                                  <img
                                    src={require("../assests/icons/withdraw-icon.png")}
                                    // width="30px"
                                    className="nav-primary-icons-headinner"
                                  />
                                  <span>Withdraw</span>
                                </div>
                                <span className="text_head_arrow hover-show">
                                  <i class="fa-solid fa-chevron-right"></i>
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/apibind" className="link-content">
                                <div className="head_link_flex">
                                  <img
                                    src={require("../assests/icons/api-bind.png")}
                                    // width="30px"
                                    className="nav-primary-icons-headinner"
                                  />
                                  <span>API Bind</span>
                                </div>
                                <span className="text_head_arrow hover-show">
                                  <i class="fa-solid fa-chevron-right"></i>
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/team" className="link-content">
                                <div className="head_link_flex">
                                  <img
                                    src={require("../assests/icons/team-ico.png")}
                                    // width="30px"
                                    className="nav-primary-icons-headinner"
                                  />
                                  <span>Your Team</span>
                                </div>
                                <span className="text_head_arrow hover-show">
                                  <i class="fa-solid fa-chevron-right"></i>
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/security" className="link-content">
                                <div className="head_link_flex">
                                  <img
                                    src={require("../assests/icons/Security-icon.png")}
                                    // width="30px"
                                    className="nav-primary-icons-headinner"
                                  />
                                  <span>Security</span>
                                </div>
                                <span className="text_head_arrow hover-show">
                                  <i class="fa-solid fa-chevron-right"></i>
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/faq" className="link-content">
                                <div className="head_link_flex">
                                  <img
                                    src={require("../assests/icons/question-icon.png")}
                                    // width="30px"
                                    className="nav-primary-icons-headinner"
                                  />
                                  <span> FAQs</span>
                                </div>
                                <span className="text_head_arrow hover-show">
                                  <i class="fa-solid fa-chevron-right"></i>
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/policy" className="link-content">
                                <div className="head_link_flex">
                                  <img
                                    src={require("../assests/icons/policy-icon.png")}
                                    // width="30px"
                                    className="nav-primary-icons-headinner"
                                  />
                                  <span> Policy</span>
                                </div>
                                <span className="text_head_arrow hover-show">
                                  <i class="fa-solid fa-chevron-right"></i>
                                </span>
                              </Link>
                            </li>
                          </ul>
                          {/* logout button */}
                          {user.isAuthenticated && (
                            <div
                              className="btn-wrapper security-link cursor-pointer"
                              onClick={handleLogout}
                            >
                              <span className="user-btn">Logout</span>
                              <img
                                src={require("../assests/logout.png")}
                                alt="logoutimg"
                                className="logout-img"
                              />
                            </div>
                          )}

                          {/* <div className="btn-wrapper security-link cursor-pointer" onClick={handleLogout}>
                          <span className="user-btn">Logout</span>
                          <img
                            src={require("../assests/logout.png")}
                            alt="logoutimg"
                            className="logout-img"
                          />
                        </div> */}
                        </div>
                        {/* ) : (
                        ""
                      )} */}
                      </Menu>
                    )}
                  </>

                  {theme == "dark" ? (
                    <Link
                      onClick={handleThemeToggle}
                      className="contact_button"
                    >
                      <span className="theme-icons">
                        <i class="bi bi-brightness-high"></i>
                      </span>
                    </Link>
                  ) : (
                    <Link
                      onClick={handleThemeToggle}
                      className="contact_button"
                    >
                      <span className="theme-icons">
                        {" "}
                        <i class="bi bi-moon"></i>
                      </span>
                    </Link>
                  )}
                </div>
              </Toolbar>
            </AppBar>

            <Drawer
              anchor="left"
              open={mobileMenuOpen}
              onClose={handleMobileMenuClose}
              className={`mobile-drawer ${classes.drawer}`}
            >
              <div className={classes.mobileMenu}>
                <div className="logo_new_sectio mobile_menu_icon">
                  <Link to="/" className="logo-brand">
                    <img src={Logo} className="img-fluid" alt="logo" />
                  </Link>
                </div>

                <div className="menu_statis">
                  <ListItem button className="drawa">
                    <Link to="/home">Home</Link>
                  </ListItem>
                  <ListItem button className="drawa">
                    <Link to="/strategies">Strategies</Link>
                  </ListItem>
                  <ListItem button className="drawa ">
                    <Link to="/assets">Assets</Link>
                  </ListItem>
                  <ListItem button className="drawa">
                    <Link to="/referral"> Refer & Earn</Link>
                  </ListItem>
                  <ListItem button className="drawa">
                    <Link to="/profile"> Profile</Link>
                  </ListItem>
                  <ListItem button className="drawa">
                    <Link to="/deposit"> Deposit</Link>
                  </ListItem>
                  <ListItem button className="drawa">
                    <Link to="/withdraw"> Withdraw</Link>
                  </ListItem>
                  <ListItem button className="drawa">
                    <Link to="/apibind"> API Bind</Link>
                  </ListItem>
                  <ListItem button className="drawa">
                    <Link to="/upgrade">Upgrade</Link>
                  </ListItem>
                  {/* <ListItem button className="drawa ">
                    <Link to="/login"> <button className="head-btn-login">Login</button> </Link>
                  </ListItem>
                  <ListItem button className="drawa ">
                  <Link to="/register"> <button className="head-btn">Register</button> </Link>
                  </ListItem> */}
                </div>
              </div>
            </Drawer>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
