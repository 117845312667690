import React, { useState, useRef, useEffect, useCallback } from "react";
import useStateRef from "react-usestateref";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Select from "react-select";
import { Link } from "react-router-dom";

import service from "../services/authservices";

function Deposit() {
  const [currency, setCurrency] = useState("USDC");
  const [network, setNetwork] = useState("BEP20");
  const [addressData, setAddressData] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const coinTyperef = useRef(currency);
  const fetchAddressData = async () => {
    try {
      const data = await service.getUserAddressNetwork(currency, network);
      if (data.status) {
        setAddressData(data.data);
      } else {
        console.log(data.Message);
      }
    } catch (error) {
      console.error("Error fetching address data:", error);
    }
  };

  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const data = await service.getTransactions();
      if (data.status) {
        if (Array.isArray(data.deposits) && data.deposits.length > 0) {
          // Filter unique transactions based on txID
          const uniqueTransactions = data.deposits.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.txID === value.txID)
          );
          setTransactions(uniqueTransactions);
        } else {
          // If no deposits or empty deposits, set an empty array
          setTransactions([]);
          //toast.error("No transactions found");
        }
      } else {
        setTransactions([]);
        //toast.error("No transactions found");
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setTransactions([]);
      toast.error(
        <div className="toast-ml">
          <h4 className="toast-title">Error!</h4>
          <p className="toast-message">Failed to load transactions</p>
        </div>
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAddressData();
    fetchTransactions();
  }, [currency, network]);

  const currencyOptions = [
    { value: "USDC", label: "USDC" },
    { value: "USDT", label: "USDT" },
  ];

  const networkOptions = [
    { value: "BEP20", label: "BEP20" },
    { value: "TRC20", label: "TRC20" },
  ];

  const handleCopy = (txID) => {
    // Prevent triggering multiple times rapidly
    if (handleCopy.timeout) clearTimeout(handleCopy.timeout);

    handleCopy.timeout = setTimeout(() => {
      navigator.clipboard
        .writeText(txID)
        .then(() => {
          toast.success(
            <div className="toast-ml">
              <h4 className="toast-title">Success!</h4>
              <p className="toast-message">
                Transaction ID copied to clipboard!
              </p>
            </div>
          );
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
          toast.error(
            <div className="toast-ml">
              <h4 className="toast-title">Error!</h4>
              <p className="toast-message">Failed to copy Transaction ID</p>
            </div>
          );
        });
    }, 300); // 300ms debounce time
  };

  const handleCopyAdd = (address) => {
    // Prevent triggering multiple times rapidly
    if (handleCopyAdd.timeout) clearTimeout(handleCopyAdd.timeout);

    handleCopyAdd.timeout = setTimeout(() => {
      navigator.clipboard
        .writeText(address)
        .then(() => {
          toast.success(
            <div className="toast-ml">
              <h4 className="toast-title">Success!</h4>
              <p className="toast-message">Address copied to clipboard!</p>
            </div>
          );
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
          toast.error(
            <div className="toast-ml">
              <h4 className="toast-title">Error!</h4>
              <p className="toast-message">Failed to copy address</p>
            </div>
          );
        });
    }, 300); // 300ms debounce time
  };

  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#f9f9f9",
      border: "1px solid #ccc",
      padding: "4px",
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      color: "#fff",
      backgroundColor: isFocused ? "#292b31" : "#181a20",
      cursor: "pointer",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#000",
    }),
  };

  return (
    <div>
      <section>
        <Header />
      </section>

      <div className="deposit-section">
        <div className="container">
          <div className="row prof_row_sm">
            <div className="col-lg-2 dispaly_none_1200">
              <Sidebar />
            </div>
            <div className="col-xl-10 col-lg-12 deposit_pad_top">
              <span className="profile_span">Deposit</span>
              <div className="row mt-4">
                <div className="col-lg-7">
                  <div className="deposit_left_main">
                    <div className="depo_inner_divmain">
                      <span className="home-viewasset-span">Select crypto</span>
                      <div className="depo_nav_design">
                        <div
                          className={
                            coinTyperef.current === "USDC"
                              ? "depo_nav_active"
                              : "depo_nav_notactive"
                          }
                          onClick={() => {
                            setCurrency("USDC");
                            coinTyperef.current = "USDC";
                          }}
                        >
                          <img
                            src={require("../assests/usdc-logo.png")}
                            alt="USDC"
                            className="logout-img"
                          />
                          <span>USDC</span>
                        </div>
                        <div
                          className={
                            coinTyperef.current === "USDT"
                              ? "depo_nav_active"
                              : "depo_nav_notactive"
                          }
                          onClick={() => {
                            setCurrency("USDT");
                            coinTyperef.current = "USDT";
                          }}
                        >
                          <img
                            src={require("../assests/usdt-logo.png")}
                            alt="USDT"
                            className="logout-img"
                          />
                          <span>USDT</span>
                        </div>
                      </div>
                    </div>
                    <div className="depo_inner_divmain">
                      <span className="home-viewasset-span">
                        Select Network
                      </span>
                      <Select
                        options={networkOptions}
                        onChange={(e) => setNetwork(e.value)}
                      />
                    </div>
                    <div className="depo_in_notesdiv">
                      <div className="depoin_notes_icon">
                        <i class="bi bi-exclamation-triangle depo_note_ico"></i>
                      </div>
                      <div className="depo_notes_defspan">
                        Only deposit USDC to the Polygon network. Depositing any
                        other cryptocurrency or using a different network may
                        result in the permanent loss of your funds
                      </div>
                    </div>
                    <div className="depo_inner_divmain">
                      <span className="home-viewasset-span">
                        Network Address
                      </span>
                      <div className="depo_qr_maindiv">
                        {addressData ? (
                          <>
                            <div className="depo_qr_imgmain">
                              <div className="depo_qr_imgsize">
                                <img
                                  src={addressData.qrcode}
                                  alt="QR code"
                                  className="QR-depo-img"
                                />
                              </div>
                              <span className="scan_or_enter">
                                Scan the QR code or copy the address to deposit
                              </span>
                            </div>
                            <div className="depo_add_dis">
                              <span className="address_span">
                                {addressData.address}
                              </span>
                              <i
                                className="ri-file-copy-line cursor-pointer add_depo_copy"
                                onClick={() =>
                                  handleCopyAdd(addressData.address)
                                }
                              ></i>
                            </div>
                          </>
                        ) : (
                          <p>Loading...</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="container m-top-20 p-0">
                    <div
                      class="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div className="faq">
                        <h5 className="faq-title">FAQ</h5>
                        <div class="accordion-item font-satoshi">
                          <h2 class="accordion-header" id="flush-headingOne">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseOne"
                              aria-expanded="false"
                              aria-controls="flush-collapseOne"
                            >
                              1. How do I make a deposit?
                              <span class="icon">
                                <i class="fas fa-chevron-down"></i>
                              </span>
                            </button>
                          </h2>
                          <div
                            id="flush-collapseOne"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingOne"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div class="accordion-body">
                              KYC verification is essential to comply with
                              regulations and ensure the security of our
                              platform by preventing fraud and other illegal
                              activities.
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item font-satoshi">
                          <h2 class="accordion-header" id="flush-headingTwo">
                            <button
                              class="accordion-button collapsed max-wrap"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseTwo"
                              aria-expanded="false"
                              aria-controls="flush-collapseTwo"
                            >
                              2. Why have I still not received my deposit?
                              <span class="icon">
                                <i class="fas fa-chevron-down"></i>
                              </span>
                            </button>
                          </h2>
                          <div
                            id="flush-collapseTwo"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingTwo"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div class="accordion-body">
                              You will need to submit a government-issued ID
                              (like a passport or driver's license) and a recent
                              proof of address (such as a utility bill or bank
                              statement).
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item font-satoshi">
                          <h2 class="accordion-header" id="flush-headingThree">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseThree"
                              aria-expanded="false"
                              aria-controls="flush-collapseThree"
                            >
                              3. How do I find my deposit address and tag/memos?
                              <span class="icon">
                                <i class="fas fa-chevron-down"></i>
                              </span>
                            </button>
                          </h2>
                          <div
                            id="flush-collapseThree"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingThree"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div class="accordion-body">
                              The verification process typically takes [X
                              hours/days], depending on the volume of requests
                              and the clarity of the documents provided.
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item font-satoshi">
                          <h2 class="accordion-header" id="flush-headingFour">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseFour"
                              aria-expanded="false"
                              aria-controls="flush-collapseFour"
                            >
                              4. How to check the deposit progress?
                              <span class="icon">
                                <i class="fas fa-chevron-down"></i>
                              </span>
                            </button>
                          </h2>
                          <div
                            id="flush-collapseFour"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingFour"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div class="accordion-body">
                              Yes, we use encryption and follow strict data
                              protection regulations to ensure that your
                              personal information remains secure.
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item font-satoshi">
                          <h2 class="accordion-header" id="flush-headingFive">
                            <button
                              class="accordion-button collapsed max-wrap"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseFive"
                              aria-expanded="false"
                              aria-controls="flush-collapseFive"
                            >
                              5. How to check the deposit progress?
                              <span class="icon">
                                <i class="fas fa-chevron-down"></i>
                              </span>
                            </button>
                          </h2>
                          <div
                            id="flush-collapseFive"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingFive"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div class="accordion-body">
                              If your verification is rejected, you will receive
                              an email with the reasons and instructions on how
                              to resolve the issue.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="recent_depo_main">
                <div className="manage_btn_row">
                  <span className="home_right_marketspan">Recent Deposit</span>
                  <Link to="/deposithistory">
                    <div className="d-flex align-items-center gap-2 cursor-pointer">
                      <span className="home_starty_view">View All</span>
                      <i class="fa-solid fa-chevron-right home_starty_viewarrow"></i>
                    </div>
                  </Link>
                </div>
                <div className="recent_depo_tablemain pb-0">
                  <div className="table-responsive table-borderless home_recent_table">
                    {loading ? (
                      <p className="depo_no_record">Loading...</p>
                    ) : (
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text_left">Currency</th>
                            <th>Date & Time</th>
                            <th>Network</th>
                            <th>Amount</th>
                            <th>Transaction ID</th>
                          </tr>
                        </thead>
                        <tbody>
                          {transactions.length > 0 ? (
                            transactions.slice(0, 5).map((transaction, index) => (
                              <tr key={index}>
                                <td>
                                  <div className="home_recent_firstdata">
                                    <img
                                      src={require("../assests/usdt-logo.png")}
                                      alt="USDT"
                                      className="usdt_home_recent"
                                    />
                                    <span>{transaction.currency}</span>
                                  </div>
                                </td>
                                <td>
                                  <span>
                                    {isNaN(new Date(transaction.timestamp))
                                      ? "null"
                                      : dayjs(transaction.timestamp).format(
                                        "DD MMMM YYYY, hh:mm A"
                                      )}
                                  </span>
                                </td>
                                <td>
                                  <span>{transaction.network}</span>
                                </td>
                                <td>
                                  <span>{transaction.amount}</span>
                                </td>
                                <td>
                                  <div className="home_uid_main">
                                    <span>{transaction.txID}</span>
                                    <i
                                      className="ri-file-copy-line cursor-pointer uid_copy_recent"
                                      onClick={() =>
                                        handleCopy(transaction.txID)
                                      }
                                    ></i>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="5">
                                <span className="depo_no_record">
                                  No transactions found
                                </span>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Deposit;
