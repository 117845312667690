import React, { createContext, useState, useEffect } from 'react';
import { getUserDetails } from '../services/authservices';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    tfaStatus: 0,
    email: null,
    uid: null,
    referralCode: null,
    referralUrl: null,
    displayname: null,
    country: null,
    isAuthenticated: false,
  });
  const [is2FARequired, setIs2FARequired] = useState(false);

  const fetchUserDetails = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const data = await getUserDetails(token);
        if (data && data.status) {
          const userDetails = {
            isAuthenticated: true,
            tfaStatus: data.tfastatus,
            email: data.email,
            displayname: data.displayname,
            country: data.country,
            uid: data.uid,
            referralCode: data.referralCode,
            referralUrl: data.referralUrl,
          };
          const storedApiKey = localStorage.getItem('apiKey');
          const storedSecretKey = localStorage.getItem('secretKey');
          const isUserBound = storedApiKey && storedSecretKey && /^[a-zA-Z0-9]{32,}$/.test(storedApiKey) && /^[a-zA-Z0-9]{32,}$/.test(storedSecretKey);

          if (isUserBound) {
            setUser({ ...userDetails, isBound: true });
          } else {
            setUser({ ...userDetails, isBound: false });
          }
          setIs2FARequired(data.tfastatus === 1);
          return userDetails;
        } else {
          // Default user if not authenticated
          const defaultUser = {
            isAuthenticated: false,
            tfaStatus: 0,
            email: null,
            uid: null,
            referralCode: null,
            referralUrl: null,
            isBound: false,
          };
          setUser(defaultUser);
          setIs2FARequired(false);
          return defaultUser;
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
        setUser({
          isAuthenticated: false,
          tfaStatus: 0,
          email: null,
          uid: null,
          referralCode: null,
          referralUrl: null,
          isBound: false,
        });
        setIs2FARequired(false);
      }
    } else {

      setUser({
        isAuthenticated: false,
        tfaStatus: 0,
        email: null,
        uid: null,
        referralCode: null,
        referralUrl: null,
        isBound: false,
      });
      setIs2FARequired(false);
    }
  };




  const login = async (token) => {
    localStorage.setItem('token', token);
    await fetchUserDetails();
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      fetchUserDetails();
    }
  }, []);

  const logout = () => {
    localStorage.removeItem('token');
    setUser({
      isAuthenticated: false,
      email: null,
      uid: null,
      referralCode: null,
      referralUrl: null,
      displayname: null,
      country: null,
    });
    setIs2FARequired(false);
  };

  return (
    <UserContext.Provider value={{ user, setUser, login, logout, is2FARequired }}>
      {children}
    </UserContext.Provider>
  );
};   