
import React, { useEffect } from "react";
import useState from "react-usestateref";

import authService from "../services/authservices";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import dayjs from "dayjs";

function LoginHistory() {

    const [amount, setAmount] = useState("");
    const [otpSent, setOtpSent] = useState(false);
    const [timer, setTimer] = useState(120);
    const [emailOtp, setEmailOtp] = useState("");
    const [tfaCode, setTfaCode] = useState("");
    const [coinType, setCoinType, coinTyperef] = useState("USDC");

    const [addressOptions, setAddressOptions] = useState([]);

    const [network, setNetwork] = useState("BEP20");

    const [user, setUser] = useState({
        tfaStatus: 0,
        email: null,
        uid: null,
        referralCode: null,
        referralUrl: null,
        displayname: null,
        country: null,
        isAuthenticated: false,
    });
    const [loading, setLoading] = useState(true);
    const [loginHistory, setLoginHistory] = useState([]);

    const fetchUserDetails = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const data = await authService.getUserDetails(token);
                if (data && data.status) {
                    const userDetails = {
                        isAuthenticated: true,
                        tfaStatus: data.tfastatus,
                        email: data.email,
                        displayname: data.displayname,
                        country: data.country,
                        uid: data.uid,
                        referralCode: data.referralCode,
                        referralUrl: data.referralUrl,
                    };
                    setUser(userDetails);
                } else {
                    setUser({
                        isAuthenticated: false,
                        tfaStatus: 0,
                        email: null,
                        uid: null,
                        referralCode: null,
                        referralUrl: null,
                    });
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
                setUser({
                    isAuthenticated: false,
                    tfaStatus: 0,
                    email: null,
                    uid: null,
                    referralCode: null,
                    referralUrl: null,
                });
            }
        } else {
            setUser({
                isAuthenticated: false,
                tfaStatus: 0,
                email: null,
                uid: null,
                referralCode: null,
                referralUrl: null,
            });
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchUserDetails();
    }, []);

    const handleCopy = (txID) => {
        // Prevent triggering multiple times rapidly
        if (handleCopy.timeout) clearTimeout(handleCopy.timeout);

        handleCopy.timeout = setTimeout(() => {
            navigator.clipboard
                .writeText(txID)
                .then(() => {
                    toast.success(
                        <div className="toast-ml">
                            <h4 className="toast-title">Success!</h4>
                            <p className="toast-message">
                                Transaction ID copied to clipboard!
                            </p>
                        </div>
                    );
                })
                .catch((err) => {
                    console.error("Failed to copy text: ", err);
                    toast.error(
                        <div className="toast-ml">
                            <h4 className="toast-title">Error!</h4>
                            <p className="toast-message">Failed to copy Transaction ID</p>
                        </div>
                    );
                });
        }, 300); // 300ms debounce time
    };

    useEffect(() => {
        const fetchLoginHistory = async () => {
            const response = await authService.getLoginHistory();
            if (response.status) {
                setLoginHistory(response.data);
            } else {
                console.error('Failed to fetch login history:', response.Message);
            }
        };

        fetchLoginHistory();
    }, []);

    return (
        <div className="recent_depo_main">
            <div className="manage_btn_row">
                <span className="home_right_marketspan">Recent Logins</span>
                <Link to="/home">
                    <div className="d-flex align-items-center gap-2 cursor-pointer">
                        <span className="home_starty_view">Back to Home</span>
                        <i className="fa-solid fa-chevron-right home_starty_viewarrow"></i>
                    </div>
                </Link>
            </div>
            <div className="recent_depo_tablemain pb-0">
                <div className="table-responsive table-borderless home_recent_table">
                    {loading ? (
                        <p className="depo_no_record">Loading...</p>
                    ) : (
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="text_left">IP Address</th>
                                    <th>Date & Time</th>
                                    <th>OS</th>
                                    <th>Device</th>
                                    <th>Browser</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loginHistory.length > 0 ? (
                                    loginHistory.map((login, index) => (
                                        <tr key={index}>
                                            <td><span>{login.ipAddress}</span></td>
                                            <td>
                                                <span>
                                                    {isNaN(new Date(login.createdTime))
                                                        ? "null"
                                                        : dayjs(login.createdTime).format(
                                                            "DD MMMM YYYY, hh:mm A"
                                                        )}
                                                </span>
                                            </td>
                                            <td>
                                                <span>{login.os}</span>
                                            </td>
                                            <td>   <span>{login.device}</span></td>
                                            <td><span>{login.browser}</span></td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5">
                                            <span className="depo_no_record">
                                                No login history found
                                            </span>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div >
    );
}

export default LoginHistory;
