import React from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";

function Team() {
  return (
    <div>
      <section>
        <Header />
      </section>

      <div className="team-section">
        <div className="container">
          <div className="row prof_row_sm">
            <div className="col-lg-2 dispaly_none_1200">
              <Sidebar />
            </div>

            <div className="col-xl-10 col-lg-12 deposit_pad_top">
              <div className="row team_main">
                <div className="col-lg-4 team_left_col_top">
                  <span className="team_top_left">Manage Your Team</span>
                </div>
                <div className="col-lg-8 m-top-20 pad_team_460">
                  <div className="team_top_right">
                    <div className="team_top_right_inmain">
                      <div className="team_top_right_leftcolor"></div>
                      <div className="team_topright_inner">
                        <span className="team_topinner_head">Team Members</span>
                        <span className="team_topinner_subhead">0</span>
                      </div>
                    </div>
                    <div className="team_top_right_inmain">
                      <div className="team_top_right_leftcolor"></div>
                      <div className="team_topright_inner">
                        <span className="team_topinner_head">
                          Cumulative profit
                        </span>
                        <span className="team_topinner_subhead">0</span>
                      </div>
                    </div>
                    <div className="team_top_right_inmain">
                      <div className="team_top_right_leftcolor"></div>
                      <div className="team_topright_inner">
                        <span className="team_topinner_head">
                          Direct members
                        </span>
                        <span className="team_topinner_subhead">0</span>
                      </div>
                    </div>
                    <div className="team_top_right_inmain">
                      <div className="team_top_right_leftcolor"></div>
                      <div className="team_topright_inner">
                        <span className="team_topinner_head">
                          Indirect members
                        </span>
                        <span className="team_topinner_subhead">0</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="team_second_main">
                <div className="row ">
                  <div className="col-lg-4 pad-0-560">
                    <div className="team_secondin_box">
                      <div className="team_secondin_box_head">
                        <img
                          src={require("../assests/icons/refer-teamember.png")}
                          alt="Refer"
                          className="refer_icon_inbox icons-light"
                        />
                        <img
                          src={require("../assests/icons/refer-teamember-dark.webp")}
                          alt="Refer"
                          className="refer_icon_inbox icons-dark"
                        />
                        <span className="top_startgy_inbottomadvance">
                          {" "}
                          Total Team AUm
                        </span>
                      </div>
                      <span className="refer_count_number">0.00%</span>
                    </div>
                  </div>
                  <div className="col-lg-4 pad-0-560 m-top-20">
                    <div className="team_secondin_box">
                      <div className="team_secondin_box_head">
                        <img
                          src={require("../assests/icons/refer-teamember.png")}
                          alt="Refer"
                          className="refer_icon_inbox icons-light"
                        />{" "}
                        <img
                          src={require("../assests/icons/refer-teamember-dark.webp")}
                          alt="Refer"
                          className="refer_icon_inbox icons-dark"
                        />
                        <span className="top_startgy_inbottomadvance">
                          {" "}
                          Direct Member AUM
                        </span>
                      </div>
                      <span className="refer_count_number">0.00 USDT</span>
                    </div>
                  </div>
                  <div className="col-lg-4 pad-0-560 m-top-20">
                    <div className="team_secondin_box">
                      <div className="team_secondin_box_head">
                        <img
                          src={require("../assests/icons/refer-teamember.png")}
                          alt="Refer"
                          className="refer_icon_inbox icons-light"
                        />{" "}
                        <img
                          src={require("../assests/icons/refer-teamember-dark.webp")}
                          alt="Refer"
                          className="refer_icon_inbox icons-dark"
                        />
                        <span className="top_startgy_inbottomadvance">
                          {" "}
                          Indirect Members AUM
                        </span>
                      </div>
                      <span className="refer_count_number">0.00 USDT</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="refer_third_main pb-0">
                    <span className="home_botstatus_span"> Members Rank</span>
                    <div className="table-responsive table-borderless home_recent_table">
                      <table className="table">
                        <thead>
                          <th className="text_left">SI.no</th>
                          <th>Name</th>
                          <th className="text_right">Rank</th>
                        </thead>
                        <tbody>
                          <tr>
                          <td colSpan={3}>
                              <span> No Records Found</span>
                            </td>
                          </tr>
                          {/* <tr>
                            <td className="text_left">
                              <span>01</span>
                            </td>
                            <td>
                              <span> Kathryn Murphy</span>
                            </td>
                            <td className="text_right">
                              <span>Rank 01</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="text_left">
                              <span>01</span>
                            </td>
                            <td>
                              <span> Kathryn Murphy</span>
                            </td>
                            <td className="text_right">
                              <span>Rank 01</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="text_left">
                              <span>01</span>
                            </td>
                            <td>
                              <span> Kathryn Murphy</span>
                            </td>
                            <td className="text_right">
                              <span>Rank 01</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="text_left">
                              <span>01</span>
                            </td>
                            <td>
                              <span> Kathryn Murphy</span>
                            </td>
                            <td className="text_right">
                              <span>Rank 01</span>
                            </td>
                          </tr>
                          <tr className="border_bottom_none">
                            <td className="text_left">
                              <span>01</span>
                            </td>
                            <td>
                              <span> Kathryn Murphy</span>
                            </td>
                            <td className="text_right">
                              <span>Rank 01</span>
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
